import ISortableNode from '../common/ISortableNode'
import IFormTreeSuperBriefDto from './IFormTreeSuperBriefDto'
import IGeomTreeNodeDto from './IGeomTreeNodeDto'

export default interface IGeomElementSortableNode extends ISortableNode{
    id?: number;
    geomParticle?: IGeomTreeNodeDto;
}

export function customNodeKey ({ node, treeIndex }): number {
  return node && node.id
}

// ---------------

export function makeGeoElementTree(level0s: IGeomTreeNodeDto[]): IGeomElementSortableNode[] {
  if (!level0s?.length) return null

  let id = 1

  function _makeNode (node: IGeomTreeNodeDto): IGeomElementSortableNode {
    const res: IGeomElementSortableNode = {
      id:  id++,
      children: [],
      expanded: true,
      geomParticle: node,
    }
    if (node.childs) node.childs.forEach(x => {
      res.children.push(_makeNode(x))
    })
    return res
  }

  const res: IGeomElementSortableNode[] = []
  level0s.forEach(x => {
    res.push(_makeNode(x))
  })

  return res
}

// ---
