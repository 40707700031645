import {TypographyOptions} from '@material-ui/core/styles/createTypography'


const typography: TypographyOptions = {
  fontFamily: ['Roboto', 'SF UI Display'].join(','),
  h1: {
    fontWeight: 600,
    fontSize: 38,
  },
  h2: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'SF UI Display',
  },
  h3: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'SF UI Display',
  },
  h4: {
    fontWeight: 500,
    fontSize: 18,
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '1.15em',
  },
  overline: {
    fontWeight: 500,
  },
  body2: {
    fontSize: '0.8rem',
  },
}

export default typography
