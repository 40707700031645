import {createContext, useContext} from 'react'
import IRootStore from '../store/_root/type'

export type RootContextType = () => IRootStore;

const RootContext = createContext<RootContextType>(undefined)
if (process.env.NODE_ENV !== 'production') {
  RootContext.displayName = 'RootContext'
}

const { Provider } = RootContext

export { Provider as RootContextProvider, RootContext }

export default function useStore(): IRootStore {
  const root = useContext(RootContext)
  return root()
}
