import { action, observable, makeObservable } from 'mobx'
import ISupplierBriefDto from '../../dto/supplier/ISupplierBriefDto'
import RootStoreModel from '../_root/RootStoreModel'
import ISupplierFullDto from '../../dto/supplier/ISupplierFullDto'
import IRootStore from '../_root/type'

class SupplierFirstSelectorStore extends RootStoreModel {
  open: boolean
  rootSupplier: ISupplierFullDto
  suppliers: ISupplierBriefDto[]
  selectedSupplier: ISupplierBriefDto
  handleConfirm?: Function
  handleCancel?: Function

  //-------------

  openModal = (
    rootSupplier: ISupplierFullDto,
    suppliers: ISupplierBriefDto[],
    initialValue: ISupplierBriefDto,
    handleConfirm?: Function,
    handleCancel?: Function,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.rootSupplier = rootSupplier
    this.suppliers = suppliers
    this.selectedSupplier = initialValue
    this.handleConfirm = handleConfirm
    this.handleCancel = handleCancel

    this.open = true
  }

  closeModal = () => {
    this.open = false

    this.rootSupplier = null
    this.suppliers = null
    this.selectedSupplier = null
    this.handleConfirm = null
    this.handleCancel = null
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      rootSupplier: observable,
      suppliers: observable,
      selectedSupplier: observable,
      openModal: action,
      closeModal: action,
    })
  }
}

export default SupplierFirstSelectorStore
