import IDataRowEditDto from '../dto/data/IDataRowEditDto'
import IColumnDescDto from '../dto/columndesc/IColumnDescDto'

export function geojsonAsFeatureCollection(geojson): any {
  if (!geojson) return null
  const res = typeof geojson === 'string' ? JSON.parse(geojson) : JSON.parse(JSON.stringify(geojson))

  switch (res.type) {
  case 'FeatureCollection': return res
  case 'Feature': return {
    type: 'FeatureCollection',
    features: [res],
  }
  case 'GeometryCollection':
  case 'Point':
  case 'MultiPoint':
  case 'LineString':
  case 'MultiLineString':
  case 'Polygon':
  case 'MultiPolygon':
    return {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {},
        geometry: res,
      }],
    }
  }
  return null
}

export function geojsonAsGeometry(geojson): any {
  if (!geojson) return null
  const res = JSON.parse(JSON.stringify(geojson))

  switch (geojson.type) {
  case 'FeatureCollection': return res.features && res.fueatures[0] && res.features[0].geometry
  case 'Feature': return res.geometry
  case 'GeometryCollection':
  case 'Point':
  case 'MultiPoint':
  case 'LineString':
  case 'MultiLineString':
  case 'Polygon':
  case 'MultiPolygon':
    return res
  }
  return null
}

// modifying
export function geojsonMultiPointsToSingle(featureCollection: any) {
  const newFeatures = []
  featureCollection.features.forEach(feature => {
    if (feature.geometry.type === 'MultiPoint' ) {
      const geometry = feature.geometry
      for (let i = 1; i < geometry.coordinates.length; i++) {
        newFeatures.push({
          type: 'Feature',
          properties: feature.properties,
          geometry: {
            type: 'Point',
            coordinates: geometry.coordinates[i],
          },
        })
      }
      geometry.type = 'Point'
      geometry.coordinates = geometry.coordinates[0]
    }
  })

  featureCollection.features = featureCollection.features.filter(feature => feature.type !== 'MultiPoint').concat(newFeatures)

}

export function dreAsFeatureCollection(datum: IDataRowEditDto, columns: IColumnDescDto[]): any {
  const featureCollection = {
    type: 'FeatureCollection',
    features: [],
  }

  columns.filter(col => col.colType === 'POSTGIS').forEach(col => {
    let geojson = datum.values && datum.values[col.id]
    if (!geojson) return
    if (typeof geojson === 'boolean' || typeof geojson === 'number') return
    if (typeof geojson === 'string') geojson = JSON.parse(geojson)
    geojson = geojsonAsFeatureCollection(geojson)
    if (col.postgisShape === 'POINT') {
      geojsonMultiPointsToSingle(geojson)
    }
    featureCollection.features = featureCollection.features.concat(geojson.features)
  })

  return featureCollection
}

////////////////////////////////////

export const DEFAULT_BOUNDING_BOX = [37.0, 43.0, 40.0, 47.0]
function bboxIsValid(bb: number[]): boolean {
  if (!(bb && bb.length === 4)) return false
  return isFinite(bb[0]) && isFinite(bb[1]) && isFinite(bb[2]) && isFinite(bb[3])
}

export function bboxMinTrim(bb: number[]): number[] {
  let finite = true
  bb.forEach(x => {
    if (!isFinite(x)) finite = false
  })
  if (!finite) return DEFAULT_BOUNDING_BOX
  const res = bb.concat([])
  const lenLat = Math.abs(bb[1] - bb[3])
  const lenLon = Math.abs(bb[0] - bb[2]) / 2
  const MIN_L = 0.05
  if (!lenLon) { res[0] = res[0] - MIN_L; res[2] = res[0] + MIN_L }
  if (!lenLat) { res[1] = res[1] - MIN_L; res[3] = res[1] + MIN_L }
  return res
}

export function calcZoomByBBox(bb: number[]): number {
  console.debug('calcZoomByBBox')
  if (!bboxIsValid(bb)) return calcZoomByBBox(DEFAULT_BOUNDING_BOX)
  const lenLat = Math.abs(bb[1] - bb[3])
  const lenLon = Math.abs(bb[0] - bb[2]) / 2
  if (!lenLat && !lenLon) return calcZoomByBBox(DEFAULT_BOUNDING_BOX)
  return calcZoomByLattitudeLength(Math.max(lenLat, lenLon), 1)
}

export function calcZoomByLattitudeLength(x: number, margin?: number): number {
  x *= (1 + ((margin === null || margin === undefined) ? 0.0 : margin))
  if (x <= 0.0001 || x > 180) return 2
  let res = Math.log2(180 / x) + 1
  if (res > 16) res = 16
  return res
}

export function calcCenterLattitudeByBBox(bb: number[]): number {
  if (!bboxIsValid(bb)) return calcCenterLattitudeByBBox(DEFAULT_BOUNDING_BOX)
  return (bb[1] + bb[3]) / 2
}

export function calcCenterLongitudeByBBox(bb: number[]): number {
  if (!bboxIsValid(bb)) return calcCenterLongitudeByBBox(DEFAULT_BOUNDING_BOX)
  return (bb[0] + bb[2]) / 2
}
