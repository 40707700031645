import { createContext } from 'react'
import { ControllersContextType } from './controllersContextTypes'

export const controllersContextValue: ControllersContextType = {
  controllers: {},
  addController(name, controller) {
    this.controllers = { ...this.controllers, [name]: controller }
  },
  getController(name) {
    return this.controllers[name]
  },
  rmController(name) {
    this.controllers[name] = undefined
  },
}

export const ControllersContext = createContext<ControllersContextType>(controllersContextValue)