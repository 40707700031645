import ServerApi, {IResponse} from './common/ServerApi'
import IUserGroupFullDto from '../dto/usergroup/IUserGroupFullDto'
import IUserGroupCreateEditDto from '../dto/usergroup/IUserGroupCreateEditDto'
import IUserGroupBriefDto from '../dto/usergroup/IUserGroupBriefDto'
import IUserGroupDeleteDto from '../dto/usergroup/IUserGroupDeleteDto'
import IUserGroupListRequestDto from '../dto/request/IUserGroupListRequestDto'
import IIdNameDto from '../dto/common/IIdNameDto'
import IPageDto from '../dto/common/IPageDto'

export default class UserGroupApi {

  static async getRootGroups(withUsers: boolean): Promise<IResponse<IUserGroupFullDto[]>> {
    console.debug('getRootGroups')
    return ServerApi.postQuery('userGroup/getRootGroups', {withUsers: withUsers})
  }

  static async getGroupsByFilter(dto: IUserGroupListRequestDto): Promise<IResponse<IUserGroupFullDto[]>> {
    console.debug('getGroupsByFilter')
    return ServerApi.postQuery('userGroup/getGroupsByFilter', dto)
  }

  static async getUserGroup(id: number, withUsers: boolean): Promise<IResponse<IUserGroupFullDto>> {
    return ServerApi.postQuery('userGroup/getUserGroup', {id: id, withUsers: withUsers})
  }

  static async createUserGroup(dto: IUserGroupCreateEditDto): Promise<IResponse<IUserGroupFullDto>> {
    return ServerApi.postQuery('userGroup/createUserGroup', dto)
  }

  static async editUserGroup(dto: IUserGroupCreateEditDto): Promise<IResponse<IUserGroupFullDto>> {
    return ServerApi.postQuery('userGroup/editUserGroup', dto)
  }

  static async deleteUserGroup(dto: IUserGroupDeleteDto): Promise<IResponse<IUserGroupBriefDto>> {
    return ServerApi.postQuery('userGroup/deleteUserGroup', dto)
  }

  static async changeName(dto: IIdNameDto): Promise<IResponse> {
    return ServerApi.postQuery('userGroup/changeName', dto)
  }

  static async getUserGroupsForForm(formId: number): Promise<IResponse<IUserGroupBriefDto[]>> {
    return ServerApi.postQuery('userGroup/getUserGroupsForForm', { id: formId })
  }
}
