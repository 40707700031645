import React, { FunctionComponent, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, Collapse, ListItem, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import type { Icon as IconType } from 'react-feather'
//import {FontWeightProperty} from "csstype";

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: '#fff', // theme.palette.text.secondary
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    display: 'inline-flex',
    fontSize: 14,
    textDecoration: 'none',
    color: '#fff', //
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    lineHeight: '1.2em',
    width: '100%',
    //fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
    '&.depth-0': {
      '& $title': {
        //fontWeight: theme.typography.fontWeightMedium
      },
    },
  },
  icon: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50px',
  },
}))

interface INavItemProps {
  titl?: string
  href?: string
  depth?: number
  icon?: IconType
  className?: string
  open?: boolean
  noLink?: boolean
  info?: React.ComponentType
  children?: React.ReactNode
  handleClick?: (ev) => void
  [key: string]: any
}

const NavItem: FunctionComponent<INavItemProps> = ({
  title,
  href,
  depth,
  children,
  handleClick,
  icon: Icon,
  className,
  open: openProp,
  noLink,
  info: Info,
  ...rest
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 20 + 8 * depth
  }

  const style = { paddingLeft }

  if (children) {
    return (
      <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon fontSize="small" color="inherit" />
          ) : (
            <ExpandMoreIcon fontSize="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  //console.log(isActive);

  return (
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <RouterLink
        activeClassName={classes.active} // ругается если присвоить activeClassName к Button
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        //component={noLink ? undefined : RouterLink}
        exact={false}
        style={style}
        //onClick={handleClick}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </RouterLink>
    </ListItem>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  open: false,
}

export default NavItem
