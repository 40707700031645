import React, {FunctionComponent} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import Logo from '../views/panels/Logo'
import {useStyles} from './splash-styles'

const SplashScreen: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Logo className={classes.logo} />
      </Box>
      <CircularProgress />
    </div>
  )
}

export default SplashScreen
