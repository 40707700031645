import { action, IObservableArray, observable, runInAction, makeObservable } from 'mobx'
import BaseListStore from './common/BaseListStore'
import { IResponse, message } from '../api/common/ServerApi'
import IReportDto from '../dto/report/IReportDto'
import IReportRequestDto from '../dto/report/IReportRequestDto'
import DataApi from '../api/DataApi'
import StructureApi from '../api/StructureApi'
import IDicVersionDto from '../dto/report/IDicVersionDto'
import { REPORT_COPY_METHOD } from '../enums/REPORT_COPY_METHOD'
import IFormFullDto from '../dto/form/IFormFullDto'
import { STAGE_STATUS } from '../enums/STAGE_STATUS'
import { MOVE_DOWN_METHOD } from '../enums/MOVE_DOWN_METHOD'
import ISupplierBriefDto from '../dto/supplier/ISupplierBriefDto'
import IRootStore from './_root/type'
import { MULTI_REPORT_DATA_ACTION } from '../views/pages/MyForm/ReportList/GroupActionMenu'
import IMultiReportUgDto from "../dto/data/IMultiReportUgDto";

class ReportListStore extends BaseListStore<IReportDto, IReportRequestDto> {
  form: IFormFullDto
  loadingInactualIds: IObservableArray<number> = observable.array([])
  loadingAgreedIds: IObservableArray<number> = observable.array([])
  loadingDescriptionIds: IObservableArray<number> = observable.array([])

  supplierStore = () => {
    return this.root().supplierSelectorStore
  }

  initialize = async (initObj?: any): Promise<IResponse> => {
    const formId = initObj.formId
    if (!formId) return { success: false }

    this.isLoading = true
    this.loadingError = null

    const resp1 = await StructureApi.getForm(formId)
    if (resp1.success) {
      this.form = resp1.data
    } else {
      this.loadingError = message(resp1)
      this.isLoading = false
      return resp1
    }

    const resp2 = await this.supplierStore().load(formId)
    if (!resp2.success) {
      this.loadingError = this.supplierStore().loadingError
      this.isLoading = false
      return resp2
    }

    this.requestOptions = this.getDefaultRequestOptions()
    this.requestOptions.formId = formId

    // мы имеем доступ к rootSupplier
    let initialSupplier: ISupplierBriefDto = null
    if (
      this.supplierStore().rootSupplier &&
      this.supplierStore().mySuppliers.find(x => x.id === this.supplierStore().rootSupplier.id)
    ) {
      initialSupplier = this.supplierStore().rootSupplier
    } else {
      // иначе
      initialSupplier = this.supplierStore().mySuppliers[0]
    }
    if (initialSupplier) {
      this.supplierStore().selectSupplier(initialSupplier)
      this.requestOptions.userGroupId = initialSupplier.userGroup.id
    }

    //await this.loadDics();
    const resp3 = await this.load(this.requestOptions)

    this.initialized = true
    return resp3
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      form: observable,

      createReport: action,
      setReportInactual: action,
      setReportAgreed: action,
      dataAction: action,
    })
  }

  protected getDefaultRequestOptions(): IReportRequestDto {
    return new IReportRequestDto()
  }

  protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
    return DataApi.deleteReports(ids)
  }

  protected async deleteOneRoutine(id: number): Promise<IResponse> {
    return DataApi.deleteReport(id)
  }

  protected async getListRoutine(apiRequestOptions: IReportRequestDto): Promise<IResponse> {
    return DataApi.getReports(apiRequestOptions)
  }

  load = async (requestOptions: IReportRequestDto): Promise<IResponse> => {
    return this.loadList(requestOptions)
  }

  createReport = async (
    formId: number,
    dicVersions: IDicVersionDto[],
    dateStart: Date,
    dateEnd: Date,
    datePublish: Date,
    description: string,
    emailNotification: boolean,
  ): Promise<IResponse> => {
    const resp = await DataApi.createReport({
      dicVersions: dicVersions,
      form: { id: formId },
      periodStart: dateStart,
      periodEnd: dateEnd,
      periodPublish: datePublish,
      description: description,
      columns: null,
      rootColumnGroups: null,
      emailNotification: emailNotification,
    })

    await this.load(this.requestOptions)

    return resp
  }

  copyReport = async (
    reportId: number,
    copyMethod: REPORT_COPY_METHOD,
    moveDownMethod: MOVE_DOWN_METHOD,
    periodStart: Date,
    periodEnd: Date,
    periodPublish: Date,
    description: string,
    emailNotification: boolean,
  ): Promise<IResponse> => {
    const resp = await DataApi.copyReport({
      reportId: reportId,
      copyMethod: copyMethod,
      moveDownMethod: moveDownMethod,
      periodStart: periodStart,
      periodEnd: periodEnd,
      periodPublish: periodPublish,
      description: description,
      emailNotification: emailNotification,
    })

    if (resp.success) {
      await this.load(this.requestOptions)
    }

    return resp
  }

  setReportInactual = async (reportId: number, inactual: boolean) => {
    if (!this.loadingInactualIds.includes(reportId)) this.loadingInactualIds.push(reportId)
    const resp = await DataApi.setReportInactual(reportId, inactual)
    if (resp.success) {
      this.page.content.forEach(x => {
        if (x.id === reportId) x.inactual = inactual
        x.isActualRd = (resp.data as number) === x.id
      })
    }
    this.loadingInactualIds.remove(reportId)
    return resp
  }

  setReportAgreed = async (reportId: number, agreed: boolean) => {
    if (!this.loadingAgreedIds.includes(reportId)) this.loadingAgreedIds.push(reportId)
    const resp = await DataApi.setReportAgreed(reportId, agreed)
    runInAction(() => {
      if (resp.success) {
        const rep = this.page.content.find(x => x.id === reportId)
        rep.agreed = resp.data.agreed
      }
      this.loadingAgreedIds.remove(reportId)
    })
    return resp
  }

  setReportDescription = async (reportId: number, description: string) => {
    if (!this.loadingDescriptionIds.includes(reportId)) this.loadingDescriptionIds.push(reportId)
    const resp = await DataApi.setReportDescription(reportId, description)
    if (resp.success) {
      this.page.content.find(x => x.id === reportId).description = description
    }
    this.loadingAgreedIds.remove(reportId)
    return resp
  }

  setReportDates = async (
    reportId: number,
    periodStart: Date,
    periodEnd: Date,
    periodPublish: Date,
  ) => {
    const resp = await DataApi.setReportDates(reportId, periodStart, periodEnd, periodPublish)
    if (resp.success) {
      runInAction(() => {
        const report = this.page.content.find(x => x.id === reportId)
        report.periodStart = resp.data.periodStart
        report.periodEnd = resp.data.periodEnd
        report.periodPublish = resp.data.periodPublish
        report.agreed = resp.data.agreed
      })
    }
    return resp
  }

  makeReportsFromDate = async (
    reportId: number,
    columnId: number,
    stageStatus: STAGE_STATUS,
    keepSupplierFirsts: boolean,
    approveReports: boolean,
  ) => {
    const resp = await DataApi.makeReportsFromDate(
      reportId,
      columnId,
      stageStatus,
      keepSupplierFirsts,
      approveReports,
    )
    if (!resp.success) return resp
    const resp2 = await this.load(this.requestOptions)
    return resp2
  }

  // multi report data actions
  dataAction = async (
      action: MULTI_REPORT_DATA_ACTION,
      formId: number,
      reportIds: number[],
  ): Promise<IResponse> => {
    this.isLoading = true
    this.loadingError = null
    let resp
    const multiReportDto: IMultiReportUgDto = {
      formId: formId,
      reportIds: reportIds,
      userGroupId: null,
    }
    switch (action) {
      case 'setAgreedTrueNotEmpties':
        resp = await DataApi.setMultiReportAgreed({
          formId: formId,
          reportIds: reportIds,
          agreed: true,
          notEmptyReportsOnly: true,
        })
        break
      case 'setAgreedTrue':
        resp = await DataApi.setMultiReportAgreed({
          formId: formId,
          reportIds: reportIds,
          agreed: true,
        })
        break
      case 'setAgreedFalse':
        resp = await DataApi.setMultiReportAgreed({
          formId: formId,
          reportIds: reportIds,
          agreed: false,
        })
        break
      case 'moveToRoot':
        resp = await DataApi.moveToRootMultiReportData(multiReportDto)
        break
      case 'moveToFirst':
        resp = await DataApi.moveToFirstMultiReportData(multiReportDto)
        break
      case 'approve':
        resp = await DataApi.approveMultiReportData(multiReportDto)
        break
      case 'disapprove':
        resp = await DataApi.disapproveMultiReportData(multiReportDto)
        break
    }

    if (resp.success) {
      const resp2 = await this.load(this.requestOptions)
    }
    this.isLoading = false

    return resp
  }

}

export default ReportListStore
