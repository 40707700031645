import React, { FunctionComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import {
  AppBar,
  AppBarProps,
  Box,
  colors,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core'
import Logo from '../../panels/Logo'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    minHeight: 64,
    height: 64,
  },
  caption: {
    color: colors.common.white,
  },
  logo: { height: '40px' },
}))

interface ITopbarProps extends AppBarProps {
  className?: string
}

const TopBar: FunctionComponent<ITopbarProps> = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo topbar className={classes.logo} />
        </RouterLink>

        <Box flexGrow={1} ml={2}>
          DataPlatform
          <br />
          <Typography variant="caption" color="textSecondary" className={classes.caption}>
            {process.env.REACT_APP_COPYRIGHT}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
