import moment from 'moment'

console.debug('date-util')

export function isValidDate(d: Date): boolean {
  return d && d instanceof Date && !isNaN(d.getTime())
}

export function dateStr(val: Date): string {
  if (!val) return ''
  else return moment(val).utc(true).format('DD.MM.YYYY')
}

export function timeStr(val: Date): string {
  if (!val) return ''
  else return moment(val).utc(true).format('DD.MM.YYYY HH:mm')
}

export function momentStrFromDate(val: Date): string {
  if (!val) return undefined
  else return moment(val).utc(true).format('YYYY-MM-DD')
}
export function momentDateFromRuDatePicker(val: string): Date {
  if (!val) return null
  else return moment.utc(val, 'DD.MM.YYYY', true).toDate()
}
