import UserApi from 'src/api/UserApi'
import UserGroupApi from 'src/api/UserGroupApi'
import IUserFullDto from 'src/dto/ancient-mser/IUserFullDto'
import IUserGroupCreateEditDto from 'src/dto/usergroup/IUserGroupCreateEditDto'
import UserSelectorStore from 'src/store/ModalStore/UserSelectorStore'

export class UserGropsService {
  private readonly repository: UserSelectorStore

  constructor(repository: UserSelectorStore) {
    this.repository = repository
  }

  private async _getUserByNameOrEmail(nameOrEmail: string) {
    try {
      const { data, success } = await UserApi.getUsers({
        pageNumber: 0,
        locked: false,
        hasApiKey: false,
        search: nameOrEmail,
      })

      if (!success) {
        return []
      }

      return data.content
    } catch (e) {
      throw e
    }
  }

  public async getUsersByNameOrEmails(nameOrEmails: string[]) {
    const requests = nameOrEmails.map(this._getUserByNameOrEmail)
    this.repository.manyInputUsersDetailsIsLoading = true
    const result = await Promise.all(requests)
    this.repository.manyInputUsersDetailsIsLoading = false
    return result
  }

  public filterInvalidNamesOrEmails(namesOrEmails: string[], serverResponse: IUserFullDto[][]) {
    return namesOrEmails.filter((_, index) => serverResponse[index]?.length !== 1)
  }

  public async setUserGroupUsers(userGroup: IUserGroupCreateEditDto, users: IUserFullDto[]) {
    try {
      this.repository.manyInputSaveInProcess = true
      const { message, success } = await UserGroupApi.editUserGroup({
        ...userGroup,
        users: [...userGroup.users, ...users],
      })

      if (!success) {
        throw new Error(message)
      }

      return message
    } catch (e) {
      throw e
    } finally {
      this.repository.manyInputSaveInProcess = false
    }
  }
}
