import ServerApi, {IResponse} from './common/ServerApi'
import IPageDto, {emptyPage} from '../dto/common/IPageDto'
import IGeoTiffListRequestDto from '../dto/request/IGeoTiffListRequestDto'
import IGeoTiffFullDto from '../dto/gismap/IGeoTiffFullDto'
import IGeoTiffCreateEditDto from '../dto/gismap/IGeoTiffCreateEditDto'

export default class GeoTiffApi {
  static async downloadGeoTiff(id, onProgress) {
    return ServerApi.downloadFileQuery(`geotiff/download/${id}`,
      null, {}, {
        id: id,
      },
      'POST',
      onProgress,
    )
  }

  static async uploadGeoTiff(geoTiffId: number, file: File, onProgress): Promise<IResponse<IGeoTiffFullDto>> {
    console.debug('uploadGeoTiff')

    const fd = new FormData()
    fd.append('geoTiffId', String(geoTiffId))
    fd.append('file', file)

    return ServerApi.postQuery('geotiff/uploadGeoTiff', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    })
  }

  static getGeoTiffProfile(id: number): Promise<IResponse<IGeoTiffFullDto>> {
    console.debug('getGeoTiffProfile')
    return ServerApi.postQuery('geotiff/getGeoTiffProfile', {id: id})
  }

  static getGeoTiffs(requestDto: IGeoTiffListRequestDto): Promise<IResponse<IPageDto<IGeoTiffFullDto>>> {
    return new Promise(resolve => ({success: true, data: emptyPage}))
    console.debug('getGeoTiffs')
    return ServerApi.postQuery('geotiff/getGeoTiffs', requestDto)
  }

  static deleteGeoTiff(id: number): Promise<IResponse> {
    console.debug('deleteGeoTiff')
    return ServerApi.postQuery('geotiff/deleteGeoTiff', {id: id})
  }

  static deleteGeoTiffs(ids: number[]): Promise<IResponse> {
    console.debug('deleteGeoTiffs')
    return ServerApi.postQuery('geotiff/deleteGeoTiffs', {ids: ids})
  }

  static saveGeoTiff(dto: IGeoTiffCreateEditDto): Promise<IResponse<IGeoTiffFullDto>> {
    console.debug('saveGeoTiff')
    return ServerApi.postQuery('geotiff/saveGeoTiff', dto)
  }

}
