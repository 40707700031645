import IIdDto from '../common/IIdDto'
import IUserGroupBriefDto from '../usergroup/IUserGroupBriefDto'
import ISupplierFullDto from './ISupplierFullDto'
import ISupplierPermissionDto from './ISupplierPermissionDto'
import IIdNameDto from '../common/IIdNameDto'

export default interface ISupplierBriefDto extends IIdDto {
    userGroup: IUserGroupBriefDto;
    observerUserGroups?: IIdNameDto[];

    savedPermission?: ISupplierPermissionDto;
    //
    effectivePermission?: ISupplierPermissionDto;

    // non-db
    admin?: boolean;
    observer?: boolean;
}

export function fromSupplierFullDto(f: ISupplierFullDto): ISupplierBriefDto {
  if (!f) return null
  return {
    id: f.id,
    admin: f.admin,
    observer: f.observer,
    userGroup: f.userGroup,
    observerUserGroups: f.observerUserGroups,
  }
}