export const rowBlue = '#def'
export const rowGreen = '#dfd'
export const rowYellow = '#ffd'
export const rowRed = '#f0d8d8'
export const rowOrange = '#fed'
export const rowWhite = '#fff'
export const rowGray1 = '#eee'
export const rowGray2 = '#ddd'
export const rowGray3 = '#aaa'

export const palette = {
  background: {
    default: '#F4F4F4',
    dark: '#f4f6f8',
    paper: '#FFFFFF',
  },
  divider: '#CFCFCF',
  primary: {
    dark: '#252A2F',
    main: '#363F45',
    light: '#455A64',
  },
  secondary: {
    main: '#D8262C',
  },
  text: {
    primary: '#252C32',
    secondary: '#3B4256',
  },
}
