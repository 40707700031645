import { Feature, Polygon } from 'geojson'
import { action, computed, observable, makeObservable } from "mobx";    // eslint-disable-line
import area from '@turf/area'
import distance from '@turf/distance'
import { MeasureMode } from './types'

export class MeasureStore {
  mode: MeasureMode | null;
  coordinates: [number, number][] = [];
  mouseCoords?: [number, number];

  constructor() {
    makeObservable(this, {
      mode: observable,
      coordinates: observable,
      mouseCoords: observable,
      setCoordinates: action,
      distance: computed,
      square: computed,
      reset: action
    });
  }

  setCoordinates(coordinates: [number, number][]) {
    this.coordinates = coordinates
  }

  get distance() {
    if ( this.coordinates.length ) {
      return this.coordinates.reduce((acc, item, index, arr) => {
        if (index === 0) {
          return acc
        }
        return +acc + distance(arr[index - 1], item, { units: 'meters' })
      }, 0)
    }
    return null
  }

  get square() {
    const coordinates = [this.coordinates]
    const polygon: Feature<Polygon> = { geometry: { coordinates, type: 'Polygon' }, properties: {}, type: 'Feature' }
    return area(polygon)
  }

  reset() {
    this.coordinates = []
    this.mode = null
  }
}