import { action, observable, makeObservable } from 'mobx';	// eslint-disable-line
import {WhatsHereCadastr, FormLayerItem, GeoTiffLayerItem} from './types'

export class WhatsHereStore {
  coordinates: [number, number][] = [];
  cadastrData: WhatsHereCadastr | null = null;
  formLayersData: FormLayerItem[] = [];
  geoTiffLayersData: GeoTiffLayerItem[] = [];
  polygonCadastrData: WhatsHereCadastr | null = null;
  polygonFormLayersData: FormLayerItem[] = [];
  polygonGeoTiffLayersData: GeoTiffLayerItem[] = [];
  mode: 'POINT' | 'POLYGON';
  targetPoint: [number, number] | undefined;

  cadastrDataIsLoading = false;
  layersDataIsLoading = false;

  constructor() {
    makeObservable(this, {
      coordinates: observable,
      cadastrData: observable,
      formLayersData: observable,
      geoTiffLayersData: observable,
      polygonCadastrData: observable,
      polygonFormLayersData: observable,
      polygonGeoTiffLayersData: observable,
      mode: observable,
      targetPoint: observable,
      cadastrDataIsLoading: observable,
      layersDataIsLoading: observable,
      reset: action
    });
  }

  reset() {
    this.cadastrData = null
    this.cadastrDataIsLoading = undefined
    this.coordinates = []
    this.formLayersData = []
    this.geoTiffLayersData = []
    this.layersDataIsLoading = false
    this.polygonCadastrData = null
    this.polygonFormLayersData = []
    this.polygonGeoTiffLayersData = []
    this.mode = undefined
    this.targetPoint = undefined
  }

  get isLoading() {
    return this.cadastrDataIsLoading || this.layersDataIsLoading
  }

  get cadastrDataIsEmpty() {
    return !this.land && !this.quarter
  }

  get land() {
    if (!this.mode) {
      return null
    }
      
    const data = this.mode === 'POLYGON' ? this.polygonCadastrData : this.cadastrData
    const number = data?.landRecords[0]?.cadastralNumber
    const title = data?.landRecords[0]?.typeValue
    if (!number && !title) {
      return null
    }

    return { number, title }
  }

  get formLayers() {
    if (!this.mode) {
      return null
    }
      
    return this.mode === 'POLYGON' ? this.polygonFormLayersData : this.formLayersData
  }

  get geoTiffLayers() {
    if (!this.mode) {
      return null
    }

    return this.mode === 'POLYGON' ? this.polygonGeoTiffLayersData : this.geoTiffLayersData
  }

  get quarter() {
    if (!this.mode) {
      return null
    }
      
    const data = this.mode === 'POLYGON' ? this.polygonCadastrData : this.cadastrData
    const number = data?.cadastralBlocks[0]?.cadastralNumber
    const title = data?.cadastralBlocks[0]?.typeValue
    if (!number && !title) {
      return null
    }

    return { number, title }
  }
}