import IIdCodeNameDto from '../common/IIdCodeNameDto'

export default interface IDataRowEditDto {
    id?: number;
    // for upload: object is one of String/Number/Date/Boolean  (for refValues its code string)
    values?: object; // Map<Long, Object>
    supplierFirstId?: number;
    // readonly
    mainGeometry?: string;
}

export function getByPath(obj: IDataRowEditDto, columnId: number, path: number[]): any {
  //console.debug('getByPath...');

  function fn(dre: IDataRowEditDto, cId: number, p: number[]):any {
    if (!(dre && dre.values)) return
    if (!(p && p.length)) return dre.values[cId]
    else {
      const p0 = p[0]
      p.shift()
      return fn(dre.values[p0], cId, p)
    }
  }

  return fn(obj, columnId, JSON.parse(JSON.stringify(path || [])))
}

export function getCodeValue(obj: IDataRowEditDto | IIdCodeNameDto, columnMap: any): string {
  if (!obj) return null
  if ((obj as IIdCodeNameDto).code) return (obj as IIdCodeNameDto).code
  const dre = obj as IDataRowEditDto
  if (!dre.values || !columnMap) return null
  for (const x in dre.values) {
    if (columnMap[x] && columnMap[x].isUniqueKey) {
      if (typeof dre.values[x] === 'object') return getCodeValue(dre.values[x], columnMap)
      else return dre.values[x]
    }
  }
  return null
}