import { observable, runInAction, makeObservable } from 'mobx'
import { IResponse } from '../../api/common/ServerApi'
import DataApi from '../../api/DataApi'
import IDicItemDto from '../../dto/data/IDicItemDto'
import IReportDicItemsRequestDto from '../../dto/report/IReportDicItemsRequestDto'
import IMTRow from './IMTRow'
import BaseDataStore from './BaseDataStore'
import IRootStore from '../_root/type'

class OpenReportDataStore extends BaseDataStore<IDicItemDto, IReportDicItemsRequestDto> {
  mtRows: object[] = []
  loadChildRequests: object = {} // <parentId, pageNumber>

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, { mtRows: observable })
  }

  public async initialize(initObj?: any): Promise<IResponse> {
    const respSuper = await super.initialize(initObj)
    if (!respSuper.success) {
      this.loadingError = respSuper.message
      this.isLoading = false
      return respSuper
    }

    this.beforeInit()

    const resp3 = await this.load(this.requestOptions)
    this.loadingError = resp3.message
    this.initialized = true
    return resp3
  }

  protected getDefaultRequestOptions(): IReportDicItemsRequestDto {
    const res = new IReportDicItemsRequestDto()
    res.sort = []
    return res
  }

  protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
    return
  }

  protected async deleteOneRoutine(id: number): Promise<IResponse> {
    return
  }

  protected async getListRoutine(requestDto: IReportDicItemsRequestDto): Promise<IResponse> {
    return DataApi.getDicItems(requestDto)
  }

  load = async (requestOptions: IReportDicItemsRequestDto): Promise<IResponse> => {
    console.debug('OpenReportDataStore::load')
    this.loadChildRequests = {}
    const resp = await this.loadList(requestOptions)

    if (resp.success) {
      this.mtRows = this.unnest(resp.data.content, !requestOptions.includeChilds)
    }

    return resp
  }

  loadMoreChilds = async (parentId: number) => {
    console.debug('loadMoreChilds')
    runInAction(() => {
      this.isLoading = true
      this.loadingError = null
    })

    const requestOptions = this.getRequestOptionsForLoadChilds(parentId)
    this.loadChildRequests[parentId] = requestOptions.pageNumber

    const resp = await this.loadList(requestOptions)

    runInAction(() => {
      if (resp.success) {
        //const node = this.mtRows.find(x => x.id === requestOptions.parentId);
        this.mtRows = this.mtRows.filter(
          x => !((x as IMTRow).id < 0 && (x as IMTRow).parentId === requestOptions.parentId),
        )
        this.mtRows = this.mtRows.concat(
          resp.data.content.map(x => {
            return {
              ...x.values,
              id: x.id,
              parentId: parentId,
            }
          }),
        )
        if (!requestOptions.includeChilds) {
          if (resp.data.totalPages > requestOptions.pageNumber + 1) {
            this.mtRows.push(this.makeLoadMoreRow(requestOptions.parentId))
          }
          resp.data.content.forEach(x => {
            if (x.hasChilds) {
              this.mtRows.push(this.makeLoadMoreRow(x.id))
            }
          })
        }
      } else {
        this.loadingError = resp.message
      }

      this.isLoading = false
    })

    return resp
  }

  private unnest(dicItems: IDicItemDto[], makeLoadMore: boolean): object[] {
    let res = []
    for (let i = 0; i < (dicItems && dicItems.length); i++) {
      const d = dicItems[i]
      const x: any = { ...d.values, id: d.id, parentId: d.parent && d.parent.id }
      if (makeLoadMore && !(d.childs && d.childs.length) && d.hasChilds) {
        res.push(this.makeLoadMoreRow(d.id))
      }
      res.push(x)
      res = res.concat(this.unnest(d.childs, makeLoadMore))
    }
    return res
  }

  private getRequestOptionsForLoadChilds(parentId: number): IReportDicItemsRequestDto {
    const existingPage = this.loadChildRequests[parentId]
    const res: IReportDicItemsRequestDto = {
      parentId: parentId,
      sort: this.requestOptions.sort,
      search: null,
      reportId: this.report.id,
      includeChilds: false,
      pageNumber: typeof existingPage === 'number' ? existingPage + 1 : 0,
      pageSize: this.requestOptions.pageSize,
    }

    return res
  }

  private makeLoadMoreRow(parentId: number): object {
    return {
      id: -parentId,
      parentId: parentId,
    }
  }
}

export default OpenReportDataStore
