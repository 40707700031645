import { action, observable, makeObservable } from 'mobx'
import IUserFullDto from '../../dto/ancient-mser/IUserFullDto'
import UserApi from '../../api/UserApi'
import { IResponse, message } from '../../api/common/ServerApi'
import LoadableStore from '../common/ILoadableStore'
import IUserBriefDto from '../../dto/ancient-mser/IUserBriefDto'
import IPssRequestDto from '../../dto/request/IPssRequestDto'
import UserListStore from '../UserListStore'
import IUserListRequestDto from '../../dto/request/IUserListRequestDto'
import IUserGroupMediumDto from 'src/dto/usergroup/IUserGroupMediumDto'
import IRootStore from '../_root/type'

class UserSelectorStore extends LoadableStore {
  userListStore: UserListStore

  open: boolean = false
  handleSelect: (user: IUserFullDto) => void
  handleCancel: () => void

  showWithApiKey: boolean = false
  showLocked: boolean = false

  selectedUser?: IUserBriefDto

  userDetails: IUserFullDto

  showManyInputModal = false
  manyInputValue: string = ''
  manyInputUsersDetails: IUserFullDto[] = []
  manyInputUsersDetailsIsLoading = false
  manyInputActiveTab: 0 | 1 = 0
  manyInputSaveInProcess = false
  private _manyInputUsersInvalid: string[] = []
  selectedUserGroup: IUserGroupMediumDto | null = null

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable<UserSelectorStore, '_manyInputUsersInvalid'>(this, {
      open: observable,
      selectedUser: observable,
      userDetails: observable,
      userListStore: observable,

      showWithApiKey: observable,
      showLocked: observable,

      openModal: action,
      closeModal: action,
      loadUser: action,
      selectUser: action,
      showManyInputModal: observable,
      manyInputValue: observable,
      manyInputUsersDetails: observable,
      manyInputUsersDetailsIsLoading: observable,
      manyInputActiveTab: observable,
      manyInputSaveInProcess: observable,
      _manyInputUsersInvalid: observable,
    })
  }

  public async initialize(initObj?: any): Promise<IResponse<any>> {
    if (this.initialized) return { success: true }

    const rq = new IUserListRequestDto()
    rq.locked = false
    rq.hasApiKey = false
    this.userListStore = new UserListStore(this.getRootStore)
    const resp = await this.userListStore.initialize(rq)

    this.initialized = true
    return resp
  }

  get disableManyInputSaveTab() {
    return !this.manyInputUsersDetails.length || this.manyInputIsLoading
  }

  get disableManyInputTextTab() {
    return this.manyInputIsLoading
  }

  get manyInputIsLoading() {
    return this.manyInputUsersDetailsIsLoading || this.manyInputSaveInProcess
  }

  get manyInputUsersInvalid() {
    if (!this._manyInputUsersInvalid.length) {
      return null
    }

    return this._manyInputUsersInvalid.join(', ')
  }

  setManyInputUsersInvalid(payload: string[]) {
    this._manyInputUsersInvalid = payload
  }

  get manyInputSubmitButtonLabel() {
    if (this.manyInputActiveTab === 0) {
      return 'Проверить'
    }

    if (this.manyInputActiveTab === 1) {
      return 'Добавить'
    }

    return 'Ok'
  }

  openModal = (
    options: { showWithApiKey: boolean; showLocked: boolean },
    onSelect: (IUserFullDto) => void,
    onCancel?: () => void,
  ) => {
    this.showWithApiKey = options ? options.showWithApiKey : false
    this.showLocked = options ? options.showLocked : false

    this.handleSelect = onSelect
    this.handleCancel = onCancel
    this.open = true
  }

  closeModal = () => {
    this.open = false
  }

  protected async getListRoutine(apiRequestOptions: IPssRequestDto): Promise<IResponse> {
    return UserApi.getUsers(apiRequestOptions)
  }

  loadUser = async (id: number) => {
    this.isLoading = true
    this.loadingError = null
    this.userDetails = null

    const resp = await UserApi.getUser(id)
    if (resp.success) {
      this.userDetails = resp.data
    } else {
      this.userDetails = null
      this.loadingError = message(resp)
    }

    this.isLoading = false
    return resp
  }

  selectUser = (usr: IUserBriefDto) => {
    this.selectedUser = usr
    if (usr && usr.id) {
      if (this.getRootStore().authStore.isSysAdmin) {
        this.loadUser(usr.id)
      } else {
        this.userDetails = usr as IUserFullDto
      }
    } else {
      this.userDetails = null
    }
  }

  resetManyInputPart() {
    this.showManyInputModal = false
    this.manyInputValue = ''
    this.manyInputUsersDetails = []
    this.manyInputUsersDetailsIsLoading = false
    this._manyInputUsersInvalid = []
    this.selectedUserGroup = null
    this.manyInputActiveTab = 0
  }
}

export default UserSelectorStore
