import { action, observable, runInAction, makeObservable } from 'mobx'
import LoadableStore from './common/ILoadableStore'
import { IResponse, message } from '../api/common/ServerApi'
import { findInTree, findParentFor } from '../dto/common/IIdNameTreeDto'
import IUserGroupFullDto from '../dto/usergroup/IUserGroupFullDto'
import UserGroupApi from '../api/UserGroupApi'
import IUserGroupCreateEditDto from '../dto/usergroup/IUserGroupCreateEditDto'
import IUserGroupDeleteDto from '../dto/usergroup/IUserGroupDeleteDto'
import IUserGroupSortableNode, { makeUserGroupTree } from '../dto/usergroup/IUserGroupSortableNode'
import IIdNameDto from '../dto/common/IIdNameDto'
import IRootStore from './_root/type'

class UserGroupStore extends LoadableStore {
  rootUserGroup: IUserGroupFullDto
  rootSortable: IUserGroupSortableNode

  isLoadingDetails: boolean
  loadingDetailsError: string
  userGroupDetails: IUserGroupFullDto

  editingUserGroup: IUserGroupCreateEditDto

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('UserGroupStore.initialize')
    const resp = await this.getRootGroups(initObj && initObj.withUsers)
    this.initialized = true
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      rootUserGroup: observable,
      rootSortable: observable,
      userGroupDetails: observable,
      isLoadingDetails: observable,
      loadingDetailsError: observable,
      editingUserGroup: observable,
      getRootGroups: action,
      getGroupsByFilter: action,
      getUserGroup: action,
      setEditingUserGroup: action,
      createUserGroup: action,
      editUserGroup: action,
      deleteUserGroup: action,
    })
  }

  private makeRootNode(arr: IUserGroupFullDto[]): IUserGroupFullDto {
    return {
      id: null,
      name: 'Все группы',
      admins: [],
      users: [],
      observers: [],
      childs: arr,
      hasChilds: true,
    }
  }

  getRootGroups = async (withUsers: boolean): Promise<IResponse> => {
    console.debug('getRootGroups...')
    this.isLoading = true
    this.loadingError = null
    this.userGroupDetails = null
    const res = await UserGroupApi.getRootGroups(withUsers)

    runInAction(() => {
      if (res.success) {
        this.rootUserGroup = this.makeRootNode(res.data)
      } else {
        this.rootUserGroup = null
        this.loadingError = message(res)
      }
      this.rootSortable = makeUserGroupTree(this.rootUserGroup, this.rootSortable, null, true)
      this.isLoading = false
    })

    return res
  }

  getGroupsByFilter = async (filter: string, withUsers: boolean): Promise<IResponse> => {
    console.debug('getRootGroups...')
    this.isLoading = true
    this.loadingError = null
    const res = await UserGroupApi.getGroupsByFilter({
      pageNumber: 0,
      pageSize: 100,
      search: filter,
      sort: [{ columnName: 'name', asc: true }],
      withUsers: withUsers,
    })

    runInAction(() => {
      if (res.success) {
        this.rootUserGroup = this.makeRootNode(res.data)
      } else {
        this.rootUserGroup = null
        this.loadingError = message(res)
      }

      const tree = makeUserGroupTree(
        this.rootUserGroup,
        this.rootSortable,
        undefined,
        undefined,
        true,
      )
      // expand all
      const expandAll = obj => {
        obj.expanded = true
        if (obj.children)
          obj.children.forEach(x => {
            expandAll(x)
          })
      }
      expandAll(tree)

      this.rootSortable = tree
      this.isLoading = false
    })

    return res
  }

  private replaceNode(node: IUserGroupFullDto, withVals: IUserGroupFullDto) {
    node.id = withVals.id
    node.name = withVals.name
    node.hasChilds = withVals.hasChilds
    node.childs = withVals.childs
    node.users = withVals.users || []
  }

  getUserGroup = async (id: number, withUsers: boolean): Promise<IResponse> => {
    console.debug('getUserGroup...')

    if (!id) return this.getRootGroups(withUsers)

    this.isLoading = true
    this.loadingError = null
    this.userGroupDetails = null
    const res = await UserGroupApi.getUserGroup(id, withUsers)

    runInAction(() => {
      if (res.success) {
        const ug = res.data
        const node = findInTree(this.rootUserGroup, id) as IUserGroupFullDto
        if (node) this.replaceNode(node, ug)
      } else {
        this.loadingError = message(res)
      }
      this.rootSortable = makeUserGroupTree(this.rootUserGroup, this.rootSortable, id, true)
      this.isLoading = false
    })

    return res
  }

  createUserGroup = async (createDto: IUserGroupCreateEditDto) => {
    this.loadingError = null
    this.isLoading = true
    const resp = await UserGroupApi.createUserGroup(createDto)

    runInAction(() => {
      if (resp.success) {
        const node = createDto.parentId
          ? (findInTree(this.rootUserGroup, createDto.parentId) as IUserGroupFullDto)
          : this.rootUserGroup
        if (node) {
          if (!node.childs) node.childs = []
          const ug = resp.data
          node.childs.push(ug)
        }
        this.rootSortable = makeUserGroupTree(
          this.rootUserGroup,
          this.rootSortable,
          createDto.parentId,
          false,
        )
      } else {
        this.loadingError = message(resp)
      }
      this.isLoading = false
    })

    return resp
  }

  editUserGroup = async (editDto: IUserGroupCreateEditDto) => {
    this.loadingError = null
    this.isLoading = true
    const resp = await UserGroupApi.editUserGroup(editDto)

    runInAction(() => {
      if (resp.success) {
        const ug = resp.data
        const node = findInTree(this.rootUserGroup, editDto.id) as IUserGroupFullDto
        if (node) this.replaceNode(node, ug)
        this.rootSortable = makeUserGroupTree(
          this.rootUserGroup,
          this.rootSortable,
          editDto.id,
          false,
        )
      } else {
        this.loadingError = message(resp)
      }
      this.isLoading = false
    })

    return resp
  }

  deleteUserGroup = async (deleteDto: IUserGroupDeleteDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const parent = findParentFor(this.rootUserGroup, deleteDto.id)
    let resp = await UserGroupApi.deleteUserGroup(deleteDto)
    if (resp.success) {
      if (parent) resp = await this.getUserGroup(parent.id, true)
      else resp = await this.getRootGroups(true)
      this.replaceNode(parent as IUserGroupFullDto, resp.data as IUserGroupFullDto)
      this.rootSortable = makeUserGroupTree(
        this.rootUserGroup,
        this.rootSortable,
        parent && parent.id,
        false,
      )
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  changeName = async (dto: IIdNameDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await UserGroupApi.changeName(dto)

    runInAction(() => {
      if (resp.success) {
        const node = findInTree(this.rootUserGroup, dto.id) as IUserGroupFullDto
        node.name = dto.name
        this.rootSortable = makeUserGroupTree(this.rootUserGroup, this.rootSortable, dto.id, false)
      } else {
        this.loadingError = message(resp)
      }
      this.isLoading = false
    })

    return resp
  }

  setEditingUserGroup = (val: IUserGroupCreateEditDto) => {
    this.editingUserGroup = val
  }
}

export default UserGroupStore
