import { CircleLayer, FillLayer } from 'react-map-gl/maplibre'

import { UsingLayer } from 'src/store/map-store/types'

export const ROUTE_SOURCE_ID = 'route-source'

const circleStyle: CircleLayer = {
  id: 'route-source-circles',
  source: ROUTE_SOURCE_ID,
  type: 'circle',
  paint: { 'circle-radius': 12, 'circle-color': '#FF9E00' },
  filter: ['all', ['==', '$type', 'Point']],
}

const fillStyle: FillLayer = {
  id: 'route-source-polygon',
  source: ROUTE_SOURCE_ID,
  type: 'fill',
  paint: { 'fill-color': '#0E51A7', 'fill-opacity': 0.5 },
}

export const ROUTE_SOURCE_STYLE: UsingLayer[] = [circleStyle, fillStyle]