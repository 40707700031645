import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core'
import {observer} from 'mobx-react'
import useStore from '../../context/RootStoreContext'
import {getModalZTop} from '../../util/modal-z-levels'
import {useTheme} from '@material-ui/styles'
import {MscTheme} from '../../theme/type'

function SelectDialog() {
  const theme = useTheme() as MscTheme
  const selectModalStore = useStore().selectModalStore
  const {open, title, value, values, captions, handleConfirm, handleCancel, select } = selectModalStore

  const defaultHandleConfirm = () => {
    if (handleConfirm) handleConfirm(value)
    selectModalStore.closeModal()
  }

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel()
    selectModalStore.closeModal()
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const handleChange = (ev) => {
    select(ev.target.value)
  }

  const renderOptions = () => {
    let i = 0
    return (<>
      {values && values.map(v => {
        return <option key={v} value={v}>{captions[i++]}</option>
      })}

    </>)
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={open}
      style={{zIndex: getModalZTop(theme)}}
      onClose={defaultHandleCancel}
      scroll={'paper'}
      aria-labelledby="text-field-dialog-title"
      aria-describedby="text-field-dialog-description"
    >
      <DialogTitle id="text-field-dialog-title">{title}</DialogTitle>
      <DialogContent
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <TextField
          label={'Обязательное / необязательное'}
          onChange={handleChange}
          value={value}
          name="required"
          InputLabelProps={{shrink: true}}
          select
          SelectProps={{ native: true }}
          variant="outlined"
          fullWidth={true}
        >
          {renderOptions()}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
        </Button>
        <Button onClick={defaultHandleConfirm} color="primary" autoFocus>
              OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(SelectDialog)