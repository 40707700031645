import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from '../_root/RootStoreModel'
import IRootStore from '../_root/type'

class SelectModalStore extends RootStoreModel {
  open: boolean = false
  title: string
  value: string
  values: string[]
  captions: string[]
  handleConfirm?: Function
  handleCancel?: Function

  openModal = (
    title: string,
    initialValue: string,
    values: string[],
    captions: string[],
    handleConfirm?: Function,
    handleCancel?: Function,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.title = title
    this.value = initialValue
    this.values = values
    this.captions = captions
    this.handleConfirm = handleConfirm
    this.handleCancel = handleCancel

    this.open = true
  }

  closeModal = () => {
    this.open = false

    this.title = ''
    this.value = null
    this.values = null
    this.captions = null
    this.handleConfirm = null
    this.handleCancel = null
  }

  select = (value: string) => {
    this.value = value
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      title: observable,
      value: observable,
      values: observable,
      captions: observable,
      openModal: action,
      closeModal: action,
      select: action,
    })
  }
}

export default SelectModalStore
