import ISortableNode, {makeMapOfExpanded} from '../common/ISortableNode'
import IUserGroupBriefDTO from './IUserGroupBriefDto'
import IUserGroupFullDto from './IUserGroupFullDto'
import IUserGroupMediumDto from './IUserGroupMediumDto'
import IUserBriefDto, {makeUserName} from '../ancient-mser/IUserBriefDto'
import {alphaSortFn} from '../../util/objects'

export default interface IUserGroupSortableNode extends ISortableNode{
    id?: number;
    userGroup?: IUserGroupMediumDto;
    user?: IUserBriefDto;

    parentUserGroupId?: number;
    userOwnerGroup?: IUserGroupMediumDto;

    // for "loading" dummies
    isDummy?: boolean;
}

export function customNodeKey ({ node, treeIndex }): string {
  if (!node) return
  if (node.userGroup) return `G${Number(node.userGroup.id)}`
  if (node.user) return `G${node.id}U${node.user.id}`
}

// ---------------

export function makeUserGroupTree(rootUserGroup: IUserGroupBriefDTO | IUserGroupBriefDTO | IUserGroupFullDto,
  oldRootSortable: IUserGroupSortableNode,
  initiatorNodeId?: number, collapseInitiatorChilds?: boolean, forceRootLoaded?: boolean): IUserGroupSortableNode {
  if (!rootUserGroup) return null

  const expandedMap = makeMapOfExpanded([oldRootSortable], customNodeKey)

  // make deep copy of rootProvider

  function _makeNode (node: IUserGroupFullDto, collapsed?: boolean): IUserGroupSortableNode {
    const res: IUserGroupSortableNode = {
      id: node.id,
      children: [],
      expanded: !collapsed && (node.id === initiatorNodeId || node === rootUserGroup ||
                expandedMap[customNodeKey({node: {userGroup: node}, treeIndex: null})]),
      userGroup: node,
    }

    let loaded = (node.childs && node.childs.length && node.hasChilds) || !node.hasChilds
    if (node === rootUserGroup && typeof forceRootLoaded === 'boolean') {
      loaded = forceRootLoaded
    }

    if (loaded) {
      let userNodes = []
      if (node.users) {
        node.users.forEach(x => {
          userNodes.push({
            id: node.id,
            children: [],
            user: x,
            userOwnerGroup: node,
          })
        })
      }

      if (node.observers) {
        node.observers.forEach(x => {
          x.observer = true
          userNodes.push({
            id: node.id,
            children: [],
            user: x,
            userOwnerGroup: node,
          })
        })
      }

      if (node.admins) {
        node.admins.forEach(x => {
          x.admin = true
          userNodes.push({
            id: node.id,
            children: [],
            user: x,
            userOwnerGroup: node,
          })
        })
      }

      userNodes = userNodes.sort((x1, x2) => {
        let s1 = x1.user
        if (x1.user.admin) s1 += '0'
        else if (x1.user.observer) s1 += '2'
        else s1 += '1'
        let s2 = x2.user
        if (x2.user.admin) s2 += '0'
        else if (x2.user.observer) s2 += '2'
        else s2 += '1'
        return alphaSortFn(
          makeUserName(s1),
          makeUserName(s2),
        )
      })

      let childNodes = []
      if (node.childs) {
        node.childs.forEach(x => {
          childNodes.push(_makeNode(x,
            collapsed || ((node.id === initiatorNodeId || !initiatorNodeId) && collapseInitiatorChilds)))
        })
        childNodes = childNodes.sort((x1, x2) => {
          return alphaSortFn(
            x1.userGroup.name,
            x2.userGroup.name,
          )
        })
      }

      res.children = userNodes.concat(childNodes)
    } else if (node.hasChilds) {
      //console.debug('@dummy')
      const dummy: IUserGroupSortableNode = {
        id: -node.id,
        children: [],
        expanded: false,
        userGroup: null,
        parentUserGroupId: node.id,
        isDummy: true,
      }
      //console.debug('dummy.id = ' + dummy.id)
      res.children.push(dummy)
    }
    return res
  }

  const res = _makeNode(rootUserGroup as IUserGroupFullDto)
  //console.debug(JSON.stringify(res));
  return res
}

// ---
