import IColumnDescDto from '../columndesc/IColumnDescDto'
import IColumnGroupDto from '../columngroup/IColumnGroupDto'
import {FLAT_COLUMN_TYPE_MAP} from '../../enums/FLAT_COLUMN_TYPE'
import ISortableNode from '../common/ISortableNode'
import {uuidv4} from '../../util/uuid'

export default interface IColumnSortableNode extends ISortableNode {
    children?: IColumnSortableNode[],

    id?: number;
    uid?: string;
    isGroup?: boolean;

    column?: IColumnDescDto;
    sord?: number;
}

export function customNodeKey ({ node, treeIndex }) {
  if (!node) return null
  return node.id ? node.id : node.uid
}

export function findNodeByUid(nodes: IColumnSortableNode[], uid: string): IColumnSortableNode {
  if (!(nodes && nodes.length)) return null
  if (!uid) return null

  function _find(node: IColumnSortableNode): IColumnSortableNode {
    if (node.uid === uid) return node
    if (node.children && node.children.length) {
      for (let i = 0; i < node.children.length; i++) {
        const res = _find(node.children[i])
        if (res) return res
      }
    }
    return null
  }

  for (let i = 0; i < nodes.length; i++) {
    const res = _find(nodes[i])
    if (res) return res
  }
  return null
}

// ---------------

export function makeGroupTree(columns: IColumnDescDto[], rootColumnGroups: IColumnGroupDto[], outRootColumns: IColumnDescDto[], showDeletedColumns: boolean): IColumnSortableNode[] {
  let newTree = []

  let cols = JSON.parse(JSON.stringify(columns))
  const groups = rootColumnGroups

  // make nodes for columnGroups
  const _makeGroup = (group: IColumnGroupDto): IColumnSortableNode => {
    const res: IColumnSortableNode = {
      uid: group.uid,
      title: group.title,
      children: [],
      isGroup: true,
      sord: group.sord,
      expanded: true,
    }
    if (group.childs) group.childs.forEach(x => { res.children.push(_makeGroup(x)) })
    if (group.columns) {
      group.columns.forEach(x => {
        const columnDesc = x.id ? cols.find(c => c.id === x.id) : cols.find(c => c.colName === x.colName)
        if (!columnDesc) {console.debug('2222: ' + x.colName); return}
        if (!x.id) {
          x.uid = uuidv4()
          columnDesc.uid = x.uid
        }
        const show = showDeletedColumns || !x.deleted
        if (show) {
          res.children.push(makeSortableFromColumnDesc(columnDesc))
        }
        cols = cols.filter(c => customNodeKey({node: c as IColumnSortableNode, treeIndex: null}) !==
                    customNodeKey({node: x as IColumnSortableNode, treeIndex: null}),
        )
      })
    }
    res.children = res.children.sort( (x1, x2) => {return x1.sord - x2.sord} )
    return res
  }
  if (groups) groups.forEach(x => {
    newTree.push(_makeGroup(x))
  })

  // --
  if (outRootColumns) {
    outRootColumns.splice(0, outRootColumns.length)
    cols.forEach(x => {
      outRootColumns.push(JSON.parse(JSON.stringify(x)))
    })
  }
  // /--

  // make node for cols with group == null
  cols.forEach(x => {
    const show = showDeletedColumns || !x.deleted
    if (show) newTree.push(makeSortableFromColumnDesc(x))
  })

  newTree = newTree.sort( (x1, x2) => {return x1.sord - x2.sord} )

  return newTree
}

// ---

export function makeSortableFromColumnDesc(dto: IColumnDescDto): IColumnSortableNode {
  /*if (!dto) return {
        id: 0,
        uid: 'not found',
        title: 'not found',
        subtitle: 'not found',
        isGroup: false,
        column: null,
        sord: 0,
    }*/

  return {
    id: dto.id,
    uid: dto.uid,
    title: dto.colTitle,
    subtitle: `${dto.colName} / ${FLAT_COLUMN_TYPE_MAP[dto.colType]}`,
    isGroup: false,
    column: dto,
    sord: dto.sord,
  }
}

