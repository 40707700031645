import IIdDto from '../common/IIdDto'
import ISortableNode from '../common/ISortableNode'
import IColumnGroupMinimalDto from '../columngroup/IColumnGroupMinimalDto'
import IColumnDescDto from '../columndesc/IColumnDescDto'
import {pathsEqual} from '../../util/column-path-util'
import IFilterFieldDto from '../request/IFilterFieldDto'

export default interface  IFormHeaderDto {
    group: IColumnGroupMinimalDto;
    desc: IIdDto;
    childs: IFormHeaderDto[];
    isGroup: boolean;
    sord: number;

    width: number;
    height: number;
    row: number;
    col: number;

    // front only
    path: number[];
}

// ---------
export interface IFormHeaderRow {
    cols: IFormHeaderDto[];
}

// дерево заголовков
export function makeFormHeaderRows(rootFormHeader: IFormHeaderDto): IFormHeaderRow[] {
  const res: IFormHeaderRow[] = []

  const setTo = (rInd: number, cInd: number, v: IFormHeaderDto) => {
    if (!res[rInd]) res[rInd] = {cols: []}
    res[rInd].cols[cInd] = v
  }

  function fn (node: IFormHeaderDto, path: number[]) {
    if (!node) return

    node.path = JSON.parse(JSON.stringify(path))
    setTo(node.row, node.col, node)

    const newPath = JSON.parse(JSON.stringify(path))
    if (node.desc) newPath.push(node.desc.id)
    if (node.childs) node.childs.forEach(ch => fn(ch, newPath))
  }

  if (rootFormHeader && rootFormHeader.childs) rootFormHeader.childs.forEach(x => fn(x, []))

  res.forEach(r => {
    r.cols = r.cols.filter(c => !!c)
  })

  //console.debug(JSON.stringify(res));

  return res
}

export function makeAGGridFieldName(fh: IFormHeaderDto): string {
  let fieldName = fh.desc.id + ''
  if (fh.path && fh.path.length) fieldName = fh.path.join('x') + 'x' + fieldName
  return fieldName
}

export function makeTitleFromFHPath(path: IFormHeaderDto[], columnMap): string {
  console.debug('IFormHeaderDto makeTitleFromFHPath')
  if (!path) return null
  const arr = []
  path.forEach(x => {
    if (x.group) arr.push(x.group.title)
    else if (x.desc) arr.push(columnMap[x.desc.id].colTitle)
  })
  return arr.join(' \u279C ')
}

export interface IFormHeaderSortableNode extends ISortableNode {
    uid: string;
    children?: IFormHeaderSortableNode[],
    fh: IFormHeaderDto;
    parent: IFormHeaderSortableNode;
    //enabled: boolean;
}

export function makeColumnTreeFromRootFormHeader(rootFormHeader: IFormHeaderDto, columnMap,
  enabledLeafFormHeaders: IFormHeaderDto[]): IFormHeaderSortableNode[] {
  console.debug('makeColumnTreeFromRootFormHeader...')
  const newTree = []

  function walk(parent: IFormHeaderSortableNode, fh: IFormHeaderDto, level: number) {
    if (enabledLeafFormHeaders) {
      if (!fh.isGroup) {
        if (!enabledLeafFormHeaders.find(x => {
          return JSON.stringify(x.path) + x.desc.id === JSON.stringify(fh.path) + fh.desc.id
        })) {
          return
        }
      }
    }
    const res: IFormHeaderSortableNode = {
      uid: fh.group ? `g_level_${level}_${fh.group.id}` : `c_level_${level}_${fh.desc.id}`,
      children: [],
      expanded: true,
      fh: fh,
      parent: parent,
      //enabled: true,
    }
    if (parent) parent.children.push(res)
    else newTree.push(res)
    if (fh.childs) fh.childs.forEach(ch => walk(res, ch, level + 1))
  }

  rootFormHeader.childs.forEach(x => walk(null, x, 0))

  return newTree
}

export function equalsByDesc(fh1: IFormHeaderDto, fh2: IFormHeaderDto): boolean {
  if (!fh1 || !fh2 || !fh1.desc || !fh2.desc) return false
  if (fh1.desc.id !== fh2.desc.id) return false
  const plen1 = fh1.path ? fh1.path.length : 0
  const plen2 = fh2.path ? fh2.path.length : 0
  if (plen1 !== plen2) return false
  if (plen1 === 0) return true
  return JSON.stringify(fh1.path) === JSON.stringify(fh2.path)
}

export function columnForFilter(ff: IFilterFieldDto, fh: IFormHeaderDto, columnMap: any): IColumnDescDto {
  if (fh.desc) {
    if ( (columnMap[fh.desc.id].colName === ff.columnName) &&
            pathsEqual(ff.path, fh.path)
    ) return columnMap[fh.desc.id]
  }

  if (fh.childs) {
    let res = null
    fh.childs.forEach(ch => {
      if (!res) {
        res = columnForFilter(ff, ch, columnMap)
      }
    })
    return res
  }
}