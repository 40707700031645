import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core'
import Page from '../../components/Page'
import LoginForm from './LoginForm'
import { getMatchProp } from '../../../util/match-util'
import { useStyles } from '../styles'
import { DEFAULT_SUBSYSTEM, SubsystemEnum } from '../../../util/constants'
import AuthApi from '../../../api/AuthApi'
import { showResponseError } from '../../../util/enqueue-util'
import { useSnackbar } from 'notistack'

export function getSubsystem(props: any): SubsystemEnum {
  return getMatchProp(props, 'subsystem') as SubsystemEnum
}

function LoginView(props) {
  console.debug('LoginView...')

  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const subsystem = getSubsystem(props)
  let subsystemName: string
  switch (subsystem) {
    case 'shud':
      subsystemName = 'Подсистема сбора, хранения и управления данными'
      break
    case 'nsi':
      subsystemName = 'Подсистема нормативно-справочной информации'
      break
    case 'data':
      subsystemName = 'Data Platform'
      break
  }

  const handleSubmitSuccess = () => {
    let redirectUrl: string
    switch (subsystem) {
      case 'nsi':
        redirectUrl = '/nsi/'
        break
      case 'shud':
        redirectUrl = '/shud/'
        break
      case 'data':
        redirectUrl = '/data/'
        break
      default:
        redirectUrl = `/${DEFAULT_SUBSYSTEM}/`
        break
    }
    history.push(redirectUrl)
  }

  const re = new RegExp(`/${subsystem}/signinLocal.*`)
  const myRootUrl = window.location.href.replace(re, '')

  const redirectUrl = `${myRootUrl}/${subsystem}/centralWaiter`
  const scServerUrl = `${process.env.REACT_APP_AUTH_SERVER_2}?duplicateTokenInUrl=true&ref=${redirectUrl}`

  return (
    <Page title="Авторизация">
      <Container maxWidth="sm" style={{ display: 'flex', height: '100%' }}>
        <Card className={classes.card} id="crd">
          <CardContent className={classes.content}>
            {/* <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar> */}

            <Typography variant="h3" color="textPrimary" align="center">
              {subsystemName}
            </Typography>

            <br />
            <Typography variant="h1" color="textPrimary" align="center">
              Авторизация
            </Typography>

            <br />
            <LoginForm onSubmitSuccess={handleSubmitSuccess} />
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default LoginView
