import {FORM_TYPE} from '../../enums/FORM_TYPE'
import INameDto from '../common/INameDto'
import IReportBriefDto from '../report/IReportBriefDto'
import {SubsystemEnum} from '../../util/constants'

export default interface IFormBriefDto {
  id: number
  dataSource: string
  mnemo: string
  tableName: string
  tableTitle: string
  tableDescription: string
  register: boolean
  open: boolean
  tree: boolean
  formType: FORM_TYPE
  structureFixed: boolean
  intermediateCanEdit: boolean
  intermediateCanSetSupplierFirst: boolean
  tags: INameDto[]
  acceptionDate: Date
  useTemplateState: boolean
  hasVisibleGeom: boolean
  // transient
  actualReport: IReportBriefDto
  rootUserGroupId: number
  topLevelUnapproved: number
}

export function tagsString(dto: { tags: INameDto[] }): string {
  if (!dto.tags) return ''
  return dto.tags.map(x => x.name).join(', ')
}

export function periodEndLabel(formType: FORM_TYPE): string {
  switch (formType) {
    case 'REPORT':
      return 'Конец периода'
    case 'REGISTER':
      return 'По состоянию на'
    case 'GEO_REGISTER':
      return 'По состоянию на'
    case 'NSI':
    case 'CNSI':
      return 'Дата принятия'
    default:
      return ''
  }
}

export function myFormListTitleBySubsystem(subsystem: SubsystemEnum): string {
  switch (subsystem) {
    case 'nsi':
      return 'Справочники'
    case 'shud':
      return 'Формы/реестры'
    case 'data':
      return 'Формы/реестры'
  }
}

export function myFormListTitleByFormType(formType: FORM_TYPE | FORM_TYPE[]): string {
  if (Array.isArray(formType)) formType = formType[0]
  switch (formType) {
    case 'REPORT':
      return 'формы'
    case 'REGISTER':
      return 'реестры'
    case 'GEO_REGISTER':
      return 'Гео реестры (слои)'
    case 'NSI':
      return 'Cправочники'
    case 'CNSI':
      return 'Ссправочники'
  }
}

export function reportListTitle(formType: FORM_TYPE): string {
  if (!formType) return 'Список'
  switch (formType) {
    case 'NSI':
    case 'CNSI':
    case 'REGISTER':
    case 'GEO_REGISTER':
      return 'Список версий'
    case 'REPORT':
      return 'Список отчетов'
  }
  return 'Список'
}

export function reportTitle(formType: FORM_TYPE): string {
  switch (formType) {
    case 'NSI':
    case 'CNSI':
    case 'REGISTER':
    case 'GEO_REGISTER':
      return 'Версия'
    case 'REPORT':
      return 'Отчет'
  }
}

export function openFormLink(formType: FORM_TYPE): string {
  return 'openRegisters'
}
