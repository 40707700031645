import { action, makeObservable } from 'mobx'
import IFormBriefDto from '../dto/form/IFormBriefDto'
import StructureApi from '../api/StructureApi'
import BaseListStore from './common/BaseListStore'
import IFormListRequestDto from '../dto/request/IFormListRequestDto'
import { IResponse } from '../api/common/ServerApi'
import { copyAndSpread } from '../util/objects'
import { FORM_TYPE } from '../enums/FORM_TYPE'
import IRootStore from './_root/type'

class FormListStore extends BaseListStore<IFormBriefDto, IFormListRequestDto> {
  permittedTypes?: FORM_TYPE[]

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('FormListStore.initialize...')

    // sync synhro
    this.requestOptions = this.getDefaultRequestOptions()
    if (
      initObj &&
      typeof initObj === 'object' &&
      initObj.requestOptions &&
      typeof initObj.requestOptions === 'object'
    ) {
      this.requestOptions = copyAndSpread(this.requestOptions, initObj.requestOptions)
    }

    //await this.loadDics();
    const resp = await this.load(this.requestOptions)
    this.getRootStore().tagListStore.getTags()
    this.getRootStore().tagListStore.selectedTags = this.requestOptions.tags || []

    this.initialized = true
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      //subsystem: observable,
      validateAndApplySubsystemFilter: action,
    })
  }

  protected getDefaultRequestOptions(): IFormListRequestDto {
    return new IFormListRequestDto()
  }

  protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
    return // возможно и не нужно
    //return StructureApi.deleteForms(ids);
  }

  protected async deleteOneRoutine(id: number): Promise<IResponse> {
    return StructureApi.deleteForm(id)
  }

  protected async getListRoutine(apiRequestOptions: IFormListRequestDto): Promise<IResponse> {
    return StructureApi.getForms(apiRequestOptions)
  }

  load = async (requestOptions: IFormListRequestDto): Promise<IResponse> => {
    if (this.permittedTypes && this.permittedTypes.length) {
      if (requestOptions) {
        if (!(requestOptions.formTypes && requestOptions.formTypes.length))
          requestOptions.formTypes = this.permittedTypes
        else
          requestOptions.formTypes = requestOptions.formTypes.filter(x =>
            this.permittedTypes.includes(x),
          )
      }
    }
    requestOptions.lkUserGroupsIds = this.getRootStore().authStore.lkUserGroupsIds
    return this.loadList(requestOptions)
  }

  validateAndApplySubsystemFilter = (permittedTypes: FORM_TYPE[]): boolean => {
    this.permittedTypes = permittedTypes
    if (
      !(
        this.requestOptions &&
        this.requestOptions.formTypes &&
        this.requestOptions.formTypes.length
      )
    ) {
      this.requestOptions = this.getDefaultRequestOptions()
      this.requestOptions.formTypes = permittedTypes
      return false
    } else {
      let needReload = false
      if (permittedTypes) {
        this.requestOptions.formTypes.forEach(x => {
          if (!permittedTypes.includes(x)) {
            needReload = true
          }
        })
      }

      if (needReload) {
        this.requestOptions.formTypes = permittedTypes
      }
      return needReload
    }
  }
}

export default FormListStore
