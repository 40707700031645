export default interface ISortableNode {
    title?: string;
    subtitle?: string;
    children?: ISortableNode[],
    expanded?: boolean,

    // temporary for filtering
    _matches?: boolean;
}

export function findNodeByPath(nodes: ISortableNode[], path: number[] | string[],
  customNodeKey: ({node, treeIndex} ) => number | string) {
  if (!(path && path.length && nodes && nodes.length)) return null

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (customNodeKey({node: node, treeIndex: null}) === path[0]) {
      if (path.length === 1) return node
      else return findNodeByPath(node.children, path.slice(1, path.length), customNodeKey)
    }
  }

  return null
}

export function makeMapOfExpanded(rootNodes: ISortableNode[], customNodeKey: ({node, treeIndex}) => number | string) {
  const expandedMap = {}
  if (!rootNodes && rootNodes.length) return expandedMap

  rootNodes.forEach(r => {
    function w1(node: ISortableNode) {
      if (node.expanded) {
        expandedMap[customNodeKey({node: node, treeIndex: null})] = true
        if (node.children) node.children.forEach(ch => {w1(ch)})
      }
    }
    if (r) w1(r)
  })

  //console.debug(JSON.stringify(expandedMap));

  return expandedMap
}

export function filterTree(rootNodes: ISortableNode[], matchFn: (node: ISortableNode) => boolean): ISortableNode[] {

  function mark(node: ISortableNode) {
    if (!node) return
    node._matches = matchFn(node)
    if (node.children) node.children.forEach(x => {
      mark(x)
      if (x._matches) node._matches = true
    })
  }

  function flt(nodes: ISortableNode[]): ISortableNode[] {
    const res: ISortableNode[] = nodes.filter(x => x._matches)
    res.forEach(x => {
      x.children = flt(x.children)
    })
    return res
  }

  if (!rootNodes) return
  rootNodes.forEach(x => {mark(x)})
  return flt(rootNodes)
}