import { action, observable, makeObservable } from 'mobx'
import StructureApi from '../api/StructureApi'
import IFormFullDto from '../dto/form/IFormFullDto'
import ICreateEditFormDto from '../dto/form/ICreateEditFormDto'
import ICreateEditColumnDescDto from '../dto/columndesc/ICreateEditColumnDescDto'
import LoadableStore from './common/ILoadableStore'
import { IResponse, message } from '../api/common/ServerApi'
import IColumnDescDto from '../dto/columndesc/IColumnDescDto'
import IColumnSortableNode, { customNodeKey, makeGroupTree } from '../dto/form/IColumnSortableNode'
import { copyAndSpread } from '../util/objects'
import {
  addNodeUnderParent,
  changeNodeAtPath,
  getFlatDataFromTree,
  removeNodeAtPath,
} from 'react-sortable-tree'
import { uuidv4 } from '../util/uuid'
import ICreateEditColumnGroupDto from '../dto/columngroup/ICreateEditColumnGroupDto'
import { findNodeByPath } from '../dto/common/ISortableNode'
import { SET_FORM_COLUMNS_METHOD } from '../enums/SET_FORM_COLUMNS_METHOD'
import IFormScheduleDto from '../dto/form/IFormScheduleDto'
import IColumnGroupDto from '../dto/columngroup/IColumnGroupDto'
import IRootStore from './_root/type'

class FormProfileStore extends LoadableStore {
  form: IFormFullDto
  editingColumns: IColumnDescDto[] = []
  groupTree: IColumnSortableNode[] = []
  showDeletedColumns: boolean
  setFormColumnsMethod: SET_FORM_COLUMNS_METHOD

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('formListStore.initialize')
    const id = initObj as number
    let res
    if (id) {
      res = await this.loadForm(id)
    } else {
      this.form = null
      res = { success: true }
    }

    this.initialized = true
    return res
  }

  readFormResponse = (resp: IResponse<IFormFullDto>) => {
    if (resp.success) {
      this.form = resp.data
      if (this.form.tags)
        this.getRootStore().tagListStore.selectedTags = this.form.tags.map(x => x.name)
    } else {
      this.form = null
      this.loadingError = message(resp)
      return resp
    }

    if (this.form && this.form.columns)
      this.editingColumns = JSON.parse(JSON.stringify(this.form.columns))
    else this.editingColumns = []

    this.groupTree = makeGroupTree(this.form.columns, this.form.rootColumnGroups, null, true)
  }

  loadForm = async (id: number): Promise<IResponse> => {
    this.isLoading = true
    this.loadingError = null
    const res = await StructureApi.getForm2({
      id: id,
      includeColumnHasData: true,
      includeColumns: true,
      includeReports: true,
    })

    this.readFormResponse(res)

    this.isLoading = false
    return res
  }

  createForm = async (createDto: ICreateEditFormDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await StructureApi.createForm(createDto)
    if (resp.success) {
      this.form = resp.data
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  editForm = async (editDto: ICreateEditFormDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await StructureApi.editForm(editDto)
    if (resp.success) {
      this.form = resp.data
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  setFormColumns = async (): Promise<IResponse> => {
    this.treeToFlat()

    this.loadingError = null
    this.isLoading = true

    const resp = await StructureApi.setFormColumnsWithGroups({
      id: this.form.id,
      columns: this.makeCreateEditColumnDtos(this.editingColumns),
      columnGroups: this.makeCreateEditColumnGroupDtos(this.groupTree),
      method: this.setFormColumnsMethod,
    })

    if (resp.success) {
      const resp2 = await this.loadForm(this.form.id)
      if (!resp2.success) {
        this.loadingError = message(resp2)
      }
      this.isLoading = false
      return resp2
    } else {
      this.loadingError = message(resp)
      this.isLoading = false
      return resp
    }
    this.isLoading = false
  }

  makeCreateEditColumnDtos = (columns: IColumnDescDto[]): ICreateEditColumnDescDto[] => {
    let res: ICreateEditColumnDescDto[] = []
    if (!columns) return res
    res = columns.map(x => {
      const y = x as ICreateEditColumnDescDto
      if (!(y.refForm && y.refForm.id)) y.refForm = null
      return y
    })
    return res
  }

  constructor(root: () => IRootStore) {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super(root)

    makeObservable(this, {
      form: observable,
      editingColumns: observable,
      groupTree: observable,
      setFormColumnsMethod: observable,
      loadForm: action,
      createForm: action,
      editForm: action,
      setFormColumns: action,
      editEditingColumn: action,
      addEditingColumn: action,
      editingDeleteGroup: action,
      editingRenameGroup: action,
      editingAddGroup: action,
      removeEditingColumn: action,
      //fullRemoveEditingColumn: action,
      switchShowDeletedColumns: action,
      createFormSchedule: action,
      removeUnsavedFormSchedule: action,
      saveSchedule: action,
      deleteSchedule: action,
      getColumnsAndGroupsCopy: action,
      getColumnsFromXlsxSample: action,
      copyForm: action,
      readFormResponse: action,
      mergeStructurePart: action,
    })
  }

  private getFlatTree() {
    const flatMap = getFlatDataFromTree({
      treeData: this.groupTree,
      getNodeKey: customNodeKey,
      ignoreCollapsed: false,
    })
    let i = 100
    flatMap.forEach(x => {
      x.node.sord = i
      i += 100
    })
    return flatMap
  }

  makeCreateEditColumnGroupDtos = (groups: IColumnSortableNode[]): ICreateEditColumnGroupDto[] => {
    const res: ICreateEditColumnGroupDto[] = []
    if (!groups) return res

    const flatMap = getFlatDataFromTree({
      treeData: this.groupTree,
      getNodeKey: customNodeKey,
      ignoreCollapsed: false,
    })

    flatMap.forEach(x => {
      const node = x.node
      if (node.isGroup) {
        const v: ICreateEditColumnGroupDto = {
          uid: node.uid,
          sord: x.node.sord,
          title: node.title,
          parentUid: x.parentNode && x.parentNode.uid,
        }
        res.push(v)
      }
    })

    return res
  }

  editEditingColumn = (
    node: IColumnSortableNode,
    path: number[] | string[],
    newDesc: IColumnDescDto,
  ) => {
    const treeDataNew = changeNodeAtPath({
      treeData: this.groupTree,
      path: path,
      newNode: copyAndSpread(node, { column: newDesc }),
      ignoreCollapsed: true,
      getNodeKey: customNodeKey,
    })
    this.groupTree = treeDataNew

    this.treeToFlat()
  }

  addEditingColumn = (dto: IColumnDescDto, parentNode: IColumnSortableNode) => {
    const newNode = {
      id: dto.id,
      uid: dto.uid,
      column: dto,
      title: 'Новая колонка',
      isGroup: false,
    }

    this.groupTreeAddNode(newNode, parentNode)
  }

  removeEditingColumn = (node: IColumnSortableNode, path: number[] | string[]) => {
    this.groupTree = removeNodeAtPath({
      treeData: this.groupTree,
      path: path,
      ignoreCollapsed: true,
      getNodeKey: customNodeKey,
    })
  }

  /*fullRemoveEditingColumn = async (node: IColumnSortableNode, path: number[] | string[]) => {
    const resp = await StructureApi.deleteColumnWithData(node.column.id);
    if (resp.success) {
      this.groupTree = removeNodeAtPath({
        treeData: this.groupTree,
        path: path,
        ignoreCollapsed: true,
        getNodeKey: customNodeKey,
      });
    }
    return resp;
  }*/

  private groupTreeAddNode(newNode: IColumnSortableNode, parentNode: IColumnSortableNode) {
    let treeDataNew = this.groupTree

    if (!parentNode) {
      if (!treeDataNew) treeDataNew = []
      treeDataNew = treeDataNew.concat([newNode])
      this.groupTree = treeDataNew
    } else {
      treeDataNew = addNodeUnderParent({
        treeData: this.groupTree,
        parentKey: customNodeKey({ node: parentNode, treeIndex: null }),
        newNode: newNode,
        getNodeKey: customNodeKey,
        ignoreCollapsed: true,
        expandParent: true,
      })
      // видимо баг в либе, ответ завернут в лишний wrapper
      this.groupTree = (treeDataNew as any).treeData
    }

    //this.treeToFlat();
  }

  private treeToFlat() {
    const flatMap = this.getFlatTree()
    let i = 0
    this.editingColumns = flatMap
      .map(x => {
        if (!x.node.column) return null
        else {
          x.node.column.sord = x.node.sord
          x.node.column.ind = i
          i++
          if (x.parentNode) x.node.column.columnGroupUid = x.parentNode.uid
          return x.node.column
        }
      })
      .filter(x => !!x)
  }

  // --- column groups operations
  editingDeleteGroup = (node: any, path: number[] | string[], deleteChildren: boolean) => {
    let childsToMove = null
    if (!deleteChildren && node.children && node.children.length) childsToMove = node.children

    this.groupTree = removeNodeAtPath({
      treeData: this.groupTree,
      path: path,
      ignoreCollapsed: true,
      getNodeKey: customNodeKey,
    })

    if (childsToMove) {
      const parentPath = path
      parentPath.pop()
      if (parentPath.length) {
        const newParent = findNodeByPath(this.groupTree, parentPath, customNodeKey)
        newParent.children = newParent.children.concat(childsToMove)
        console.debug('path: ' + JSON.stringify(parentPath))
        console.debug('newParent: ' + JSON.stringify(newParent))
        this.groupTree = changeNodeAtPath({
          treeData: this.groupTree,
          path: parentPath,
          newNode: newParent,
          ignoreCollapsed: true,
          getNodeKey: customNodeKey,
        })
      } else {
        childsToMove.forEach(x => {
          this.groupTreeAddNode(x, null)
        })
      }
      /*childsToMove.forEach(x => {
        this.groupTreeAddNode(x, null);
      });*/
    }
  }

  editingRenameGroup = (node: any, path: number[] | string[], newTitle: string) => {
    const treeDataNew = changeNodeAtPath({
      treeData: this.groupTree,
      path: path,
      newNode: copyAndSpread(node, { title: newTitle }),
      ignoreCollapsed: true,
      getNodeKey: customNodeKey,
    })
    this.groupTree = treeDataNew
  }

  editingAddGroup = (parentNode: IColumnSortableNode) => {
    const newNode = {
      title: 'Новая группа',
      isGroup: true,
      uid: uuidv4(),
    }
    this.groupTreeAddNode(newNode, parentNode)
  }
  // --- \column groups operations

  // --- SortableTree

  switchShowDeletedColumns = (newVal: boolean) => {
    console.debug(`switchShowDeletedColumns = ${newVal}`)
    /*this.showDeletedColumns = newVal;
    this.groupTree = makeGroupTree(this.form, null, this.showDeletedColumns);*/
  }
  // ---

  createFormSchedule = () => {
    this.form.formSchedules.push({})
  }

  removeUnsavedFormSchedule = () => {
    this.form.formSchedules = this.form.formSchedules.filter(x => !!x.id)
  }

  saveSchedule = async (dto: IFormScheduleDto): Promise<IResponse<IFormScheduleDto[]>> => {
    dto.form = { id: this.form.id }
    const resp = await StructureApi.saveFormSchedule(dto)
    if (resp.success) this.form.formSchedules = resp.data
    return resp
  }

  deleteSchedule = async (id: number): Promise<IResponse> => {
    const resp = await StructureApi.deleteFormSchedule(id)
    if (resp.success) this.form.formSchedules = this.form.formSchedules.filter(x => x.id !== id)
    return resp
  }

  clearFormSchedules = async (formId: number): Promise<IResponse> => {
    const resp = await StructureApi.clearFormSchedules(formId)
    if (resp.success) this.form.formSchedules = []
    return resp
  }

  getColumnsAndGroupsCopy = async (srcFormId: number): Promise<IResponse> => {
    const resp = await StructureApi.getColumnsAndGroupsCopy(srcFormId, this.form.id)
    if (resp.success) {
      this.editingColumns = resp.data.columns
      this.editingColumns.forEach(x => {
        x.uid = uuidv4()
      })
      this.groupTree = makeGroupTree(this.editingColumns, resp.data.rootColumnGroups, null, true)
    }
    return resp
  }

  getColumnsFromXlsxSample = async (file: File): Promise<IResponse> => {
    const resp = await StructureApi.getColumnsFromXlsxSample(file)
    if (resp.success) {
      const newColumns: IColumnDescDto[] = resp.data
      newColumns.forEach(x => {
        x.uid = uuidv4()
      })
      this.editingColumns = this.editingColumns.filter(x => x.internal).concat(newColumns)
      this.groupTree = makeGroupTree(this.editingColumns, [], null, true)
    }
    return resp
  }

  copyForm = async (srcFormId: number): Promise<IResponse> => {
    this.isLoading = true
    const resp = await StructureApi.copyForm({
      srcFormId: srcFormId,
      destFormId: this.form.id,
    })
    this.readFormResponse(resp)
    this.isLoading = false
    return resp
  }

  mergeStructurePart = (newColumns: IColumnDescDto[], newGroups: IColumnGroupDto[]) => {
    function resetIds(g: IColumnGroupDto) {
      g.uid = uuidv4()
      if (g.columns) {
        g.columns.forEach(c => {
          newColumns.push(c)
        })
      }
      if (g.childs) {
        g.childs.forEach(ch => {
          resetIds(ch)
        })
      }
    }
    newGroups.forEach(newG => {
      resetIds(newG)
    })

    newColumns.forEach(col => {
      col.id = null
      col.uid = uuidv4()
    })

    const newTreePart = makeGroupTree(newColumns, newGroups, null, false)
    this.groupTree = this.groupTree.concat(newTreePart)
  }
}

export default FormProfileStore
