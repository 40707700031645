import React, {FunctionComponent, useCallback, useState} from 'react'
import {Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {observer} from 'mobx-react'
import useStore from '../../context/RootStoreContext'
import {getModalZTop} from '../../util/modal-z-levels'
import {useTheme} from '@material-ui/styles'
import {MscTheme} from '../../theme/type'

const FileSelectDialog: FunctionComponent<{}> = ({children, ...rest}) => {
  const theme = useTheme() as MscTheme
  const store = useStore().fileSelectModalStore
  const { file, open, title, body, handleConfirm, handleCancel } = store

  const handleChangeFile = useCallback((ev) => {
    store.file = ev.target.files[0]
  }, [])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const defaultHandleConfirm = async () => {
    setIsSubmitting(true)
    if (handleConfirm) await handleConfirm(file)
    store.closeModal()
    setIsSubmitting(false)
  }

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel()
    store.closeModal()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={open}
      style={{zIndex: getModalZTop(theme)}}
      onClose={defaultHandleCancel}
      aria-labelledby="file-select-dialog-title"
      aria-describedby="file-select-dialog-description"
    >
      <DialogTitle id="file-select-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box>{body}</Box>

        <Box>
          <input type={'file'} onChange={handleChangeFile}/>
        </Box>

        <Box>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
        </Button>
        <Button variant={'contained'} onClick={defaultHandleConfirm} color="primary" autoFocus disabled={isSubmitting || !file}>
              OK {isSubmitting && <CircularProgress/>}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(FileSelectDialog)