import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@material-ui/core'
import {observer} from 'mobx-react'
import useStore from '../../context/RootStoreContext'
import LoadingScreen from '../components/LoadingScreen'
import {MscTheme} from '../../theme/type'
import CloseIcon from '@material-ui/icons/Close'
import {getModalZTop} from '../../util/modal-z-levels'
import {useTheme} from '@material-ui/styles'
import {useCommonStyles} from '../../util/common-classes'

function InfoDialog() {
  const theme = useTheme() as MscTheme
  const classes = useCommonStyles()
  const infoModalStore = useStore().infoModalStore
  const {open, title, body, url, element, isLoading, htmlContent, hardModal} = infoModalStore

  const handleClose = (event: object, reason: string) => {
    if (!(reason === 'backdropClick' && hardModal)) {
      infoModalStore.closeModal()
    }
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <Dialog
      style={{zIndex: getModalZTop(theme)}}
      maxWidth={'xl'}
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
    >
      <DialogTitle id="info-dialog-title">
        {title}
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => {handleClose(null, null)}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={descriptionElementRef} tabIndex={-1} >
        {body && <div>{body}</div>}
        {htmlContent && (
          <>
            {isLoading ?
              (<LoadingScreen/>)
              :
              (<div dangerouslySetInnerHTML={{__html: htmlContent}}/>)
            }
          </>
        )}
        {element}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleClose(null, null)}} color="primary" autoFocus>
              Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(InfoDialog)