
export function saveLocalFile(data: string | ArrayBuffer, filename: string, type?: string) {
  if (!data) return
  const bb = new Blob([data], { type: type ? type : 'application/octet-stream' })
  const a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(bb)
  a.click()
}

export function cleanFileName(s: string): string {
  if (!s) return 'unnamed_file'
  s = s.trim()
  if (s[0] == '"' && s[s.length - 1] == '"') s = s.substr(1, s.length - 2)
  return s
}