import React, {useCallback} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core'
import {observer} from 'mobx-react'
import useStore from '../../context/RootStoreContext'
import {getModalZTop} from '../../util/modal-z-levels'
import {useTheme} from '@material-ui/styles'
import {MscTheme} from '../../theme/type'

function TextFieldDialog() {
  const theme = useTheme() as MscTheme
  const textFieldModalStore = useStore().textFieldModalStore
  const {open, width, rows, title, value, maxlen, handleConfirm, handleCancel } = textFieldModalStore

  const defaultHandleConfirm = () => {
    if (handleConfirm) handleConfirm(value)
    textFieldModalStore.closeModal()
  }

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel()
    textFieldModalStore.closeModal()
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const handleChange = useCallback( (ev) => {
    textFieldModalStore.value = ev.target.value
  }, [])

  return (
    <Dialog
      maxWidth={'lg'}
      open={open}
      style={{zIndex: getModalZTop(theme)}}
      onClose={defaultHandleCancel}
      scroll={'paper'}
      aria-labelledby="text-field-dialog-title"
      aria-describedby="text-field-dialog-description"
    >
      <DialogTitle id="text-field-dialog-title">{title}</DialogTitle>
      <DialogContent
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <TextField
          style={{width: width}}
          fullWidth={true}
          multiline={true}
          rows={4}
          rowsMax={rows}
          value={value}
          inputProps={{
            maxLength: maxlen,
          }}
          onChange={handleChange}
          label={'Ввведите текст'}
          InputLabelProps={{shrink: true}}
          variant={'outlined'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
        </Button>
        <Button onClick={defaultHandleConfirm} color="primary" autoFocus>
              OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(TextFieldDialog)