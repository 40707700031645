import { action, makeObservable, observable } from 'mobx'
import BaseListStore from '../common/BaseListStore'
import { IResponse } from '../../api/common/ServerApi'
import IReportDto from '../../dto/report/IReportDto'
import DataApi from '../../api/DataApi'
import StructureApi from '../../api/StructureApi'
import IFormFullDto, { getFlatGroupUids } from '../../dto/form/IFormFullDto'
import IColumnSortableNode, { makeGroupTree } from '../../dto/form/IColumnSortableNode'
import IColumnDescDto from '../../dto/columndesc/IColumnDescDto'
import { DIC_REQUEST_MODE } from '../../enums/DIC_REQUEST_MODE'
import IFormHeadersRequestDto from '../../dto/request/IFormHeadersRequestDto'
import IFormHeaderDto, { IFormHeaderRow, makeFormHeaderRows } from '../../dto/form/IFormHeaderDto'
import IColumnGroupDto from '../../dto/columngroup/IColumnGroupDto'
import IReportBaseDataRequestDto from '../../dto/report/IReportBaseDataRequestDto'
import IReportColumnsDto from '../../dto/report/IReportColumnsDto'
import IFormColumnsDto from '../../dto/form/IFormColumnsDto'
import IRootStore from '../_root/type'

abstract class BaseDataStore<T, S extends IReportBaseDataRequestDto> extends BaseListStore<T, S> {
  report: IReportDto
  form: IFormFullDto
  rootFormHeader: IFormHeaderDto
  rootFormHeaderMinimalDetail: IFormHeaderDto

  formHeaderRows: IFormHeaderRow[]
  formHeaderMinimalDetailRows: IFormHeaderRow[]
  groupTree: IColumnSortableNode[] = []
  rootColumns: IColumnDescDto[] = []
  flatGroupUids: string[] = []

  virtualColumns: IColumnDescDto[]
  virtualGroups: IColumnGroupDto[]

  dicsReportColumns: IReportColumnsDto[]
  columnMap: {}
  groupMap: {}
  leafFormHeaders: IFormHeaderDto[] = []

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      form: observable,
      report: observable,
      formHeaderRows: observable,
      formHeaderMinimalDetailRows: observable,
      groupTree: observable,
      rootColumns: observable,
      flatGroupUids: observable,
      columnMap: observable,
      groupMap: observable,
      setReportAgreed: action,
    })
  }

  public async initialize(initObj?: any): Promise<IResponse> {
    console.debug('BaseDataStore::initialize...')
    this.beforeInit()

    let formId = 0

    if (initObj.requestOptions) this.requestOptions = initObj.requestOptions
    else this.requestOptions = this.getDefaultRequestOptions()

    if (!initObj.reportId) {
      formId = initObj.formId
      this.requestOptions.reportId = null
      this.requestOptions.formId = initObj.formId
      this.report = null
    } else {
      const reportId = initObj.reportId
      this.requestOptions.reportId = reportId

      const resp1 = await DataApi.getReport(reportId)
      if (resp1.success) {
        this.report = resp1.data
        formId = this.report.form.id
      } else {
        this.loadingError = resp1.message
        this.isLoading = false
        return resp1
      }
    }

    const resp2 = await StructureApi.getForm(formId)
    if (resp2.success) {
      const resp2_0 = await StructureApi.getVirtualFormColumnsDTO();
      if (resp2_0.success) {
        this.virtualColumns = resp2_0.data.columns
        this.virtualGroups = resp2_0.data.rootColumnGroups;
      } else {
        this.loadingError = resp2_0.message
        this.isLoading = false
        return resp2_0;
      }

      this.form = resp2.data
      const resp2_1 = await this.initHeaders(
        this.requestOptions ? this.requestOptions.dicRequestMode : null,
          this.requestOptions ? this.requestOptions.includeReferrerFields : null,
          this.requestOptions ? this.requestOptions.includeRowInfoAsColumns : null,
      )
      if (!resp2_1.success) {
        this.loadingError = resp2_1.message
        this.isLoading = false
        return resp2_1
      }
    } else {
      this.loadingError = resp2.message
      this.isLoading = false
      return resp2
    }

    return resp2
  }

  initHeaders = async (dicRequestMode: DIC_REQUEST_MODE, includeReferrerFields: boolean,
                       includeRowInfoAsColumns: boolean): Promise<IResponse> => {
    const rqDto: IFormHeadersRequestDto = {
      formId: this.form.id,
      reportId: this.report ? this.report.id : this.form.reports[0].id,
      dicRequestMode: dicRequestMode,
      includeReferrerFields: includeReferrerFields,
      includeRowInfoAsColumns: includeRowInfoAsColumns,
    }

    const resp1 = await StructureApi.getReportDictionaries(rqDto)
    if (!resp1.success) {
      this.loadingError = resp1.message
      this.isLoading = false
      return resp1
    }
    this.dicsReportColumns = resp1.data

    const resp2 = await StructureApi.getFormHeaders(rqDto)
    if (!resp2.success) {
      this.loadingError = resp2.message
      this.isLoading = false
      return resp2
    }
    this.rootFormHeader = resp2.data
    this.formHeaderRows = makeFormHeaderRows(this.rootFormHeader)
    this.fillLeafFormHeaders()
    this.fillMaps()

    if (dicRequestMode && dicRequestMode.startsWith('DRE')) {
      const respFHMin = await StructureApi.getFormHeaders({
        ...rqDto,
        dicRequestMode: 'ID_CODE_NAME',
      })
      if (!respFHMin.success) {
        this.loadingError = resp2.message
        this.isLoading = false
        return respFHMin
      }
      this.rootFormHeaderMinimalDetail = respFHMin.data
      this.formHeaderMinimalDetailRows = makeFormHeaderRows(this.rootFormHeaderMinimalDetail)
    } else {
      this.rootFormHeaderMinimalDetail = this.rootFormHeader
      this.formHeaderMinimalDetailRows = this.formHeaderRows
    }

    const columns = this.columnsWithVirtuals()
    const rootColumnGroups = this.rootGroupsWithVirtuals()

    this.groupTree = makeGroupTree(columns, rootColumnGroups, this.rootColumns, false)
    this.flatGroupUids = getFlatGroupUids(rootColumnGroups)

    //*->> DEBUGA
    //console.debug(JSON.stringify(this.columnMap));
    //console.debug(JSON.stringify(this.leafFormHeaders));

    return resp2
  }

  static makeLeafFormHeaders(rootFormHeader: IFormHeaderDto): IFormHeaderDto[] {
    const res: IFormHeaderDto[] = []

    function fn(node: IFormHeaderDto) {
      if (node.desc && !node.isGroup) res.push(node)
      if (node.childs)
        node.childs.forEach(x => {
          fn(x)
        })
    }

    fn(rootFormHeader)

    return res
  }

  private columnsWithVirtuals() {
    return  (this.report ? this.report.columns : this.form.columns).concat(this.virtualColumns)
  }

  private rootGroupsWithVirtuals() {
    return (this.report ? this.report.rootColumnGroups : this.form.rootColumnGroups).concat(this.virtualGroups)
  }

  private fillLeafFormHeaders() {
    this.leafFormHeaders = BaseDataStore.makeLeafFormHeaders(this.rootFormHeader)
  }

  static makeMaps(
    columns: IColumnDescDto[],
    rootGroups: IColumnGroupDto[],
    dicsReportColumns: IReportColumnsDto[] | IFormColumnsDto[],
    /*out params*/ cm: any,
    gm: any,
  ) {
    function processGroup(g: IColumnGroupDto) {
      if (!g) return
      gm[g.id] = g
      if (g.childs)
        g.childs.forEach(ch => {
          processGroup(ch)
        })
    }

    function processDic(cols: IColumnDescDto[], grs: IColumnGroupDto[]) {
      if (cols)
        cols.forEach(x => {
          cm[x.id] = x
        })
      if (grs)
        grs.forEach(g => {
          processGroup(g)
        })
    }

    processDic(columns, rootGroups)
    if (dicsReportColumns && dicsReportColumns.length) {
      dicsReportColumns.forEach(f => {
        processDic(f.columns, f.rootColumnGroups)
      })
    }

    //console.debug(JSON.stringify(gm));
    //console.debug(JSON.stringify(cm));
  }

  private fillMaps() {
    const cm = {}
    const gm = {}

    BaseDataStore.makeMaps(this.columnsWithVirtuals() , this.rootGroupsWithVirtuals(), this.dicsReportColumns, cm, gm)

    this.columnMap = cm
    this.groupMap = gm
  }

  public needsHeaderRestruct(oldValue: DIC_REQUEST_MODE, newValue: DIC_REQUEST_MODE): boolean {
    const drmType = (v: DIC_REQUEST_MODE) => {
      if (!v) return 0
      switch (v) {
        case 'ID_CODE':
        case 'ID_CODE_NAME':
        case 'ID_CODE_DESC_FIELDS':
        default:
          return 0
        case 'CODE':
          return 1
        case 'DRE_CODE_DESC':
          return 2
        case 'DRE_CODE_DESC_RECURSIVE':
          return 3
        case 'DRE_ALL_FIELDS':
          return 4
        case 'DRE_ALL_FIELDS_RECURSIVE':
          return 5
      }
    }

    return drmType(oldValue) !== drmType(newValue)
  }

  // rows' operations
  operationWithReload = action(async (operation: () => Promise<IResponse>): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp1 = await operation()
    if (!resp1.success) {
      this.loadingError = resp1.message
      this.isLoading = false
      return resp1
    }
    const resp2 = await this.load(this.requestOptions)
    return resp2
  })

  setReportAgreed = async (agreed: boolean) => {
    this.isLoading = true
    const resp = await DataApi.setReportAgreed(this.report.id, agreed)
    if (resp.success) this.report.agreed = agreed
    else this.loadingError = resp.message
    this.isLoading = false
    return resp
  }
}

export default BaseDataStore
