import IRootStore from '../type'

class RootStoreModel {
  readonly #root: () => IRootStore

  constructor(root: () => IRootStore) {
    this.#root = root
  }

  protected get root() : () => IRootStore {
    return this.#root
  }

  protected getRootStore(): IRootStore {
    return this.#root()
  }

}

export default RootStoreModel
