import React, { forwardRef, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router'
import track from 'src/util/analytics'
import { MscTheme } from 'src/theme/type'

const useStyles = makeStyles((theme: MscTheme) => ({
  backPage: {
    // для кейса с лишней прокруткой на страницах
    height: '100%',
    paddingTop: '0',
    paddingBottom: '0',
    width: '100%',
  },
}))

interface IPageProps {
  title?: string
  className?: string
  children?: React.ReactNode
}

const Page = forwardRef<HTMLDivElement, IPageProps>(
  ({ title, children, className, ...rest }, ref) => {
    const location = useLocation()
    const classes = useStyles()

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: location.pathname,
      })
    }, [location])

    useEffect(() => {
      sendPageViewEvent()
    }, [sendPageViewEvent])

    return (
      <HelmetProvider>
        <div
          ref={ref}
          {...rest}
          className={className || classes.backPage}
          style={{ overflow: 'scroll' }}
        >
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
          </Helmet>
          {children}
        </div>
      </HelmetProvider>
    )
  },
)

export default Page
