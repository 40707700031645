import { action, observable } from "mobx"   // eslint-disable-line
import { FeatureCollection, Feature } from 'geojson'

import { UsingLayer } from 'src/store/map-store/types'

export class Geojson {
	@observable data: FeatureCollection
	readonly id: string
	styles: UsingLayer[]

	constructor(id: string, styles: UsingLayer[]) {
	  this.id = id
	  this.styles = styles
	  this.data = { features: [], type: 'FeatureCollection' }
	}

	@action setFeatures(features: Feature[]) {
	  this.data = { ...this.data, features }
	}
}