import {makeStyles} from '@material-ui/core'
import {MscTheme} from '../theme/type'

export const useCommonStyles = makeStyles((theme: MscTheme) => ({
  iconButton: {
    cursor: 'pointer',
    padding: 2,
    marginLeft: 8,
    marginRight: 8,
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  cellActions: {
    whiteSpace: 'nowrap',
    padding: 1,
  },

  compactCell: {
    padding: 2,
    margin: 0,
  },

  compactTab: {
    fontSize: '12px',
    margin: '0!important',
    padding: '0 6px 0 6px',
    minWidth: '40px',
  },

  actionIcon: {
    marginRight: theme.spacing(1),
  },

  action: {
    marginBottom: theme.spacing(0),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },

  paper: {
    margin: theme.spacing(1),
  },

  backP0: {
    //backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  backP1: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  backP3: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  backP_T0B3: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },

  error: {
    color: 'red',
    fontSize: '12px',
  },

  chip: {
    margin: theme.spacing(1),
  },
  chip_compact: {
    margin: 3,
    fontSize: '10pt',
  },

  width250: {
    width: 250,
  },

  headerSettingsPopover: {
    maxWidth: '1000px',
    padding: '15px',
  },
}))