import {
  AnyLayer,
  CircleLayer,
  LineLayer,
  FillLayer,
  SymbolLayer,
  RasterLayer,
} from 'react-map-gl/maplibre'
import { FilterSpecification } from 'maplibre-gl'
import { Feature, FeatureCollection, Geometry, MultiPoint, Point } from 'geojson'

import { API_BASE_URL } from 'src/config'
import IColumnDescDto from 'src/dto/columndesc/IColumnDescDto'
import IDataRowEditDto from 'src/dto/data/IDataRowEditDto'
import IGisLayerRequestDto from 'src/dto/gismap/IGisLayerRequestDto'
import IGismapColumnDescDto, { flatten, leafGC } from 'src/dto/gismap/IGismapColumnDescDto'
import IReportBaseDataRequestDto from 'src/dto/report/IReportBaseDataRequestDto'
import IReportDto from 'src/dto/report/IReportDto'
import { objToBase64RequestString } from 'src/util/str-util'
import { makeDataSourceName } from '../../views/components/MapLibre/map'
import IReportGisInfo from '../../dto/gismap/IReportGisInfo'
import { getContrastColor } from './getContrastColor'
import { VectorSource } from 'src/store/map-store/types'

export class VectorSourceService {
  /**
   * пытается вытащить колонку с геоинформацией из отчёта;
   * @param report - отчёт, из которого необходимо получить географическиую информацию;
   * @returns - колонку из отчёта, содержащую географические данные;
   */
  getDefaultGeoColumnFromReport(reportInfo: IReportGisInfo): IGismapColumnDescDto | null {
    const postgisColumns = this.getGeoInfoFromReport(reportInfo.report)
    if (postgisColumns.length) {
      const defaultPostgisColumn =
        postgisColumns.find(({ internal }) => internal) || postgisColumns[0]
      if (defaultPostgisColumn) {
        return reportInfo.gismapColumns.find(
          gc => !gc.child && gc.columnDesc.id === defaultPostgisColumn.id,
        )
      }
    } else {
      const gc = reportInfo.gismapColumns.find(x => x.main)
      if (gc) return gc;
      return reportInfo.gismapColumns[0];
    }
    return null
  }

  /**
   * @param report - отчёт, из которого необходимо получить географическиую информацию;
   * @returns {IColumnDescDto[]} - все колонки из отчёта, содержащую географические данные;
   */
  getGeoInfoFromReport(report: IReportDto): IColumnDescDto[] {
    return report.columns.filter(({ colType }) => colType === 'POSTGIS')
  }

  /**
   * Выборка записей со значением поля фильтрации, сожержащемся в массиве
   * @param arr - массив значений поля фильтрации, записи с которыми будут отображены;
   * @param field - поле фильтрации, должно быть передано в properties объекта на карте, по умолчанию id;
   * @returns массив, подоходящий для вставки в <Layer filter={inArray(...)} />
   */
  private inArray(arr: (string | number)[], field: string = 'id'): FilterSpecification {
    return ['in', field, ...arr]
  }

  /**
   * получет тайлы на основе отчёта и текущего запроса;
   * @param gc - информация о слое, для которого формируются тайлы;
   * @param requestOptions - параметры текущего url;
   * @returns url тайлов для слоя географических данных отчёта;
   */
  getTilesUrl(gc: IGismapColumnDescDto, requestOptions: IReportBaseDataRequestDto) {
    const columnIds = flatten(gc).map(x => x.columnDesc.id)
    const fieldRequestMode = 'GEO_DESC'
    const forEdit = false
    const format = 'VECTOR'
    const includeRowInfo = false
    const includeRowInfoAsColumns = false
    const reqOpts: IGisLayerRequestDto = {
      ...requestOptions,
      columnIds,
      fieldRequestMode,
      forEdit,
      format,
      includeRowInfo,
      includeRowInfoAsColumns,
      reportId: gc.report.id,
    }
    const base64 = objToBase64RequestString(reqOpts)
    const urlEncoded = encodeURI(base64)
    return [
      `${window.location.origin}${API_BASE_URL}gismap/vectortiles/user/${gc.columnDesc.id}/{z}/{y}/{x}.pbf?requestOptions=${urlEncoded}&srid=3857`,
    ]
  }

  /**
   * пытается распарсить maplibreStyle, если не удастся попробует вернуть стили из dbConfig;
   * @param gc - информация о географических данных отчёта;
   * @returns - массив стилей для слоёв, полученных из geoColumn;
   */
  getLayersStyles(gc: IGismapColumnDescDto, defaultStyle?: any): AnyLayer[] {
    const { maplibreStyle, columnDesc } = leafGC(gc)
    const { postgisShape } = columnDesc
    try {
      if (!maplibreStyle) {
        throw new Error('Нет стилей в колонке')
      }
      const customStyles = JSON.parse(maplibreStyle) as AnyLayer[]
      return Array.isArray(customStyles) ? customStyles : [customStyles]
    } catch (e) {
      const style = defaultStyle?.[postgisShape]
      try {
        return JSON.parse(style)
      } catch (e) {
        return style || []
      }
    }
  }

  getSourceIdByReport(reportInfo: IReportGisInfo) {
    const gc = this.getDefaultGeoColumnFromReport(reportInfo)
    if (!gc) {
      throw new Error('В отчёте отсутсвует информация о географии')
    }
    return makeDataSourceName(gc)
  }

  geojsonMultiPointsToSingle(featureCollection: FeatureCollection): FeatureCollection {
    /**
     * Вытаскивет точки из мультипоинт geojson фичи
     * @param multiPointFeature - geojson фича типа multyPoint
     * @returns массив geojson фич типа point
     */
    function breakMuliPoint(multiPointFeature: Feature<MultiPoint>): Feature<Point>[] {
      const { geometry, properties } = multiPointFeature
      return geometry.coordinates.map<Feature<Point>>(coordinates => ({
        geometry: { coordinates, type: 'Point' },
        properties,
        type: 'Feature',
      }))
    }

    const { type } = featureCollection
    const multiPointFeatures = featureCollection.features.filter(
      ({ geometry }) => geometry?.type === 'MultiPoint',
    )
    const pointFeatures = multiPointFeatures.map(breakMuliPoint)
    const features = [
      ...featureCollection.features.filter(({ geometry }) => geometry?.type !== 'MultiPoint'),
    ].concat(...pointFeatures)

    return { features, type }
  }

  geojsonAsFeatureCollection(
    geojson: FeatureCollection | Feature | Geometry | any,
  ): FeatureCollection {
    const geometryTypes = [
      'GeometryCollection',
      'Point',
      'MultiPoint',
      'LineString',
      'MultiLineString',
      'Polygon',
      'MultiPolygon',
    ]

    const { type } = geojson
    if (type === 'FeatureCollection') {
      return geojson
    }

    if (type === 'Feature') {
      return {
        type: 'FeatureCollection',
        features: [geojson],
      }
    }

    if (geometryTypes.includes(type)) {
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: geojson,
          },
        ],
      }
    }

    return null
  }

  getFeatureCollectionFromReportItemDetails(
    itemDetails: IDataRowEditDto,
    report: IReportDto,
    defaultGeomColumnsIds?: number[],
  ): FeatureCollection {
    const columns = this.getGeoInfoFromReport(report)
    const defaultGeomColumn = defaultGeomColumnsIds?.length
      ? columns.filter(({ id }) => defaultGeomColumnsIds.includes(id))
      : columns

    if (itemDetails.mainGeometry) {
      try {
        const geojson = JSON.parse(itemDetails.mainGeometry)
        const featureCollection = this.geojsonAsFeatureCollection(geojson)
        if (featureCollection) {
          return featureCollection
        } else {
          throw new Error('Geojson - неизвестная геометрия')
        }
      } catch (e) {
        return { features: [], type: 'FeatureCollection' }
      }
    }

    const features = defaultGeomColumn.reduce<Feature[]>((acc, { id, postgisShape }) => {
      const geoValue = itemDetails.values?.[id]
      if (typeof geoValue === 'string') {
        try {
          const geojson = JSON.parse(geoValue)
          const featureCollection = this.geojsonAsFeatureCollection(geojson)
          if (featureCollection) {
            acc.push(...featureCollection.features)
            if (postgisShape === 'POINT') {
              this.geojsonMultiPointsToSingle(geojson)
            }
          } else {
            throw new Error('Geojson - неизвестная геометрия')
          }
        } catch (e) {
          return acc
        }
      }
      return acc
    }, [])

    return { features, type: 'FeatureCollection' }
  }

  getFillLayerOutline(
    layerProps: AnyLayer,
    source: string,
    selectedFeaturesIds: number[],
    opacity?: number,
  ): LineLayer {
    const filter = this.inArray(selectedFeaturesIds)
    const defaultColor =
      layerProps?.paint?.['fill-color'] || layerProps?.paint?.['line-color'] || '#303030'
    const lineColor = getContrastColor(defaultColor) as string
    const lineWidth = layerProps?.paint?.['fill-color'] ? 5 : 7
    const paint = { 'line-color': lineColor, 'line-width': lineWidth, 'line-opacity': opacity ?? 1 }
    const id = `decoration_outline_${layerProps.id}`
    const type = 'line'
    return { filter, id, paint, source, type, 'source-layer': 'default' }
  }

  overrideLayerOpacity(layerProps: AnyLayer, selectedIds: number[], opacity?: number): AnyLayer {
    if (layerProps?.type === 'circle') {
      const layer = layerProps as CircleLayer
      return this.overrideCircleLayerProps(layer, selectedIds, opacity)
    }
    if (layerProps?.type === 'fill') {
      const layer = layerProps as FillLayer
      return this.overrideFillLayerProps(layer, opacity)
    }
    if (layerProps?.type === 'line') {
      const layer = layerProps as LineLayer
      return this.overrideLineLayerProps(layer, selectedIds, opacity)
    }
    if (layerProps?.type === 'raster') {
      const layer = layerProps as RasterLayer
      return this.overrideRasterLayerProps(layer, opacity)
    }
    if (layerProps?.type === 'symbol') {
      const layer = layerProps as SymbolLayer
      return this.overrideSymbolLayerProps(layer, selectedIds, opacity)
    }
  }

  overrideCircleLayerProps(
    layerProps: CircleLayer,
    selectedIds: number[],
    opacity?: number,
  ): CircleLayer {
    const defaultValue = +layerProps.paint['circle-radius']
    const value = defaultValue * 2
    const ids = selectedIds.length ? selectedIds : ['']
    const paint: CircleLayer['paint'] = {
      ...layerProps.paint,
      'circle-radius': ['match', ['get', 'id'], ids, value, defaultValue],
    }

    if (layerProps.paint?.['circle-opacity']) {
      paint['circle-opacity'] = layerProps.paint['circle-opacity']
    }

    if (opacity) {
      paint['circle-opacity'] = opacity
    }
    return { ...layerProps, paint }
  }

  overrideFillLayerProps(layerProps: FillLayer, opacity?: number): FillLayer {
    const paint: FillLayer['paint'] = { ...layerProps.paint }

    if (layerProps.paint?.['fill-opacity']) {
      paint['fill-opacity'] = layerProps.paint['fill-opacity']
    }

    if (opacity) {
      paint['fill-opacity'] = opacity
    }
    return { ...layerProps, paint }
  }

  overrideLineLayerProps(
    layerProps: LineLayer,
    selectedIds: number[],
    opacity?: number,
  ): LineLayer {
    const defaultValue = +layerProps.paint['line-width']
    const value = defaultValue * 2
    const ids = selectedIds.length ? selectedIds : ['']
    const paint: LineLayer['paint'] = {
      ...layerProps.paint,
      'line-width': ['match', ['get', 'id'], ids, value, defaultValue],
    }

    if (layerProps.paint?.['line-opacity']) {
      paint['line-opacity'] = layerProps.paint['line-opacity']
    }

    if (opacity) {
      paint['line-opacity'] = opacity
    }
    return { ...layerProps, paint }
  }

  overrideSymbolLayerProps(
    layerProps: SymbolLayer,
    selectedIds: number[],
    opacity?: number,
  ): SymbolLayer {
    const defaultValue = layerProps.paint['text-color']
    const defaultSize = +layerProps.layout['icon-size']
    const value = typeof defaultValue === 'string' ? defaultValue : '#000000'
    const sizeValue = defaultSize || 1
    const bigSizeValue = sizeValue + 1
    const ids = selectedIds.length ? selectedIds : ['']
    const layout: SymbolLayer['layout'] = {
      ...layerProps.layout,
      'icon-size': ['match', ['get', 'id'], ids, bigSizeValue, sizeValue],
    }
    const paint: SymbolLayer['paint'] = {
      ...layerProps.paint,
      'text-color': ['match', ['get', 'id'], ids, '#ff0000', value],
    }

    if (layerProps.paint?.['icon-opacity']) {
      paint['icon-opacity'] = layerProps.paint['icon-opacity']
    }

    if (layerProps.paint?.['text-opacity']) {
      paint['text-opacity'] = layerProps.paint['text-opacity']
    }

    if (opacity) {
      paint['icon-opacity'] = opacity
      paint['text-opacity'] = opacity
    }

    return { ...layerProps, layout, paint }
  }

  overrideRasterLayerProps(layerProps: RasterLayer, opacity?: number) {
    const paint: RasterLayer['paint'] = { ...layerProps.paint }

    if (layerProps.paint?.['raster-opacity']) {
      paint['raster-opacity'] = layerProps.paint['raster-opacity']
    }

    if (opacity) {
      paint['raster-opacity'] = opacity
    }
    return { ...layerProps, paint }
  }

  getVectorSourceFromGisMap(column: IGismapColumnDescDto, defaultStyle: any): VectorSource {
    function getChildColumn(column: IGismapColumnDescDto) {
      return column.child ? getChildColumn(column.child) : column
    }

    function getOpacity(column: IGismapColumnDescDto, styles: AnyLayer[]) {
      if (column.columnDesc.postgisShape === 'LINE') {
        const style = styles.find(({ type }) => type === 'line') as LineLayer
        if (typeof style?.paint?.['line-opacity'] === 'number') {
          return style.paint['line-opacity']
        }
      }

      if (column.columnDesc.postgisShape === 'POINT') {
        const style = styles.find(({ type }) => type === 'circle') as CircleLayer
        if (typeof style?.paint?.['circle-opacity'] === 'number') {
          return style.paint['circle-opacity']
        }
        const symbolStyle = styles.find(({ type }) => type === 'symbol') as SymbolLayer
        if (typeof symbolStyle?.paint?.['icon-opacity'] === 'number') {
          return symbolStyle.paint['icon-opacity']
        }
      }

      if (column.columnDesc.postgisShape === 'POLYGON') {
        const style = styles.find(({ type }) => type === 'fill') as FillLayer
        if (typeof style?.paint?.['fill-opacity'] === 'number') {
          return style.paint['fill-opacity']
        }
      }

      return 1
    }

    const report = column.report
    const requestOptions = new IReportBaseDataRequestDto()
    requestOptions.formId = report.form.id
    requestOptions.reportId = report.id

    const sourceId = makeDataSourceName(column)
    const tiles = this.getTilesUrl(column, requestOptions)
    const childColumn = getChildColumn(column)
    const styles = this.getLayersStyles(childColumn, defaultStyle)
    const layers = styles.map<AnyLayer>((item, index) => {
      const id = `${sourceId}-layer-${index}`
      return { ...item, id, source: sourceId, ['source-layer']: 'default' } // eslint-disable-line
    })
    const opacity = getOpacity(column, styles)
    return {
      id: sourceId,
      layers,
      opacity,
      selectedFeaturesIds: [],
      tiles,
      type: 'vector',
      useDefaultOpacity: true,
      visible: false,
    }
  }
}
