import { bbox, center } from '@turf/turf'
import { MapMouseEvent, MapGeoJSONFeature } from 'react-map-gl/maplibre'

import { ROUTE_SOURCE_ID, ROUTE_SOURCE_STYLE } from 'src/config/routeStyles'
import { MaplibreStore } from 'src/store/map-store'
import { bboxMinTrim, calcZoomByBBox } from 'src/util/geojson-util'
import { Geojson } from 'src/views/components/MapLibre/core'
import { MapFeature, VectorSource } from 'src/store/map-store/types'
import { Raster } from 'src/views/components/MapLibre/core/Raster'
import { Ext } from 'src/views/components/MapLibre/core/Ext'

/**
 * Набор функций для работы с хранилищем карты;
 */
export class MapStoreService {
  private readonly repository: MaplibreStore

  /**
   * Слздаёт сервис для работы с хранилищем карты
   * @param maplibreStore - хранилище карты
   */
  constructor(maplibreStore: MaplibreStore) {
    this.repository = maplibreStore
  }

  /**
   * Добавляет векторный источник в хранилище карты
   * @param source - добавляемый источник
   */
  addVectorSource(source: VectorSource) {
    const sourceExists = this.repository.vectorSources.some(({ id }) => id === source.id)
    if (!sourceExists) {
      this.repository.vectorSources.push(source)
    }
  }

  addRasterSource(source: Raster) {
    const sourceExists = this.repository.rasterSources.some(({ id }) => id === source.id)
    if (!sourceExists) {
      this.repository.setRasterSources([...this.repository.rasterSources, source])
    }
  }

  addRouterSource() {
    if (!this.repository.routerSource) {
      this.repository.routerSource = new Geojson(ROUTE_SOURCE_ID, ROUTE_SOURCE_STYLE)
    }
  }

  addExtSource(source: Ext) {
    const sourceExists = this.repository.extSources.some(({ id }) => id === source.id)
    if (!sourceExists) {
      this.repository.setExtSources([...this.repository.extSources, source])
    }
  }

  flyToGeojson(geojson: any, zoomProp: number = 15) {
    try {
      const box = bboxMinTrim(bbox(geojson))
      const boxCenter = center(geojson)
      if (
        boxCenter?.geometry?.coordinates &&
        isFinite(boxCenter.geometry.coordinates[0]) &&
        isFinite(boxCenter.geometry.coordinates[1])
      ) {
        /* box[0] = box[0] + (box[2] - box[0]) / 4 // предполагаем, что левая часть карты занята формой
				box[3] = box[1] + (box[3] - box[1]) / 2 // предполагаем, что нижняя часть карты занята таблицей
				boxCenter.geometry.coordinates[0] -= (box[2] - box[0]) / 4
				boxCenter.geometry.coordinates[1] -= (box[3] - box[1]) / 2 */

        const center = boxCenter.geometry.coordinates
        const zoom = Math.min(zoomProp, calcZoomByBBox(box))
        this.repository.setFlyTo(center, zoom)
      }
    } catch (e) {
      console.log('geojson: ', geojson)
      console.error(e)
    }
  }

  getUnderMouseFeatures(event: MapMouseEvent) {
    const { x, y } = event.point
    return event.target?.queryRenderedFeatures([x, y]) || []
  }

  /**
   * 11.1. Получение слоя по id.
   * @param id - идентификатор слоя;
   * @returns слой;
   */
  getSourceById(id: string) {
    return (
      this.repository.vectorSources.find(item => item.id === id) ||
      this.repository.rasterSources.find(item => item.id === id) ||
      this.repository.extSources.find(item => item.id === id)
    )
  }

  getVectorSources() {
    return this.repository.vectorSources
  }

  getRasterSources() {
    return this.repository.rasterSources
  }

  getExtSources() {
    return this.repository.extSources
  }

  resetSelectedFeautres() {
    this.repository.vectorSources = this.repository.vectorSources.map(item => ({
      ...item,
      selectedFeaturesIds: [],
    }))
  }

  resetStore() {
    this.repository.resetState()
  }

  resetVectorSources() {
    this.repository.vectorSources = []
  }

  selectOneFeature(sourceId: string, featureId: number) {
    const source = this.repository.vectorSources.find(({ id }) => id === sourceId)
    if (!source) {
      return
    }

    if (source.selectedFeaturesIds.some(item => item === featureId)) {
      return
    }

    source.selectedFeaturesIds.push(featureId)
  }

  setFeaturePropsSourceId(feature: MapGeoJSONFeature): MapFeature {
    const { geometry, properties, type, id, bbox } = feature
    const reportId = feature.source.split('-')[feature.source.split('-').length - 1].split('.')[0]
    const sourceId = feature.source
    return { geometry, properties: { ...properties, reportId, sourceId }, type, id, bbox }
  }

  setMapSelectedFeatures(features: MapFeature[]) {
    this.repository.mapSelectedFeatures = features
  }

  setShowLeftSidebar(payload: boolean) {
    this.repository.showLeftSidebar = payload
  }

  setShowRightSidebar(payload: boolean) {
    this.repository.showRightSidebar = payload
  }

  setSourceVisible(sourceId: string, visible: boolean) {
    const source = this.repository.vectorSources.find(item => item.id === sourceId)
    if (source) {
      source.visible = visible
    }

    const sourcesR = [...this.repository.rasterSources]
    let index = sourcesR.findIndex(({ id }) => id === sourceId)
    if (index !== -1) {
      sourcesR[index].visible = visible
      this.repository.setRasterSources(sourcesR)
      return
    }
    const sourcesE = [...this.repository.extSources]
    index = sourcesE.findIndex(({ id }) => id === sourceId)
    if (index !== -1) {
      sourcesE[index].visible = visible
      this.repository.setExtSources(sourcesE)
      return
    }
  }

  /**
   * 11.2. Устанавливает прозрачность для слоя.
   * @param source - источник;
   * @param opacity = значение прозрачности;
   */
  setSourceOpacity(source: Raster | VectorSource | Ext, opacity: number) {
    const validatedOpacity = Math.max(Math.min(opacity, 1), 0)
    source.opacity = validatedOpacity
  }

  setSourceTiles(source: VectorSource, tiles: string[]) {
    source.tiles = tiles
  }

  sortVectorSourcesByIdList(idList: (string | number)[]) {
    const sortedSources = idList
      .map(item => this.repository.vectorSources.find(({ id }) => id === item))
      .filter(item => !!item)
    const unsortedSources = this.repository.vectorSources.filter(({ id }) => !idList.includes(id))
    this.repository.vectorSources = [...sortedSources, ...unsortedSources]
  }

  unselectFeature(sourceId: string, featureId: number) {}
}
