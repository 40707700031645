import {MscTheme} from '../theme/type'

export function getModalZTop(theme: MscTheme): number {
  if (!theme) return 1550
  return theme.zIndex.modal + 10
}

export function getModalZ(theme: MscTheme): number {
  if (!theme) return 1500
  return theme.zIndex.modal
}