import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import useStore from 'src/context/RootStoreContext'
import { observer } from 'mobx-react'
import { makeUserName, rolesTitle } from '../../../../dto/ancient-mser/IUserBriefDto'

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}))

function Account({ subsystem }) {
  const classes = useStyles()

  const { authStore } = useStore()
  const user = authStore.authenticatedUser

  const isAdmin = authStore.isSysAdmin
  const isUserGroupAdmin = authStore.isUserGroupAdmin

  const ref = useRef<any>()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    handleClose()
    authStore.logout(subsystem)
  }

  return (
    <>
      <div ref={ref}>
        <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen}>
          <Avatar alt={rolesTitle(user.roles)} className={classes.avatar} src={''} />
          <Hidden smDown>
            <Typography variant="h6" color="inherit" align={'center'}>
              {makeUserName(user)}
              <br />
              {user.email}
            </Typography>
          </Hidden>
        </Box>
      </div>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={() => {
            setOpen(false)
          }}
          component={RouterLink}
          to={`/${subsystem}/user/profile`}
        >
          Мой профиль
        </MenuItem>
        {(isAdmin || isUserGroupAdmin) && (
          <MenuItem component={RouterLink} to={`/${subsystem}/admin`}>
            Администрирование
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Выйти из системы</MenuItem>
      </Menu>
    </>
  )
}

export default observer(Account)
