import { action, observable, makeObservable } from 'mobx'
import LoadableStore from './common/ILoadableStore'
import { IResponse, message } from '../api/common/ServerApi'
import ISupplierFullDto from '../dto/supplier/ISupplierFullDto'
import { findInTree, findParentFor } from '../dto/common/IIdNameTreeDto'
import ISupplierCreateEditDto from '../dto/supplier/ISupplierCreateEditDto'
import SupplierApi from '../api/SupplierApi'
import ISupplierSortableNode, { makeSupplierTree } from '../dto/supplier/ISupplierSortableNode'
import IEditSupplierPermissionDto from '../dto/supplier/IEditSupplierPermissionDto'
import IEditSupplierObserversDto from '../dto/supplier/IEditSupplierObserversDto'
import IRootStore from './_root/type'

class SupplierStore extends LoadableStore {
  formId: number
  rootSupplier: ISupplierFullDto
  rootSortable: ISupplierSortableNode

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('SupplierStore.initialize')
    this.formId = initObj as number
    const resp = await this.getFormSupplierTree(this.formId)
    this.initialized = true
    return resp
  }

  getFormSupplierTree = async (id: number): Promise<IResponse> => {
    console.debug('getFormSupplierTree...')
    this.isLoading = true
    this.loadingError = null
    const res = await SupplierApi.getFormSupplierTree(id, true)
    if (res.success) {
      this.rootSupplier = res.data
    } else {
      this.rootSupplier = null
      this.loadingError = message(res)
    }
    this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    this.isLoading = false
    return res
  }

  clearFormSuppliers = async (): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.clearFormSuppliers(this.formId)
    if (resp.success) {
      if (this.rootSupplier) this.rootSupplier.childs = []
    } else {
      this.loadingError = message(resp)
    }
    this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    this.isLoading = false
    return resp
  }

  createFormSupplier = async (createDto: ISupplierCreateEditDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.createFormSupplier(createDto)
    if (resp.success) {
      if (createDto.parentId) {
        const updateNode = findInTree(this.rootSupplier, createDto.parentId)
        const newProv = resp.data
        if (!updateNode.childs) updateNode.childs = []
        updateNode.childs.push(newProv)
      } else {
        this.rootSupplier = resp.data
      }
    } else {
      this.loadingError = message(resp)
    }
    this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable) //*->>>
    this.isLoading = false
    return resp
  }

  editFormSupplier = async (editDto: ISupplierCreateEditDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.editFormSupplier(editDto)
    if (resp.success) {
      const updateNode = findInTree(this.rootSupplier, editDto.id) as ISupplierFullDto
      updateNode.userGroup = {
        id: editDto.id,
        name: editDto.name,
      }
      /*const sortableNode = findInTree(this.rootProvider, editDto.id) as IProviderSortableNode;
      sortableNode.provider.userGroup = updateNode.userGroup;*/
      this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  moveFormSupplier = async (moveDto: ISupplierCreateEditDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.moveFormSupplier(moveDto)
    if (resp.success) {
      this.rootSupplier = resp.data
    } else {
      this.rootSupplier = null
      this.loadingError = message(resp)
    }
    this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    this.isLoading = false
    return resp
  }

  deleteFormSupplier = async (id: number): Promise<IResponse> => {
    const parentNode = findParentFor(this.rootSupplier, id) as ISupplierFullDto
    const parentSortableNode = findParentFor(this.rootSortable, id) as ISupplierSortableNode

    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.deleteFormSupplier(id)
    if (resp.success) {
      if (parentNode) parentNode.childs = parentNode.childs.filter(x => x.id !== id)
      //if (parentSortableNode) parentSortableNode.children = parentSortableNode.children.filter(x => (x as IProviderSortableNode).id !== id);
      this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  copySupplierTree = async (srcFormId: number): Promise<IResponse> => {
    this.isLoading = true
    this.loadingError = null
    const resp1 = await SupplierApi.copySupplierTree(srcFormId, this.formId)
    if (!resp1.success) {
      this.isLoading = false
      this.loadingError = resp1.message
      return resp1
    }

    const resp2 = await this.getFormSupplierTree(this.formId)

    return resp2
  }

  editPermission = async (editDto: IEditSupplierPermissionDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.editSupplierPermission(editDto)
    if (resp.success) {
      const updateNode = findInTree(this.rootSupplier, editDto.supplier.id) as ISupplierFullDto
      updateNode.savedPermission = resp.data.savedPermission
      this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  editObservers = async (editDto: IEditSupplierObserversDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp = await SupplierApi.editSupplierObservers(editDto)
    if (resp.success) {
      const updateNode = findInTree(this.rootSupplier, editDto.supplierId) as ISupplierFullDto
      updateNode.observerUserGroups = resp.data.observerUserGroups
      this.rootSortable = makeSupplierTree(this.rootSupplier, this.rootSortable)
    } else {
      this.loadingError = message(resp)
    }
    this.isLoading = false
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      rootSupplier: observable,
      rootSortable: observable,
      formId: observable,
      getFormSupplierTree: action,
      clearFormSuppliers: action,
      deleteFormSupplier: action,
      copySupplierTree: action,
      editPermission: action,
      editObservers: action,
    })
  }
}

export default SupplierStore
