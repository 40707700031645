import React, { useContext, useEffect, useState } from 'react'
import { ContextMenuController } from './ContextMenuController'
import useStore from 'src/context/RootStoreContext'
import { ControllersContext } from 'src/context'
import { ContextMenuComponent } from './ContextMenuComponent'

export const ContextMenu = () => {
  const { contextMenuStore } = useStore()
  const context = useContext(ControllersContext)
  const [controller, setController] = useState<ContextMenuController | null>(null)

  useEffect(() => {
    if ( context ) {
      const controller = new ContextMenuController(contextMenuStore)
      context.addController('contextMenu', controller)
      setController(controller)
    }
	}, [context])  // eslint-disable-line

  useEffect(() => {
    document.querySelector('body').oncontextmenu = () => false
  }, [])

  if ( !controller ) {
    return null
  }

  return <ContextMenuComponent controller={controller} />
}