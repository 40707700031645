import lodash from 'lodash'
import { colors, createMuiTheme, responsiveFontSizes, Theme, ThemeOptions } from '@material-ui/core'
import typography from './typography'
import { softShadows } from './shadows'
import { MscThemeConfigType } from './type'
import { palette } from './const'

export const themeConfig: MscThemeConfigType = {
  name: 'LIGHT',
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
    MuiButton: {
      root: {
        border: 0,
        borderRadius: '50px',
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: palette.primary.light,
    },
    ...palette,
  },
  shadows: softShadows,
}

const baseConfig: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiTab: {
      wrapper: {
        color: palette.text.primary,
      },
    },
    MuiTableCell: {
      root: {
        border: 0,
        lineHeight: '0.9em',
        fontFamily: 'Roboto',
        borderBottom: 'none',
        backgroundColor: palette.background.paper,
        '&.MuiTableCell-head': { backgroundColor: palette.primary.main },
      },
      head: {
        fontSize: 14,
        fontWeight: 400,
        border: 0,
        lineHeight: '1.2em',
        paddingTop: '12px',
        paddingBottom: '12px',
        textAlign: 'center',
        color: 'white',
        borderRight: `1px solid ${palette.divider}`,
        '&:first-child': { borderRadius: '10px 0 0 0' },
        '&:last-child': {
          borderRadius: '0 10px 0 0',
          borderRight: 0,
        },
      },
      body: {
        fontSize: 12,
        borderTop: 'solid 1px #E5E7EB',
        lineHeight: '1.1em',
        color: '#000000',
        '&:first-child': { borderLeft: 'solid 1px #E5E7EB' },
        '&:last-child': { borderRight: 'solid 1px #E5E7EB' },
      },
      sizeSmall: {
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingTop: '9px',
        paddingBottom: '9px',
      },
    },
    MuiTablePagination: {
      spacer: { display: 'none' },
      toolbar: { justifyContent: 'center' },
    },
    MuiListItem: {
      root: {
        '&.text-primary': { color: '#2F80ED' },
      },
    },
  },
}

export function createTheme(): Theme {
  const theme = responsiveFontSizes(createMuiTheme(lodash.merge({}, baseConfig, themeConfig)))
  return theme
}
