import IRootStore from './type'
import AuthStore from '../AuthStore'
import FormListStore from '../FormListStore'
import FormProfileStore from '../FormProfileStore'
import AlertModalStore from '../ModalStore/AlertModalStore'
import InfoModalStore from '../ModalStore/InfoModalStore'
import TextFieldModalStore from '../ModalStore/TextFieldModalStore'
import SelectModalStore from '../ModalStore/SelectModalStore'
import ColumnDescEditorStore from '../ColumnDescEditorStore'
import SupplierStore from '../SupplierStore'
import UserGroupStore from '../UserGroupStore'
import UserSelectorStore from '../ModalStore/UserSelectorStore'
import RegisterSelectorStore from '../ModalStore/RegisterSelectorStore'
import ReportListStore from '../ReportListStore'
import ReportCreateModalStore from '../ModalStore/ReportCreateModalStore'
import OpenReportDataStore from '../data/OpenReportDataStore'
import MyReportDataStore from '../data/MyReportDataStore'
import DataEditorStore from '../data/DataEditorStore'
import SupplierSelectorStore from '../common/SupplierSelectorStore'
import TagListStore from '../TagListStore'
import FormSelectorStore from '../ModalStore/FormSelectorStore'
import ColumnSelectorStore from '../ModalStore/ColumnSelectorStore'
import UserListStore from '../UserListStore'
import UserProfileStore from '../UserProfileStore'
import UserGroupSelectorStore from '../ModalStore/UserGroupSelectorStore'
import SupplierFirstSelectorStore from '../ModalStore/SupplierFirstSelectorStore'
import MoveUpModalStore from '../ModalStore/MoveUpModalStore'
import XlsxUploadModalStore from '../ModalStore/XlsxUploadModalStore'
import FormTileOptionsStore from '../FormTileOptionsStore'
import BackgroundListStore from '../BackgroundListStore'
import { MaplibreStore } from '../map-store'
import { ContextMenuStore } from '../context-menu'

export default class RootStore implements IRootStore {
  // auth
  authStore: AuthStore

  // modals
  alertModalStore: AlertModalStore
  infoModalStore: InfoModalStore
  fileSelectModalStore: AlertModalStore
  textFieldModalStore: TextFieldModalStore
  selectModalStore: SelectModalStore
  xlsxUploadModalStore: XlsxUploadModalStore

  contextMenuStore: ContextMenuStore

  reportCreateModalStore: ReportCreateModalStore
  reportSplitModalStore: AlertModalStore
  reportSettingsModalStore: AlertModalStore
  dicsForReportListStore: ReportListStore

  // structure
  formListStore: FormListStore
  formProfileStore: FormProfileStore
  columnDescEditorStore: ColumnDescEditorStore
  supplierStore: SupplierStore
  userGroupStore: UserGroupStore
  formTileOptionsStore: FormTileOptionsStore

  // structure & data
  supplierSelectorStore: SupplierSelectorStore
  reportListStore: ReportListStore
  openReportDataStore: OpenReportDataStore
  myReportDataStore: MyReportDataStore
  dataEditorStore: DataEditorStore
  supplierFirstSelectorStore: SupplierFirstSelectorStore

  // userGroup selector
  userGroupSelectorStore: UserGroupSelectorStore
  // user selector
  userSelectorStore: UserSelectorStore
  // reference register selector
  registerSelectorStore: RegisterSelectorStore

  // form selector
  formSelectorStore: FormSelectorStore
  // column selector
  columnSelectorStore: ColumnSelectorStore

  // tags
  tagListStore: TagListStore

  // users
  userListStore: UserListStore
  userProfileStore: UserProfileStore

  // geo
  maplibreStore: MaplibreStore
  backgroundListStore: BackgroundListStore

  moveUpModalStore: MoveUpModalStore
  constructor() {
    const r = this.getRootStore

    this.authStore = new AuthStore(r)

    this.alertModalStore = new AlertModalStore(r)
    this.infoModalStore = new InfoModalStore(r)
    this.fileSelectModalStore = new AlertModalStore(r)
    this.textFieldModalStore = new TextFieldModalStore(r)
    this.selectModalStore = new SelectModalStore(r)
    this.xlsxUploadModalStore = new XlsxUploadModalStore(r)

    this.contextMenuStore = new ContextMenuStore()

    this.reportCreateModalStore = new ReportCreateModalStore(r)
    this.reportSettingsModalStore = new AlertModalStore(r)
    this.reportSplitModalStore = new AlertModalStore(r)
    this.dicsForReportListStore = new ReportListStore(r)

    this.formListStore = new FormListStore(r)
    this.formProfileStore = new FormProfileStore(r)
    this.columnDescEditorStore = new ColumnDescEditorStore(r)
    this.supplierStore = new SupplierStore(r)
    this.userGroupStore = new UserGroupStore(r)
    this.formTileOptionsStore = new FormTileOptionsStore(r)

    // structure & data
    this.supplierSelectorStore = new SupplierSelectorStore(r)
    this.reportListStore = new ReportListStore(r)
    this.openReportDataStore = new OpenReportDataStore(r)
    this.myReportDataStore = new MyReportDataStore(r)
    this.dataEditorStore = new DataEditorStore(r)
    this.supplierFirstSelectorStore = new SupplierFirstSelectorStore(r)

    // userGroupSelector
    this.userGroupSelectorStore = new UserGroupSelectorStore(r)
    // user selector
    this.userSelectorStore = new UserSelectorStore(r)
    // reference register selector
    this.registerSelectorStore = new RegisterSelectorStore(r)

    // form selector
    this.formSelectorStore = new FormSelectorStore(r)
    // column selector
    this.columnSelectorStore = new ColumnSelectorStore(r)

    // tags
    this.tagListStore = new TagListStore(r)

    // users
    this.userListStore = new UserListStore(r)
    this.userProfileStore = new UserProfileStore(r)

    this.moveUpModalStore = new MoveUpModalStore(r)

    // geo map
    this.maplibreStore = new MaplibreStore(r)
    this.backgroundListStore = new BackgroundListStore(r)
  }

  private getRootStore = (): IRootStore => this

  get getStore(): () => IRootStore {
    return this.getRootStore
  }
}
