import { action, computed, observable, runInAction, makeObservable } from 'mobx' // eslint-disable-line
import { AnyLayer } from 'react-map-gl/maplibre'
import { Position } from '@turf/turf'

import { VectorSourceService } from 'src/services'

import RootStoreModel from '../_root/RootStoreModel'
import GeoApi from '../../api/MBGeoApi'
import { Geojson, Vector } from 'src/views/components/MapLibre/core'
import { Raster } from '../../views/components/MapLibre/core/Raster'
import { DbConfig, GeoJsonSource, LayerListItem, MapFeature, VectorSource } from './types'
import IGismapColumnDescDto from '../../dto/gismap/IGismapColumnDescDto'
import { makeDataSourceName } from '../../views/components/MapLibre/map'
import IReportDataRequestDto from '../../dto/report/IReportDataRequestDto'
import { Ext } from '../../views/components/MapLibre/core/Ext'
import IRootStore from '../_root/type'

export class MaplibreStore extends RootStoreModel {
  private readonly service: VectorSourceService = new VectorSourceService()

  dbConfig: DbConfig

  vectorSources: VectorSource[] = []
  private _rasterSources: Raster[] = []
  routerSource?: Geojson

  private _extSources: Ext[] = []
  flyToCenter: Position | undefined
  flyToZoom: number | undefined
  showLeftSidebar = false
  showRightSidebar = false

  mapSelectedFeatures: MapFeature[] = []

  // embedded editor properties
  mbDrawMap: any = {}
  mbDirectionsMap: any = {}

  addMapDraw = (mapRgisUid: string, mapDraw: any) => {
    this.mbDrawMap[mapRgisUid] = mapDraw
  }

  addMapDirections = (mapRgisUid: string, mapDirections: any) => {
    this.mbDirectionsMap[mapRgisUid] = mapDirections
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable<MaplibreStore, '_rasterSources' | '_extSources'>(this, {
      dbConfig: observable,
      vectorSources: observable,
      _rasterSources: observable,
      routerSource: observable,
      _extSources: observable,
      flyToCenter: observable,
      flyToZoom: observable,
      showLeftSidebar: observable,
      showRightSidebar: observable,
      mapSelectedFeatures: observable,
      styledVectorSources: computed,
      rasterSources: computed,
      styledRasterSources: computed,
      extSources: computed,
      geojsonSources: computed,
      loadDbConfig: action,
      resetState: action,
      updateVectorSourcesWithRequestOptions: action,
    })
  }

  get styledVectorSources(): VectorSource[] {
    const ignoredLayerTypes = ['heatmap']
    const extVector = this._extSources.filter(({ type }) => type === 'vector') as any[]

    const reportVector = this.vectorSources.map(item => {
      if (!item.visible) {
        return item
      }

      const opacity = item.useDefaultOpacity ? null : item.opacity
      const source = item.id
      const baseLayers = item.layers.map<AnyLayer>((layer, index) => {
        if (!ignoredLayerTypes.includes(layer.type)) {
          const id = `${source}-layer-${index}`
          const style = { ...layer, id, source, ['source-layer']: 'default' } // eslint-disable-line
          return this.service.overrideLayerOpacity(style, item.selectedFeaturesIds, opacity)
        } else {
          return layer
        }
      })

      const outlineLayers = baseLayers
        .filter(({ type }) => type === 'fill')
        .map(layer => {
          if (!ignoredLayerTypes.includes(layer.type)) {
            return this.service.getFillLayerOutline(
              layer,
              source,
              item.selectedFeaturesIds,
              opacity,
            )
          } else {
            return layer
          }
        })

      const layers = [...baseLayers, ...outlineLayers]
      return { ...item, layers, visible: true }
    })

    return [...extVector, ...reportVector]
  }

  get rasterSources(): Raster[] {
    const extSources = this._extSources
      .filter(({ visible }) => visible)
      .filter(item => item.type === 'raster') as Raster[]

    return [...extSources, ...this._rasterSources]
  }

  get styledRasterSources(): Raster[] {
    const extSources = this._extSources
      .filter(({ visible }) => visible)
      .filter(item => item.type === 'raster') as Raster[]

    const rasterSources = [...extSources, ...this._rasterSources]

    const styledRasterSources = rasterSources.map(item => {
      const opacity = item.useDefaultOpacity ? null : item.opacity
      const source = item.id
      const layers = item.layers.map((layer, index) => {
        const id = `${source}-layer-${index}`
        const style = { ...layer, id, source }
        return this.service.overrideLayerOpacity(style, [], opacity)
      })

      return { ...item, layers }
    }) as Raster[]

    return styledRasterSources
  }

  get extSources(): Ext[] {
    return this._extSources
  }

  get geojsonSources() {
    const sources = this._extSources.filter(({ visible }) => visible)
    return sources.filter(item => item.type === 'geojson' && 'data' in item) as GeoJsonSource[]
  }

  async loadDbConfig() {
    const resp = await GeoApi.loadDbConfig()
    if (resp.success) {
      runInAction(() => {
        this.dbConfig = resp.data
      })
    }
    return resp
  }

  setRasterSources(sources: Raster[]) {
    this._rasterSources = sources
  }

  setExtSources(sources: Ext[]) {
    this._extSources = sources
  }

  setFlyTo(flyToCenter: Position, flyToZoom: number) {
    this.flyToCenter = flyToCenter
    this.flyToZoom = flyToZoom
  }

  resetState() {
    this.vectorSources = []
    this._rasterSources = []
    this._extSources = []
    this.flyToCenter = undefined
    this.flyToZoom = undefined
    this.showLeftSidebar = false
    this.mapSelectedFeatures = []
  }

  sourceIsSelected = (sourceId: string): boolean => {
    const v = this.vectorSources.find(({ id }) => id === sourceId)
    if (v) return v.visible
    const r = this.rasterSources.find(({ id }) => id === sourceId)
    if (r) return r.visible
    const e = this.extSources.find(({ id }) => id === sourceId)
    if (e) return e.visible
  }

  childSources = (items: LayerListItem[]): (Vector | Raster)[] => {
    let res = []
    res = res.concat(
      this.vectorSources.filter(({ id }) => items.some(({ sourceId }) => sourceId === id)),
    )
    res = res.concat(
      this.rasterSources.filter(({ id }) => items.some(({ sourceId }) => sourceId === id)),
    )
    res = res.concat(
      this.extSources.filter(({ id }) => items.some(({ sourceId }) => sourceId === id)),
    )
    return res
  }

  childSourcesByIds = (childIds: string[]) => {
    let res = []
    res = res.concat(this.vectorSources.filter(({ id }) => childIds.includes(id)))
    res = res.concat(this.rasterSources.filter(({ id }) => childIds.includes(id)))
    res = res.concat(this.rasterSources.filter(({ id }) => childIds.includes(id)))
    return res
  }

  updateVectorSourcesWithRequestOptions(
    gcs: IGismapColumnDescDto[],
    requestOptions: IReportDataRequestDto,
  ) {
    if (!gcs) return
    const sourceIds = gcs.map(gc => makeDataSourceName(gc))
    this.vectorSources
      .filter(vs => sourceIds.includes(vs.id))
      .forEach(vs => {
        const gc = gcs.find(gc => makeDataSourceName(gc) === vs.id)
        if (gc) {
          // vs.setTiles(vs.getService().getTilesUrl(gc, requestOptions));
        }
      })

    this.vectorSources = this.vectorSources.concat([])
  }
}
