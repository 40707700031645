import React, {FunctionComponent} from 'react'
import {Formik} from 'formik'
import clsx from 'clsx'

import {Box, Button, FormHelperText, TextField} from '@material-ui/core'
import useStore from 'src/context/RootStoreContext'

interface ILoginFormProps {
  className?: string;
  onSubmitSuccess: () => void;
}

const LoginForm: FunctionComponent<ILoginFormProps> = ({
  className,
  onSubmitSuccess,
  ...rest
}) => {
  const { authStore } = useStore()
  return (
    <Formik<{ email: string; password: string; submit?: string }>
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const result = await authStore.loginBasic(values.email, values.password)
        console.debug(result)
        if (result.success) {
          setErrors({})
          onSubmitSuccess()
        } else {
          let mes
          if (result.details && result.details.password)
            mes = 'Неверный логин или пароль'
          else if (result.message) mes = result.message
          else mes = 'Не удалось войти в аккаунт'
          setStatus({ success: false })
          setErrors({ submit: mes })
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            required={true}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Пароль"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            required={true}
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Войти
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default React.memo(LoginForm)
