import IPssRequestDto from '../request/IPssRequestDto'

export default class ITagListRequestDto extends IPssRequestDto {
  used: boolean

  constructor() {
    super()
    this.pageSize = 25
    this.sort = [
      { columnName: 'name', asc: true },
    ]
  }
}

