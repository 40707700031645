import ServerApi, {IResponse} from './common/ServerApi'
import IPageDto from '../dto/common/IPageDto'
import IFormFullDto from '../dto/form/IFormFullDto'
import IReportRequestDto from '../dto/report/IReportRequestDto'
import IReportDto from '../dto/report/IReportDto'
import {SUPPLIER_FILTER} from '../enums/SUPPLIER_FILTER'
import {STAGE_STATUS} from '../enums/STAGE_STATUS'
import IReportDataRequestDto from '../dto/report/IReportDataRequestDto'
import IDataRowReadFullDto from '../dto/data/IDataRowReadFullDto'
import IDataRowReadBriefDto from '../dto/data/IDataRowReadBriefDto'
import IDataUploadDto from '../dto/data/IDataUploadDto'
import IUpdateResultDto from '../dto/data/IUpdateResultDto'
import IDataDeleteDto from '../dto/data/IDataDeleteDto'
import IDataMoveDto from '../dto/data/IDataMoveDto'
import IMoveResultDto from '../dto/data/IMoveResultDto'
import IReportDicItemsRequestDto from '../dto/report/IReportDicItemsRequestDto'
import IDicItemDto from '../dto/data/IDicItemDto'
import IDataApproveDto from '../dto/data/IDataApproveDto'
import IApproveResultDto from '../dto/data/IApproveResultDto'
import ICopyReportDto from '../dto/report/ICopyReportDto'
import {DIC_REQUEST_MODE} from '../enums/DIC_REQUEST_MODE'
import IDataSetSupplierFirstDto from '../dto/data/IDataSetSupplierFirstDto'
import ISetSupplierFirstRequestDto from '../dto/report/ISetSupplierFirstRequestDto'
import ICreateReportDto from '../dto/report/ICreateReportDto'
import IReportBriefWithFormDto from '../dto/report/IReportBriefWithFormDto'
import IReportBriefDto from '../dto/report/IReportBriefDto'
import IMultiReportUgDto from "../dto/data/IMultiReportUgDto";
import IMultiReportSetSupplierFirstDto from "../dto/data/IMultiReportSetSupplierFirstDto";
import IMultiReportSetAgreedDto from "../dto/data/IMultiReportSetAgreedDto";
import IMultiReportSetAgreedResultDto from "../dto/data/IMultiReportSetAgreedResultDto";

export default class DataApi {

  static async getReports(requestDto: IReportRequestDto): Promise<IResponse<IPageDto<IReportDto>>> {
    return ServerApi.postQuery('data/getReports', requestDto)
  }

  static async getReport(reportId: number): Promise<IResponse<IReportDto>> {
    return ServerApi.postQuery('data/getReport', {id: reportId})
  }

  static async getReportReferers(reportId: number): Promise<IResponse<IReportDto>> {
    return ServerApi.postQuery('data/getReportReferers', {id: reportId})
  }

  static async getArchiveReportBriefWithForm(reportId: number): Promise<IResponse<IReportBriefWithFormDto>> {
    return ServerApi.postQuery('data/getArchiveReportBriefWithForm', {id: reportId})
  }

  static async createReport(createDto: ICreateReportDto): Promise<IResponse<IReportDto>> {
    return ServerApi.postQuery('data/createReport', createDto)
  }

  static async deleteReport(id: number): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('data/deleteReport', { id: id })
  }

  static async deleteReports(ids: number[]): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('data/deleteReports', { ids: ids })
  }
  static async copyReport(copyDto: ICopyReportDto): Promise<IResponse<IReportDto>> {
    return ServerApi.postQuery('data/copyReport', copyDto)
  }

  static async getReportDataXlsx(reportId: number,
    fileType: string,
    userGroupId: number,
    supplierFilter: SUPPLIER_FILTER,
    includeAscendants: boolean,
    stageStatus: STAGE_STATUS,
    dicRequestMode: DIC_REQUEST_MODE,
    subSupplierId: number,
    saveName?: string,
    includeRowInfo?: boolean,
    includeReferrerFields?: boolean,
  ) {
    return ServerApi.downloadFileQuery('data/getReportDataXlsx',
      saveName || `report_${reportId}.xlsx`,
      {
        reportId: reportId,
        fileExt: fileType,
        userGroupId: userGroupId,
        supplierFilter: supplierFilter,
        includeAscendants: includeAscendants,
        stageStatus: stageStatus,
        dicRequestMode: dicRequestMode,
        subSupplierId: subSupplierId,
        includeRowInfo: includeRowInfo,
        includeReferrerFields: includeReferrerFields,
      },
    )
  }

  static async getFormDataXlsx(dto: IReportDataRequestDto, saveName?: string) {
    return ServerApi.downloadFileQuery('data/getFormDataXlsx',
      saveName || `form_${dto.formId}_total.xlsx`,
      null,
      dto,
      'POST',
    )
  }
    
  static async getReportOpenDataXlsx(reportId: number, saveName?: string) {
    console.debug('getReportOpenDataXlsx')
    return ServerApi.downloadFileQuery('data/getReportOpenDataXlsx',
      saveName || `report_open_${reportId}.xlsx`,
      {
        reportId: reportId,
      },
    )
  }

  static async addUpdateDataXlsx(reportId: number, file: File, userGroupId: number, useDataFormat: boolean) {
    console.debug('addUpdateDataXlsx')

    const fd = new FormData()
    fd.append('xlsxData', file)
    fd.append('reportId', reportId + '')
    fd.append('userGroupId', userGroupId + '')
    fd.append('useDataFormat', useDataFormat ? 'true' : 'false')

    //*-> TODO: непрактичный функционал, убрать после сдачи
    //fd.append('validateCellFormat', 'true');


    return ServerApi.postQuery('data/addUpdateDataXlsx', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async replaceDataXlsx(reportId: number, file: File, userGroupId: number, useDataFormat: boolean) {
    console.debug('replaceDataXlsx')

    const fd = new FormData()
    fd.append('xlsxData', file)
    fd.append('reportId', reportId + '')
    fd.append('userGroupId', userGroupId + '')
    fd.append('useDataFormat', useDataFormat ? 'true' : 'false')

    //*-> TODO: непрактичный функционал, убрать после сдачи
    //fd.append('validateCellFormat', 'true');

    return ServerApi.postQuery('data/replaceDataXlsx', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async getReportDataWeb(requestDTO: IReportDataRequestDto): Promise<IResponse<IPageDto<IDataRowReadFullDto>>> {
    console.debug('getReportDataWeb')
    return ServerApi.postQuery('data/getReportDataWeb', requestDTO)
  }

  static async getReportOpenDataWeb(requestDTO: IReportDataRequestDto): Promise<IResponse<IPageDto<IDataRowReadBriefDto>>> {
    console.debug('getReportOpenDataWeb')
    return ServerApi.postQuery('data/getReportOpenDataWeb', requestDTO)
  }

  static async addUpdateDataWeb(requestDTO: IDataUploadDto): Promise<IResponse<IUpdateResultDto>> {
    console.debug('addUpdateDataWeb')
    return ServerApi.postQuery('data/addUpdateDataWeb', requestDTO)
  }

  static async getDicItems(requestDTO: IReportDicItemsRequestDto): Promise<IResponse<IPageDto<IDicItemDto>>> {
    console.debug('getDicItems')
    return ServerApi.postQuery('data/getDicItems', requestDTO)
  }

  static async deleteReportData(requestDTO: IDataDeleteDto): Promise<IResponse<IUpdateResultDto>> {
    console.debug('deleteReportData')
    return ServerApi.postQuery('data/deleteReportData', requestDTO)
  }

  static async moveUpReportData(requestDTO: IDataMoveDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveUpReportData')
    return ServerApi.postQuery('data/moveUpReportData', requestDTO)
  }

  static async moveDownReportData(requestDTO: IDataMoveDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveDownReportData')
    return ServerApi.postQuery('data/moveDownReportData', requestDTO)
  }

  static async moveToFirstReportData(requestDTO: IDataMoveDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveToFirstReportData')
    return ServerApi.postQuery('data/moveToFirstReportData', requestDTO)
  }

  static async approveReportData(requestDTO: IDataApproveDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('approveReportData')
    return ServerApi.postQuery('data/approveReportData', requestDTO)
  }

  static async disapproveReportData(requestDTO: IDataApproveDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('disapproveReportData')
    return ServerApi.postQuery('data/disapproveReportData', requestDTO)
  }

  static async setSupplierFirst(requestDTO: IDataSetSupplierFirstDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('setSupplierFirst')
    return ServerApi.postQuery('data/setReportDataSupplierFirst', requestDTO)
  }

  static async deleteReportDataAll(requestDTO: IReportDataRequestDto): Promise<IResponse<IUpdateResultDto>> {
    console.debug('deleteReportData')
    return ServerApi.postQuery('data/deleteReportDataAll', requestDTO)
  }

  static async moveUpReportDataAll(requestDTO: IReportDataRequestDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveUpReportDataAll')
    return ServerApi.postQuery('data/moveUpReportDataAll', requestDTO)
  }

  static async moveDownReportDataAll(requestDTO: IReportDataRequestDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveDownReportDataAll')
    return ServerApi.postQuery('data/moveDownReportDataAll', requestDTO)
  }

  static async moveToFirstReportDataAll(requestDTO: IReportDataRequestDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveToFirstReportDataAll')
    return ServerApi.postQuery('data/moveToFirstReportDataAll', requestDTO)
  }

  static async approveReportDataAll(requestDTO: IReportDataRequestDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('approveReportDataAll')
    return ServerApi.postQuery('data/approveReportDataAll', requestDTO)
  }

  static async disapproveReportDataAll(requestDTO: IReportDataRequestDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('disapproveReportDataAll')
    return ServerApi.postQuery('data/disapproveReportDataAll', requestDTO)
  }

  static async setSupplierFirstAll(requestDTO: ISetSupplierFirstRequestDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('setSupplierFirstAll')
    return ServerApi.postQuery('data/setReportDataSupplierFirstAll', requestDTO)
  }

  static async setReportAgreed(reportId: number, agreed: boolean): Promise<IResponse<IReportBriefDto>> {
    console.debug('setReportAgreed')
    return ServerApi.postQuery('data/setReportAgreed', {
      id: reportId,
      b: agreed,
    })
  }

  static async setReportInactual(reportId: number, inactual: boolean): Promise<IResponse> {
    console.debug('setReportInactual')
    return ServerApi.postQuery('data/setReportInactual', {
      id: reportId,
      b: inactual,
    })
  }

  static async setReportDescription(reportId: number, description: string): Promise<IResponse> {
    console.debug('setReportDescription')
    return ServerApi.postQuery('data/setReportDescription', {
      id: reportId,
      name: description,
    })
  }

  static async setReportDates(reportId: number, periodStart: Date, periodEnd: Date, periodPublish: Date): Promise<IResponse<IReportBriefDto>> {
    console.debug('setReportDates')
    return ServerApi.postQuery('data/setReportDates', {
      id: reportId,
      periodStart: periodStart,
      periodEnd: periodEnd,
      periodPublish: periodPublish,
    })
  }

  static async makeReportsFromDate(reportId: number, columnId: number, stageStatus: STAGE_STATUS,
    keepSupplierFirsts: boolean, approveReports: boolean): Promise<IResponse> {
    console.debug('makeReportsFromDate')
    return ServerApi.postQuery('data/makeReportsFromDate', {
      reportId: reportId,
      columnId: columnId,
      stageStatus: stageStatus,
      keepSupplierFirsts: keepSupplierFirsts,
      approveReports: approveReports,
    })
  }

  // multi report
  static async moveToRootMultiReportData(requestDTO: IMultiReportUgDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveToRootMultiReportData')
    return ServerApi.postQuery('data/moveToRootMultiReportData', requestDTO)
  }

  static async moveToFirstMultiReportData(requestDTO: IMultiReportUgDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('moveToFirstMultiReportData')
    return ServerApi.postQuery('data/moveToFirstMultiReportData', requestDTO)
  }

  static async approveMultiReportData(requestDTO: IMultiReportUgDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('approveMultiReportData')
    return ServerApi.postQuery('data/approveMultiReportData', requestDTO)
  }

  static async disapproveMultiReportData(requestDTO: IMultiReportUgDto): Promise<IResponse<IApproveResultDto>> {
    console.debug('disapproveMultiReportData')
    return ServerApi.postQuery('data/disapproveMultiReportData', requestDTO)
  }

  static async setMultiReportDataSupplierFirst(requestDTO: IMultiReportSetSupplierFirstDto): Promise<IResponse<IMoveResultDto>> {
    console.debug('setMultiReportDataSupplierFirst')
    return ServerApi.postQuery('data/setMultiReportDataSupplierFirst', requestDTO)
  }

  static async setMultiReportAgreed(requestDTO: IMultiReportSetAgreedDto): Promise<IResponse<IMultiReportSetAgreedResultDto>> {
    console.debug('setReportAgreed')
    return ServerApi.postQuery('data/setMultiReportAgreed', requestDTO)
  }

  // /multi report
}
