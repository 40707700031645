import { action, observable, makeObservable } from 'mobx'
import LoadableStore from './common/ILoadableStore'
import { IResponse, message } from '../api/common/ServerApi'
import IUserFullDto from '../dto/ancient-mser/IUserFullDto'
import UserApi from '../api/UserApi'
import ICreateUserDto from '../dto/ancient-mser/ICreateUserDto'
import IChangePasswordDto from '../dto/ancient-mser/IChangePasswordDto'
import IEditProfileDto from '../dto/ancient-mser/IEditProfileDto'
import FormListStore from './FormListStore'
import IRootStore from './_root/type'

class UserProfileStore extends LoadableStore {
  user: IUserFullDto
  isSaving: boolean

  adminFormListStore: FormListStore
  userFormListStore: FormListStore

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('userProfileStore.initialize')
    if (!this.adminFormListStore) this.adminFormListStore = new FormListStore(this.root)
    if (!this.userFormListStore) this.userFormListStore = new FormListStore(this.root)
    const id = initObj as number
    let res
    if (id) {
      res = await this.loadUser(id)
    } else {
      this.user = null
      res = { success: true }
    }
    this.initialized = true
    return res
  }

  loadUser = async (id: number): Promise<IResponse> => {
    this.isLoading = true
    this.loadingError = null
    const res = await UserApi.getUser(id)
    console.debug('ccc')
    if (res.success) {
      this.user = res.data
    } else {
      this.user = null
      this.loadingError = message(res)
    }

    this.isLoading = false
    return res
  }

  saveUser = async (saveDto: ICreateUserDto): Promise<IResponse> => {
    this.loadingError = null
    this.isSaving = true
    const resp = await UserApi.saveUser(saveDto)
    if (resp.success) {
      this.user = resp.data
    } else {
      this.loadingError = message(resp)
    }
    this.isSaving = false
    return resp
  }

  editProfile = async (dto: IEditProfileDto): Promise<IResponse> => {
    this.loadingError = null
    this.isSaving = true
    const resp = await UserApi.editProfile(dto)
    if (resp.success) {
      this.user = resp.data
    } else {
      this.loadingError = message(resp)
    }
    this.isSaving = false
    return resp
  }

  changePassword = async (dto: IChangePasswordDto): Promise<IResponse> => {
    this.loadingError = null
    this.isSaving = true
    const resp = await UserApi.changePassword(dto)
    if (resp.success) {
      this.user = resp.data
    } else {
      this.loadingError = message(resp)
    }
    this.isSaving = false
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      isSaving: observable,
      user: observable,
      loadUser: action,
      saveUser: action,
    })
  }
}

export default UserProfileStore
