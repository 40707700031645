import {API_BASE_URL} from '../../config'
import axios from '../../util/axios'
import {IResponse} from './ServerApi'

const BASE_URL = `${API_BASE_URL}`
export default class FrontApi {

  static async getHtml(
    uri: string,
  ): Promise<IResponse<string>> {
    const result: IResponse<string> = {
      success: false,
      status: 0,
    }

    try {
      const resp = await axios.get<string>(`${BASE_URL}${uri}`, {})
      result.success = true
      result.status = resp.status
      result.data = resp.data
      result.contentType = resp.headers['content-type']
      return result
    } catch (error) {
      result.success = false
      result.message = error.message
      result.status = error.status
    }
  }

}
