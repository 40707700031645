import Axios, { AxiosResponse } from 'axios'
import { GeocoderFeatureCollection, GeocoderItem } from 'src/store/map-store/types'

export class GeocoderApi {
  static readonly baseURL = 'https://newdata.spellsystems.com/geocoder'

  static async search(addressString: string, baseUrl: string) {
    const params = { q: addressString }

    const route = baseUrl + '/search'
    return await Axios.get<GeocoderItem[], AxiosResponse<GeocoderItem[]>>(route, { params }).then(
      ({ data }) => data,
    )
  }

  static async searchWithGeo(addressString: string, baseUrl: string) {
    const params = { q: addressString, format: 'geojson', polygon_geojson: 1 }

    const route = baseUrl + '/search'
    return await Axios.get<GeocoderFeatureCollection, AxiosResponse<GeocoderFeatureCollection>>(
      route,
      { params },
    ).then(({ data }) => data)
  }
}
