import React, {FunctionComponent, useCallback, useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import {observer} from 'mobx-react'
import useStore from '../../context/RootStoreContext'
import {getModalZTop} from '../../util/modal-z-levels'
import {useTheme} from '@material-ui/styles'
import {MscTheme} from '../../theme/type'

const XlsxUploadDialog: FunctionComponent<{}> = ({children, ...rest}) => {
  const theme = useTheme() as MscTheme
  const store = useStore().xlsxUploadModalStore
  const { file, open, title, body, handleConfirm, handleCancel, useDataFormat } = store

  const handleChangeFile = useCallback((ev) => {
    store.file = ev.target.files[0]
  }, [])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const defaultHandleConfirm = async () => {
    setIsSubmitting(true)
    if (handleConfirm) await handleConfirm(file, useDataFormat)
    store.closeModal()
    setIsSubmitting(false)
  }

  const defaultHandleCancel = (event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      if (handleCancel) handleCancel()
      store.closeModal()
    }
  }

  const handleUseDataFormatChange = (ev) => {
    store.useDataFormat = ev.target.value === 'true'
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={open}
      style={{zIndex: getModalZTop(theme)}}
      onClose={defaultHandleCancel}
      aria-labelledby="file-select-dialog-title"
      aria-describedby="file-select-dialog-description"
    >
      <DialogTitle id="file-select-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {body}
          </Grid>
          <Grid item xs={12}>
            <input type={'file'} onChange={handleChangeFile}/>
          </Grid>
          <Grid item xs={12}>
            <TextField size={'small'}
              label={'Форматирование вещественных чисел как в файле'}
              onChange={handleUseDataFormatChange}
              value={useDataFormat ? 'true' : 'false'}
              fullWidth={true}
              name="sort"
              InputLabelProps={{shrink: true}}
              select
              SelectProps={{ native: true }}
              variant="outlined"

              {...rest}
            >
              <option key={'false'} value={'false'}>Нет</option>
              <option key={'true'} value={'true'}>Да</option>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {defaultHandleCancel(null, null)}} color="secondary">
              Отмена
        </Button>
        <Button variant={'contained'} onClick={defaultHandleConfirm} color="primary" autoFocus disabled={isSubmitting || !file}>
              OK {isSubmitting && <CircularProgress size={16}/>}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(XlsxUploadDialog)