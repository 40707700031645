import Axios, { AxiosResponse } from 'axios'

import { FindLayersByGeometryPayload } from 'src/dto/gismap/FindLayersByGeometryPayload'
import { WhatsHereCadastrResponse } from 'src/dto/gismap/WhatsHereCadastrResponse'
import { GeocadasrtResponse, GeocoderFeatureCollection } from 'src/store/map-store/types'

type Params = {
	cadastralNumber: string
}

export class GeoCadastrApi {
  static readonly baseURL = '/cadastr'

  static async getObjectDetails(params: Params) {
    const route = this.baseURL + '/object-details'
    return await Axios.get<GeocadasrtResponse, AxiosResponse<GeocadasrtResponse>>(route, { params }).then(({ data }) => data)
  }

  static async findObjectByGeometry<Payload extends FindLayersByGeometryPayload>(data: Payload) {
    const route = this.baseURL + '/findObjectByGeometry'
    return await Axios
      .post<GeocoderFeatureCollection, AxiosResponse<WhatsHereCadastrResponse>>(route, data)
      .then(({ data }) => data)
  }
}
