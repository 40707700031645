import React, { FunctionComponent, lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import LoadingScreen from './views/components/LoadingScreen'

import MainLayout from './views/layouts/MainLayout'
import PrivateRoute from './views/components/route/PrivateRoute'
import AdminRoute from './views/components/route/AdminRoute'
import GuestRoute from './views/components/route/GuestRoute'
import AuthLayout from './views/layouts/AuthLayout'
import LoginView from './views/auth/LoginView'
import Error403View from './views/error-pages/Error403View'
import { DEFAULT_SUBSYSTEM } from './util/constants'

const Error404View = lazy(() => import('src/views/error-pages/Error404View'))

const Routes: FunctionComponent = () => {
  //const authenticatedUser = useStore().authStore.authenticatedUser;
  const noCentralAuth = !(process.env.REACT_APP_NO_CENTRAL_AUTH === 'false')
  console.debug('NO_CENTRAL_AUTH: ' + process.env.REACT_APP_NO_CENTRAL_AUTH)

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to={`/${DEFAULT_SUBSYSTEM}/user`} />
        <Redirect exact from="/data" to="/data/user" />
        <Redirect exact from="/shud" to="/shud/user" />
        <Redirect exact from="/nsi" to="/nsi/user" />
        <Route exact path="/404" component={Error404View} />
        <Route exact path="/403" component={Error403View} />

        <GuestRoute
          exact
          path={
            noCentralAuth
              ? ['/:subsystem/signinLocal', '/:subsystem/signin']
              : '/:subsystem/signinLocal'
          }
          component={props => (
            <AuthLayout {...props}>
              <LoginView {...props} />
              {/* <Suspense fallback={<LoadingScreen />}>
                            {lazy(() => import('src/views/auth/LoginView'))}
                        </Suspense> */}
            </AuthLayout>
          )}
        />

        {!noCentralAuth && (
          <GuestRoute
            exact
            path={['/:subsystem/signin', '/:subsystem/signin/:err']}
            component={props => (
              <AuthLayout {...props}>
                <LoginView {...props} />
                {/* <LoginCentralView {...props}/>  */
                /* LoginCentralView - устаревший компонент, noCentralAuth - возможно тоже */}
                {/* <Suspense fallback={<LoadingScreen />}>
                                {lazy(() => import('src/views/auth/LoginView'))}
                            </Suspense> */}
              </AuthLayout>
            )}
          />
        )}

        <PrivateRoute
          path="/:subsystem/user"
          component={props => (
            <MainLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route
                    exact={true}
                    path={'/data/user/externallayer/list'}
                    component={lazy(
                      () => import('src/views/pages/ExternalLayer/ExternalLayerList'),
                    )}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/externallayer/profile/:id'}
                    component={lazy(
                      () => import('src/views/pages/ExternalLayer/ExternalLayerProfile'),
                    )}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/geotiff/list'}
                    component={lazy(() => import('src/views/pages/GeoTiff/GeoTiffList'))}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/geotiff/profile/:id'}
                    component={lazy(() => import('src/views/pages/GeoTiff/GeoTiffProfile'))}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/geotiff/view/:id'}
                    component={lazy(() => import('src/views/pages/Map/MapView'))}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/maps/list'}
                    component={lazy(() => import('src/views/pages/Map/MapList'))}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/maps/profile/:id'}
                    component={lazy(() => import('src/views/pages/Map/MapProfile'))}
                  />

                  <Route
                    exact={true}
                    path={'/data/user/maps/view/:id'}
                    component={lazy(() => import('src/views/pages/Map/MapView'))}
                  />

                  <Route
                    exact
                    path={['/:subsystem/user', '/:subsystem/user/forms']}
                    component={lazy(() => import('src/views/pages/MyForm/FormList'))}
                  />
                  <Route
                    exact
                    path={[
                      '/:subsystem/user/forms/report/:id/edit/',
                      '/:subsystem/user/forms/report/:id/edit/:options/',
                      '/:subsystem/user/forms/form/:formId/:options/',
                    ]}
                    component={lazy(() => import('src/views/pages/MyForm/ReportData'))}
                  />

                  <Route
                    exact
                    path={['/:subsystem/user/openRegisters', '/:subsystem/user/openNSI']}
                    component={lazy(() => import('src/views/pages/OpenFormList/FormList'))}
                  />

                  <Route
                      exact
                      path="/:subsystem/user/forms/:id/reports"
                      component={lazy(() => import('src/views/pages/MyForm/ReportList'))}
                  />

                    <Route
                    exact
                    path={[
                      '/:subsystem/user/openIndicatorReports/form/:id/openReports',
                      '/:subsystem/user/openRegisters/form/:id/openReports',
                      '/:subsystem/user/openGeoRegisters/form/:id/openReports',
                      '/:subsystem/user/openNSI/form/:id/openReports',
                      '/:subsystem/user/openCNSI/form/:id/openReports',
                    ]}
                    component={lazy(
                      () => import('src/views/pages/OpenFormList/ReadOnlyReportList'),
                    )}
                  />

                  <Route
                    exact
                    path={[
                      '/:subsystem/user/openIndicatorReports/:id',
                      '/:subsystem/user/openRegisters/:id',
                      '/:subsystem/user/openGeoRegisters/:id',
                      '/:subsystem/user/openNSI/:id',
                      '/:subsystem/user/openCNSI/:id',
                    ]}
                    component={lazy(() => import('src/views/pages/OpenFormList/DataView'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/user/profile"
                    component={lazy(() => import('src/views/pages/UserProfile'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/user/adminform"
                    component={lazy(() => import('src/views/pages/AdminForm/AdminFormList'))}
                  />
                  <Route
                    exact
                    path="/:subsystem/user/adminform/profile/:id"
                    component={lazy(() => import('src/views/pages/AdminForm/AdminFormProfile'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/user/userGroups"
                    component={lazy(() => import('src/views/pages/AdminUserGroup'))}
                  />

                  <Redirect from="*" to="/404" />
                </Switch>
              </Suspense>
            </MainLayout>
          )}
        />

        <AdminRoute
          path="/:subsystem/admin"
          component={props => (
            <MainLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route
                    exact
                    path="/:subsystem/admin"
                    component={lazy(() => import('src/views/pages/AdminSettingsLanding'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/admin/setting"
                    component={lazy(() => import('src/views/pages/SystemOption/SystemOptionList'))}
                  />
                  <Route
                    exact
                    path="/:subsystem/admin/users/loginActions"
                    component={lazy(() => import('src/views/pages/AdminUser/UserLoginList'))}
                  />
                  <Route
                    exact
                    path="/:subsystem/admin/users/userProfiles"
                    component={lazy(() => import('src/views/pages/AdminUser/AdminUserList'))}
                  />
                  <Route
                    exact
                    path="/:subsystem/admin/users/user/:id"
                    component={lazy(() => import('src/views/pages/AdminUser/AdminUserProfile'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/admin/userGroups"
                    component={lazy(() => import('src/views/pages/AdminUserGroup'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/admin/tags"
                    component={lazy(() => import('src/views/pages/AdminTagList'))}
                  />

                  <Route
                    exact
                    path={[
                      '/:subsystem/admin/mapsetting',
                      '/:subsystem/admin/mapsetting/backgrounds',
                    ]}
                    component={lazy(
                      () => import('src/views/pages/Background/BackgroundList/index'),
                    )}
                  />

                  <Route
                    exact
                    path={'/:subsystem/admin/mapsetting/proxyItems'}
                    component={lazy(() => import('src/views/pages/ProxyItem/ProxyItemList/index'))}
                  />

                  <Route
                    exact
                    path={'/:subsystem/admin/mapsetting/sprite'}
                    component={lazy(() => import('src/views/pages/Sprite/index'))}
                  />

                  <Route
                    exact
                    path="/:subsystem/admin/formRelations"
                    component={lazy(
                      () => import('src/views/pages/AdminFormRelations/AdminFormRelationsPage'),
                    )}
                  />

                  <Redirect from="*" to="/404" />
                </Switch>
              </Suspense>
            </MainLayout>
          )}
        />

        {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && ( // тестовая страница для компонентов
          <MainLayout>
            <Route exact path="/test" component={lazy(() => import('src/views/pages/TestMap'))} />
          </MainLayout>
        )}

        <Redirect from="*" to="/404" />
      </Switch>
    </Suspense>
  )
}

export default Routes
