import IFormBriefDto from './IFormBriefDto'
import IColumnDescDto from '../columndesc/IColumnDescDto'
import IReportDto from '../report/IReportDto'
import IColumnGroupDto from '../columngroup/IColumnGroupDto'
import IFormScheduleDto from './IFormScheduleDto'

export default interface IFormFullDto extends IFormBriefDto {
    columns: IColumnDescDto[];
    rootColumnGroups: IColumnGroupDto[];
    reports: IReportDto[];
    formSchedules?: IFormScheduleDto[];
    mainGeomPath: number[];
}

// ------------------

export function getFlatGroupUids(rootGroups: IColumnGroupDto[]): string[] {
  const res: string[] = []

  if (rootGroups) {
    rootGroups.forEach(x => {
      res.push(x.uid)
      if (x.childs) {
        const r = getFlatGroupUids(x.childs)
        r.forEach(y => res.push(y))
      }
    })
  }

  return res
}