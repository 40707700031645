import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from '../_root/RootStoreModel'
import IDicVersionDto from '../../dto/report/IDicVersionDto'
import IColumnDescDto from '../../dto/columndesc/IColumnDescDto'
import IReportBriefDto from '../../dto/report/IReportBriefDto'
import { IResponse } from '../../api/common/ServerApi'
import IPageDto from '../../dto/common/IPageDto'
import { momentDateFromRuDatePicker } from '../../util/date-util'
import IRootStore from '../_root/type'

class ReportCreateModalStore extends RootStoreModel {
  open: boolean = false
  title: string
  dateStart: Date
  dateEnd: Date
  datePublish: Date
  dateStartStr: string
  dateEndStr: string
  datePublishStr: string
  description: string
  emailNotification: boolean = true
  //dicVersions: IDicVersionDto[];
  handleConfirm?: Function
  handleCancel?: Function

  refColumns: IColumnDescDto[]

  selectedRefReports: object = {} // = observable.map(); // Map<number, IReportBriefDto>

  openModal = async (
    title: string,
    refColumns: IColumnDescDto[],
    handleConfirm?: Function,
    handleCancel?: Function,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.dateStart = null
    this.dateEnd = null
    this.datePublish = null
    this.title = title
    this.handleConfirm = handleConfirm
    this.handleCancel = handleCancel
    this.selectedRefReports = {}

    this.refColumns = refColumns
    if (refColumns && refColumns.length) {
      await this.loadRefReports(refColumns[0].id)
    }

    this.open = true
  }

  closeModal = () => {
    this.open = false

    this.title = ''
    this.dateStart = null
    this.dateEnd = null
    this.datePublish = null
    this.handleConfirm = null
    this.handleCancel = null
  }

  setDateStart = (dateStr: string) => {
    this.dateStartStr = dateStr
    this.dateStart = momentDateFromRuDatePicker(dateStr)
  }

  setDateEnd = (dateStr: string) => {
    this.dateEndStr = dateStr
    this.dateEnd = momentDateFromRuDatePicker(dateStr)
  }

  setDatePublish = (dateStr: string) => {
    this.datePublishStr = dateStr
    this.datePublish = momentDateFromRuDatePicker(dateStr)
  }

  setDescription = (value: string) => {
    this.description = value
  }

  setEmailNotification = (value: boolean) => {
    this.emailNotification = value
  }

  loadRefReports = async (columnId: number): Promise<IResponse<IPageDto<IReportBriefDto>>> => {
    const col = this.refColumns.filter(x => x.id === columnId)[0]
    const resp = await this.root().dicsForReportListStore.load({
      formId: col.refForm.id,
      pageNumber: 0,
      pageSize: 20,
      sort: [{ columnName: 'periodEnd', asc: false }],
    })
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      title: observable,
      dateStart: observable,
      dateEnd: observable,
      datePublish: observable,
      dateStartStr: observable,
      dateEndStr: observable,
      datePublishStr: observable,
      description: observable,
      emailNotification: observable,
      selectedRefReports: observable,
      setDateStart: action,
      setDateEnd: action,
      setDatePublish: action,
      setDescription: action,
      setEmailNotification: action,
      openModal: action,
      closeModal: action,
      loadRefReports: action,
      selectReport: action,
    })
  }

  getSelectedDicVersions() {
    const res: IDicVersionDto[] = []
    this.refColumns.forEach(col => {
      const rep = this.selectedRefReports[col.id]
      if (rep) {
        res.push({
          column: col,
          report: rep,
        })
      }
    })
    return res
  }

  selectReport = (columnId: number, report: IReportBriefDto) => {
    console.debug('selectReport')
    this.selectedRefReports[columnId] = report
  }
}

export default ReportCreateModalStore
