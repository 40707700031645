import ServerApi, {IResponse} from "./common/ServerApi";
import IGeoExportRequestDto from "../dto/geoimpexp/IGeoExportRequestDto";
import {EXPORT_STATUS} from "../enums/geoimpexp/EXPORT_STATUS";

export class GeoExportApi {
  static readonly baseURL = 'geoexport'

  static async start(requestDto: IGeoExportRequestDto): Promise<IResponse<string>> {
    return ServerApi.postQuery(`${this.baseURL}/start`, requestDto)
  }

  static async getStatus(guid: string): Promise<IResponse<{exportStatus: EXPORT_STATUS, message: string}>> {
    return ServerApi.postQuery(`${this.baseURL}/getStatus`, {guid})
  }

  static async download(guid: string) {
    return ServerApi.downloadFileQuery(`${this.baseURL}/download`,
        null,
        {
          guid
        },
    )
  }

}
