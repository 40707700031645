import IIdNameDto from '../dto/common/IIdNameDto'
import IIdDto from '../dto/common/IIdDto'

export function copyAndSpread(srcObj: any, extentionObj: any): any {
  const x = {...srcObj}
  for (const z in extentionObj) {
    x[z] = extentionObj[z]
  }
  return Object.assign(Object.create(Object.getPrototypeOf(srcObj)), x)
}

export function getIdByName(arr: IIdNameDto[], name: string): number {
  if (!name) return null
  const item = arr.filter(x => x.name === name)
  return item && item[0] && item[0].id
}

export function getNameById(arr: IIdNameDto[], id: number): string {
  if (!id) return null
  const item = arr.filter(x => x.id === id)
  return item && item[0] && item[0].name
}

export function getIndById(arr: IIdDto[], id: number): number {
  if (!id) return -1
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === id) return i
  }
  return -1
}

export function alphaSortFn(s1: string, s2: string): number {
  if (s1 < s2) return -1
  else if (s1 > s2) return 1
  else return 0
}

export interface IFieldExtractor {
    s: string | ((obj: any) => string);
    t: string;
}

export function extractField(obj: any, fe: IFieldExtractor): string {
  switch (typeof fe.s) {
  case 'string': return obj[fe.s]
  case 'function': return fe.s(obj)
  }
}

export function numericArraysEqual(arr1: number[], arr2: number[]): boolean {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}