import React, { FC, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu, MenuItem } from '@material-ui/core'

import useStore from 'src/context/RootStoreContext'

import { ContextMenuController } from './ContextMenuController'

type Props = {
    controller: ContextMenuController
}

export const ContextMenuComponent: FC<Props> = observer(({ controller }) => {
  const { contextMenuStore } = useStore()
  const [anchorEl, setAncorEl] = useState<HTMLDivElement | null>(null)

  const anchorElCb = useCallback((div?: HTMLDivElement) => (setAncorEl(div || null)), [])

  const top = `${contextMenuStore.position.top}px`
  const left = `${contextMenuStore.position.left}px`

  function closeHandler() {
    controller.hide()
  }

  function handler(fc: () => void) {
    fc()
    closeHandler()
  }

  return (
    <div ref={anchorElCb} style={{ position: 'fixed', top, left }}>
      {anchorEl && (
        <Menu open={contextMenuStore.show} onClose={closeHandler} anchorEl={anchorEl}>
          {contextMenuStore.items.map(({ action, title }, index) => (
            <MenuItem key={`context-menu__${index}`} onClick={() => handler(action)}>
              {title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  )
})
