import { action, computed, observable } from "mobx"	// eslint-disable-line
import { AnyLayer } from 'react-map-gl/maplibre'
import { VectorSourceService } from 'src/services'

export class Vector {
  type: 'vector' = 'vector'
	@observable opacity = 1
	@observable visible = false

	readonly id: string
	private _tiles: string[]
	private readonly _styles: AnyLayer[]

	@observable private _selectedFeaturesIds: number[] = []

	private readonly service: VectorSourceService = new VectorSourceService()

	getService() {
	  return this.service
	}
	/**
	 *
	 * @param id иеднтификатор Source для maplibre;
	 * @param tiles - массив url тайлов для mapLibre;
	 * @param styles - массив стилей слоёв для mapLibre;
	 */
	constructor(id: string, tiles: string[], styles: AnyLayer[]) {
	  this.id = id
	  this._tiles = tiles
	  this._styles = styles
	}

	@computed get layers(): AnyLayer[] {
	  if (!this.visible) {
	    return []
	  }

	  const source = this.id
	  const baseLayers = this._styles.map<AnyLayer>((item, index) => {
	    const id = `${source}-layer-${index}`
			const style = { ...item, id, source, ["source-layer"]: "default" }  // eslint-disable-line
	    return this.service.overrideLayerOpacity(style, this._selectedFeaturesIds, this.opacity)
	  })

	  const outlineLayers = baseLayers
	    .filter(({ type }) => type === 'fill')
	    .map((item) => this.service.getFillLayerOutline(item, source, this._selectedFeaturesIds, this.opacity))

	  return [...baseLayers, ...outlineLayers]
	}

	@computed get tiles(): string[] {
	  return this.visible ? this._tiles : []
	}

	@action selectFeature(featureId: number) {
	  this._selectedFeaturesIds = [featureId]
	}

	@action setTiles(tiles: string[]) {
	  this._tiles = tiles
	}

	@action resetSelectedFeatures() {
	  this._selectedFeaturesIds = []
	}
}