import ServerApi, {IResponse} from './common/ServerApi'

export default class AuthApi {

  static async authAsUser(id: number, role: string): Promise<IResponse> {
    return ServerApi.getQuery('auth/loginDirect', {
      params: {
        userId: id,
        role: role,
      },
    })
  }

  static async loginBasic(email: string, password: string): Promise<IResponse> {
    return ServerApi.getQuery('auth/login', {
      params: {
        login: email,
        password: password,
      },
    })
  }

  static async logout(subsystem?: string) {
    if (subsystem) return ServerApi.getQuery(`auth/logout/${subsystem}`)
    else return ServerApi.getQuery('auth/logout')
  }
}
