import { action, observable, runInAction, makeObservable } from 'mobx'
import StructureApi from '../api/StructureApi'
import LoadableStore from './common/ILoadableStore'
import { IResponse, message } from '../api/common/ServerApi'
import IGeomElementSortableNode, { makeGeoElementTree } from '../dto/form/IGeomElementSortableNode'
import IGeomTreeDto from '../dto/form/IGeomTreeDto'
import IGeomTreeNodeDto from '../dto/form/IGeomTreeNodeDto'
import IRootStore from './_root/type'

class FormTileOptionsStore extends LoadableStore {
  id: number
  leftGeomTree: IGeomTreeDto = null
  rightGeomTree: IGeomTreeDto = null
  leftTreeData: IGeomElementSortableNode[]
  rightTreeData: IGeomElementSortableNode[]

  selectedNode: IGeomTreeNodeDto

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('FormTileOptionsStore.initialize')
    this.id = initObj as number
    const res = await this.loadTrees()
    this.initialized = true
    return res
  }

  loadLeft = async (): Promise<IResponse> => {
    this.leftGeomTree = null
    this.rightGeomTree = null
    this.leftTreeData = null
    this.rightTreeData = null
    this.isLoading = true
    this.loadingError = null
    const resp = await StructureApi.getGeomTree(this.id, true)
    let newLeftGeomTree: IGeomTreeDto
    if (resp.success) {
      newLeftGeomTree = resp.data
    } else {
      //newFormChainInfo = ;
    }
    runInAction(() => {
      this.leftGeomTree = newLeftGeomTree
      this.leftTreeData = this.leftGeomTree ? makeGeoElementTree(this.leftGeomTree.nodes) : null
      this.loadingError = null
      this.isLoading = false
    })

    return resp
  }

  loadTrees = async (): Promise<IResponse> => {
    this.leftGeomTree = null
    this.rightGeomTree = null
    this.leftTreeData = null
    this.rightTreeData = null
    this.isLoading = true
    this.loadingError = null
    const respLeft = await StructureApi.getGeomTree(this.id, true)
    let newLeftGeomTree: IGeomTreeDto
    if (respLeft.success) newLeftGeomTree = respLeft.data
    else {
      runInAction(() => {
        this.loadingError = message(respLeft)
        this.isLoading = false
      })
      return respLeft
    }
    const respRight = await StructureApi.getGeomTree(this.id, false)
    let newRightGeomTree: IGeomTreeDto
    if (respRight.success) newRightGeomTree = respRight.data
    else {
      runInAction(() => {
        this.loadingError = message(respRight)
        this.isLoading = false
      })
      return respRight
    }
    runInAction(() => {
      this.leftGeomTree = newLeftGeomTree
      this.leftTreeData = this.leftGeomTree ? makeGeoElementTree(this.leftGeomTree.nodes) : []
      this.rightGeomTree = newRightGeomTree
      this.rightTreeData = this.rightGeomTree ? makeGeoElementTree(this.rightGeomTree.nodes) : []
      this.loadingError = null
      this.isLoading = false
    })

    return respRight
  }

  selectNode = (node: IGeomTreeNodeDto) => {
    this.selectedNode = node
    if (this.selectedNode && !this.selectedNode.options.paths) {
      this.selectedNode.options.paths = []
    }
    this.rightTreeData = this.rightTreeData.map(x => x)
  }

  nullify = () => {
    this.id = null
    this.leftGeomTree = null
    this.rightGeomTree = null
    this.leftTreeData = null
    this.rightTreeData = null
  }

  save = async (): Promise<IResponse> => {
    this.isLoading = true
    this.loadingError = null
    const resp = await StructureApi.saveGeomTree(this.leftGeomTree)
    runInAction(() => {
      this.loadingError = resp.success ? null : message(resp)
      this.isLoading = false
    })
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      id: observable,
      leftGeomTree: observable,
      rightGeomTree: observable,
      leftTreeData: observable,
      rightTreeData: observable,
      selectedNode: observable,
      loadTrees: action,
      selectNode: action,
      nullify: action,
      save: action,
    })
  }
}

export default FormTileOptionsStore
