import ServerApi, {IResponse} from './common/ServerApi'
import ISupplierFullDto from '../dto/supplier/ISupplierFullDto'
import ISupplierCreateEditDto from '../dto/supplier/ISupplierCreateEditDto'
import IEditSupplierPermissionDto from '../dto/supplier/IEditSupplierPermissionDto'
import IEditSupplierObserversDto from '../dto/supplier/IEditSupplierObserversDto'
import ISupplierBriefDto from '../dto/supplier/ISupplierBriefDto'

export default class SupplierApi {

  static async getFormSupplierTree(formId: number, includePermissionInfo): Promise<IResponse<ISupplierFullDto>> {
    console.debug('getFormSupplierTree')
    return ServerApi.postQuery('supplier/getFormSupplierTree', {
      id: formId,
      b: includePermissionInfo,
    })
  }

  static async clearFormSuppliers(formId: number): Promise<IResponse<ISupplierFullDto>> {
    console.debug('clearFormSuppliers')
    return ServerApi.postQuery('supplier/clearFormSuppliers', {id: formId})
  }

  static async createFormSupplier(dto: ISupplierCreateEditDto): Promise<IResponse<ISupplierFullDto>> {
    console.debug('createFormSupplier')
    return ServerApi.postQuery('supplier/createFormSupplier', dto)
  }

  static async editFormSupplier(dto: ISupplierCreateEditDto): Promise<IResponse> {
    console.debug('editFormSupplier')
    return ServerApi.postQuery('supplier/editFormSupplier', dto)
  }

  static async moveFormSupplier(dto: ISupplierCreateEditDto): Promise<IResponse> {
    console.debug('moveFormSupplier')
    return ServerApi.postQuery('supplier/moveFormSupplier', dto)
  }

  static async deleteFormSupplier(id: number): Promise<IResponse<ISupplierFullDto>> {
    console.debug('deleteFormSupplier')
    return ServerApi.postQuery('supplier/deleteFormSupplier', {id: id})
  }

  static async getSuppliersForForm(formId: number): Promise<IResponse<ISupplierFullDto[]>> {
    return ServerApi.postQuery('supplier/getSuppliersForForm', { id: formId })
  }

  static copySupplierTree(srcFormId: number, destFormId: number): Promise<IResponse> {
    return ServerApi.postQuery('supplier/copySupplierTree', {
      srcFormId: srcFormId,
      destFormId: destFormId,
    })
  }

  static async getSubSuppliers(srcSupplierId: number): Promise<IResponse<ISupplierFullDto>> {
    return ServerApi.postQuery('supplier/getSubSuppliers', { id: srcSupplierId })
  }

  static async editSupplierPermission(dto: IEditSupplierPermissionDto): Promise<IResponse<ISupplierFullDto>> {
    console.debug('editSupplierPermission')
    return ServerApi.postQuery('supplier/editSupplierPermission', dto)
  }

  static async editSupplierObservers(dto: IEditSupplierObserversDto): Promise<IResponse<ISupplierFullDto>> {
    console.debug('editSupplierObservers')
    return ServerApi.postQuery('supplier/editSupplierObservers', dto)
  }

  static async getFormRootSupplier(formId: number, brief: boolean): Promise<IResponse<ISupplierBriefDto>> {
    return ServerApi.postQuery('supplier/getFormRootSupplier', {id: formId, b: brief})
  }
}
