import { MeasureStore } from 'src/store/map-store/MeasureStore'
import { MeasureMode } from 'src/store/map-store/types'

export class MeasureStoreService {
  private readonly repository: MeasureStore

  constructor(store: MeasureStore) {
    this.repository = store
  }

  addCoords(coordinates: [number, number]) {
    this.repository.setCoordinates([...this.repository.coordinates, coordinates])
  }

  moveCoords(index: number, coords: [number, number]) {
    this.repository.coordinates.splice(index, 1, coords)
    this.repository.setCoordinates([...this.repository.coordinates])
  }

  resetCoords() {
    this.repository.coordinates = []
  }

  resetStore() {
    this.repository.reset()
  }

  rmCoords(index: number) {
    this.repository.coordinates.splice(index, 1)
    this.repository.setCoordinates([...this.repository.coordinates])
  }

  setMouseCoords(coordinates: [number, number] | undefined) {
    this.repository.mouseCoords = coordinates
  }

  toggleMode(mode: MeasureMode) {
    if (this.repository.mode === mode) {
      this.repository.mode = null
      this.repository.coordinates = []
      return
    }
    this.repository.mode = mode
  }
}