import { ContextMenuStore } from 'src/store/context-menu'

export class ContextMenuService {
  private readonly repository: ContextMenuStore

  constructor(contextMenuStore: ContextMenuStore) {
    this.repository = contextMenuStore
  }

  setItems(items: ContextMenuStore['items']) {
    this.repository.items = items
  }

  setPosition(position: ContextMenuStore['position']) {
    this.repository.position = position
  }

  setShow(show: boolean) {
    this.repository.show = show
  }

  reset() {
    this.repository.reset()
  }
}