import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from '../_root/RootStoreModel'
import IRootStore from '../_root/type'

class AlertModalStore extends RootStoreModel {
  file: File
  open: boolean = false
  title: string
  body: string
  handleConfirm?: Function
  handleCancel?: Function
  handleClearEditedLinkedRow?: Function

  openModal = (
    title: string,
    body: string,
    handleConfirm?: Function,
    handleCancel?: Function,
    handleClearEditedLinkedRow?: Function,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.file = null

    this.title = title
    this.body = body
    this.handleConfirm = handleConfirm
    this.handleCancel = handleCancel
    this.handleClearEditedLinkedRow = handleClearEditedLinkedRow

    this.open = true
  }

  closeModal = () => {
    this.open = false
    this.file = null

    this.title = ''
    this.body = ''
    this.handleConfirm = null
    this.handleCancel = null

    if (typeof this.handleClearEditedLinkedRow === 'function') {
      this.handleClearEditedLinkedRow()
    }
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, AlertModalStoreDecorators)
  }
}

export const AlertModalStoreDecorators = {
  file: observable,
  open: observable,
  title: observable,
  body: observable,
  openModal: action,
  closeModal: action,
}

export default AlertModalStore
