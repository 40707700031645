import { action, observable } from 'mobx'    
import { ContextMenuItem } from './types/ContextMenuStoreTypes'

export class ContextMenuStore {
	@observable position: { top: number; left: number } = { top: -9999, left: -9999 }
	@observable items: ContextMenuItem[] = []
    @observable show: boolean = false

	@action reset() {
      this.items = []
      this.show = false
    }
}