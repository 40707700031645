import MBGeoApi from 'src/api/MBGeoApi'
import IPssRequestDto from 'src/dto/request/IPssRequestDto'
import BackgroundListStore from 'src/store/BackgroundListStore'
import IBackgroundListRequestDto from '../../dto/request/IBackgroundListRequestDto'

export class MapBackgroundService {
  private readonly repository?: BackgroundListStore

  constructor(backgroundListStore?: BackgroundListStore) {
    this.repository = backgroundListStore
  }

  async getMany(pageSortOptions?: IPssRequestDto) {
    const defaultOptions = {...(new IBackgroundListRequestDto()), disabled: false}
    defaultOptions.sort = [{ columnName: 'id', asc: false }]

    const options = pageSortOptions || defaultOptions
    const { data, message, status, success } = await MBGeoApi.getBackgrounds(options)
    if (status >= 400 || !success) {
      throw new Error(message || 'Что-то пошло не так...')
    }
    return data
  }

  reset() {
    if ( this.repository ) {
      this.repository.setAvailableBackgrounds(null)
      this.repository.setSelectedBackgroundId(undefined)
    }
  }
}