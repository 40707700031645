import ServerApi, { IResponse } from './common/ServerApi'
import IPageDto, {emptyPage} from '../dto/common/IPageDto'
import IFormBriefDto from '../dto/form/IFormBriefDto'
import IFormFullDto from '../dto/form/IFormFullDto'
import ICreateEditFormDto from '../dto/form/ICreateEditFormDto'
import ISetFormColumnsDto from '../dto/form/ISetFormColumnsDto'
import IFormListRequestDto from '../dto/request/IFormListRequestDto'
import ISetFormColumnsWithGroupsDto from '../dto/form/ISetFormColumnsWithGroupsDto'
import IFormHeadersRequestDto from '../dto/request/IFormHeadersRequestDto'
import IFormColumnsDto from '../dto/form/IFormColumnsDto'
import IFormHeaderDto from '../dto/form/IFormHeaderDto'
import IFormRequestDto from '../dto/request/IFormRequestDto'
import IFormScheduleDto from '../dto/form/IFormScheduleDto'
import IColumnDescDto from '../dto/columndesc/IColumnDescDto'
import ICopyFormDto from '../dto/form/ICopyFormDto'
import { DIC_REQUEST_MODE } from '../enums/DIC_REQUEST_MODE'
import IReportColumnsDto from '../dto/report/IReportColumnsDto'
import IGeomTreeDto from '../dto/form/IGeomTreeDto'
import IReferrerFormDto from '../dto/form/IReferrerFormDto'
import IRerrererFormEditDto from '../dto/form/IRerrererFormEditDto'
import ILinkedInputsRequestDto from '../dto/request/ILinkedInputsRequestDto'
import IIdPssRequestDto from "../dto/request/IIdPssRequestDto";
import IReferrerColumnBaseDto from "../dto/columndesc/IReferrerColumnBaseDto";

export default class StructureApi {
  static async getForms(
    pageRequestDto: IFormListRequestDto,
  ): Promise<IResponse<IPageDto<IFormBriefDto>>> {
    console.debug('getForms...')
    return ServerApi.postQuery('structure/getForms', pageRequestDto)
  }

  static async getForm(id: number): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('structure/getForm', { id: id })
  }

  static async getForm2(dto: IFormRequestDto): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('structure/getForm', dto)
  }

  static async createForm(createDto: ICreateEditFormDto): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('structure/createForm', createDto)
  }

  static async editForm(editDto: ICreateEditFormDto): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('structure/editForm', editDto)
  }

  static async deleteForm(id: number): Promise<IResponse> {
    return ServerApi.postQuery('structure/deleteForm', { id: id })
  }

  static async setFormColumns(dto: ISetFormColumnsDto): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('structure/setFormColumns', dto)
  }

  static async setFormColumnsWithGroups(
    dto: ISetFormColumnsWithGroupsDto,
  ): Promise<IResponse<IFormFullDto>> {
    return ServerApi.postQuery('structure/setFormColumnsWithGroups', dto)
  }

  static async deleteColumnWithData(id: number): Promise<IResponse> {
    return ServerApi.postQuery('structure/deleteColumnWithData', { id: id })
  }

  static async getFormDictionaries(
    requestDto: IFormHeadersRequestDto,
  ): Promise<IResponse<IFormColumnsDto[]>> {
    return ServerApi.postQuery('structure/getFormDictionaries', requestDto)
  }

  static async getReportDictionaries(
    requestDto: IFormHeadersRequestDto,
  ): Promise<IResponse<IReportColumnsDto[]>> {
    return ServerApi.postQuery('structure/getReportDictionaries', requestDto)
  }

  static async getFormHeaders(
    requestDto: IFormHeadersRequestDto,
  ): Promise<IResponse<IFormHeaderDto>> {
    return ServerApi.postQuery('structure/getFormHeaders', requestDto)
  }

  static async getFormBlankXlsx(id: number, fileType: string, saveName?: string) {
    return ServerApi.downloadFileQuery(
      'structure/getFormBlankXlsx',
      saveName || `form_${id}_blank.xlsx`,
      {
        formId: id,
        fileType: fileType,
      },
    )
  }

  static async getReportBlankXlsx(id: number, fileType: string, saveName?: string) {
    return ServerApi.downloadFileQuery(
      'structure/getReportBlankXlsx',
      saveName || `report_${id}_blank.xlsx`,
      {
        reportId: id,
        fileType: fileType,
      },
    )
  }

  static async getRefererForms(
    requestDto: IIdPssRequestDto,
  ): Promise<IResponse<IPageDto<IReferrerFormDto>>> {
    console.debug('getRefererForms...')
    return ServerApi.postQuery('structure/getRefererForms', requestDto)
  }

  static async getLinkedInputs(
    requestDto: ILinkedInputsRequestDto,
  ): Promise<IResponse<IReferrerColumnBaseDto[]>> {
    return {success: true, data: []}
    console.debug('getLinkedInputs...')
    return ServerApi.postQuery('structure/getLinkedInputs', requestDto)
  }

  static async addLinkedInput(
    requestDto: IRerrererFormEditDto,
  ): Promise<IResponse<IReferrerColumnBaseDto[]>> {
    console.debug('addLinkedInput...')
    return ServerApi.postQuery('structure/addLinkedInput', requestDto)
  }

  static async removeLinkedInput(
    requestDto: IRerrererFormEditDto,
  ): Promise<IResponse<IReferrerColumnBaseDto[]>> {
    console.debug('removeLinkedInput...')
    return ServerApi.postQuery('structure/removeLinkedInput', requestDto)
  }

  static async saveFormSchedule(dto: IFormScheduleDto): Promise<IResponse<IFormScheduleDto[]>> {
    console.debug('saveFormSchedule...')
    return ServerApi.postQuery('structure/saveFormSchedule', dto)
  }

  static async deleteFormSchedule(id: number): Promise<IResponse> {
    console.debug('deleteFormSchedule...')
    return ServerApi.postQuery('structure/deleteFormSchedule', { id: id })
  }

  static async clearFormSchedules(formId: number): Promise<IResponse> {
    console.debug('clearFormSchedules...')
    return ServerApi.postQuery('structure/clearFormSchedules', { id: formId })
  }

  static async getColumnsAndGroupsCopy(
    srcFormId: number,
    destFormId: number,
  ): Promise<IResponse<IFormFullDto>> {
    console.debug('getColumnsAndGroupsCopy...')
    return ServerApi.postQuery('structure/getColumnsAndGroupsCopy', {
      srcFormId: srcFormId,
      destFormId: destFormId,
    })
  }

  static async getColumnsFromXlsxSample(file: File): Promise<IResponse<IColumnDescDto[]>> {
    console.debug('getColumnsFromXlsxSample...')

    const fd = new FormData()
    fd.append('file', file)

    return ServerApi.postQuery('structure/getColumnsFromXlsxSample', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async getGeomTree(formId: number, left: boolean): Promise<IResponse<IGeomTreeDto>> {
    console.debug('getGeomTree...')
    return ServerApi.postQuery('structure/getGeomTree', {
      id: formId,
      b: left,
    })
  }

  static async saveGeomTree(dto: IGeomTreeDto): Promise<IResponse> {
    console.debug('saveGeomTree...')
    return ServerApi.postQuery('structure/saveGeomTree', dto)
  }

  static async copyForm(dto: ICopyFormDto): Promise<IResponse<IFormFullDto>> {
    console.debug('copyForm...')
    return ServerApi.postQuery('structure/copyForm', dto)
  }

  static async getSupersetSqlQuery(
    formId: number,
    dicRequestMode: DIC_REQUEST_MODE,
  ): Promise<IResponse<string>> {
    console.debug('getSupersetSqlQuery')
    return ServerApi.postQuery('structure/getSupersetSqlQuery', {
      formId: formId,
      dicRequestMode: dicRequestMode,
    })
  }

  static async getVirtualFormColumnsDTO(): Promise<IResponse<IFormColumnsDto>> {
    return ServerApi.postQuery('structure/getVirtualFormColumnsDTO', null)
  }
}
