import { ReportItemStore, ReportItemStoreData } from 'src/store/report-item'
import { message } from 'src/api/common/ServerApi'
import DataApi from 'src/api/DataApi'
import IFilterFieldDto from 'src/dto/request/IFilterFieldDto'
import { copyAndSpread } from 'src/util/objects'
import IReportDto from 'src/dto/report/IReportDto'
import IColumnDescDto from 'src/dto/columndesc/IColumnDescDto'
import IDataRowReadFullDto from 'src/dto/data/IDataRowReadFullDto'
import IPssRequestDto from 'src/dto/request/IPssRequestDto'
import { ReportItemStoreLinkedItem, SearchData } from 'src/store/report-item/types'
import StructureApi from 'src/api/StructureApi'
import IIdCodeNameDto from 'src/dto/common/IIdCodeNameDto'
import IReferrerColumnFullDto from 'src/dto/columndesc/IReferrerColumnFullDto'
import IReferrerFormDto from 'src/dto/form/IReferrerFormDto'
import { DIC_REQUEST_MODE } from 'src/enums/DIC_REQUEST_MODE'

export class ReportItemService {
  private readonly repository: ReportItemStore

  constructor(reportItemStore: ReportItemStore) {
    this.repository = reportItemStore
  }

  /**
   * 8.1. Получает отчёт.
   * @param id - идентификатор отчёта;
   * @returns отчёт
   */
  async getReport(id: number) {
    const resp = await DataApi.getReport(id)
    if (resp.success) {
      const datum = resp.data
      if (datum) {
        return datum
      } else {
        throw new Error('Неизвестная ошибка')
      }
    } else {
      throw new Error(message(resp))
    }
  }

  async getForm(id: number) {
    const resp = await StructureApi.getForm(id)
    if (resp.success) {
      const datum = resp.data
      if (datum) {
        return datum
      } else {
        throw new Error('Неизвестная ошибка')
      }
    } else {
      throw new Error(message(resp))
    }
  }

  /**
   * 8.2. Детальная инормация о строке отчёта.
   * @param id - идентификатор строки;
   * @param requestOptions - параметры текущего url;
   * @returns
   */
  async getReportItemDetails(
    id: number,
    reportId: number,
    openData: boolean,
    dicRequestMode?: DIC_REQUEST_MODE,
    includeReferrerFields?: boolean,
  ): Promise<IDataRowReadFullDto> {
    const params = copyAndSpread(
      { reportId },
      {
        pageSize: 1,
        pageNumber: 0,
        filter: [{ columnName: 'id', numEq: id } as IFilterFieldDto],
        dontUpdateUiSettings: true,
        geoFormat: 'GEOJSON',
        supplierFilter: 'USER_ALL',
        open: openData,
        includeReferrerFields: includeReferrerFields,
      },
    )
    if (dicRequestMode) {
      params.dicRequestMode = dicRequestMode
    }

    const resp = await DataApi.getReportDataWeb(params)
    if (resp.success) {
      const datum = resp.data?.content?.[0]
      if (datum) {
        return datum
      } else {
        throw new Error('Неизвестная ошибка')
      }
    } else {
      throw new Error(message(resp))
    }
  }

  /**
   * 8.3. Получает основные колонки отчёта.
   * @param report - отчёт
   * @returns {IColumnDescDto[]} - колонки
   */
  getReportAttributionColumns(report: IReportDto): IColumnDescDto[] {
    return report.columns.filter(({ colType }) => !['POSTGIS', 'FILE_SET'].includes(colType))
  }

  /**
   * Получает основные колонки отчёта.
   * @param report - отчёт
   * @returns {IColumnDescDto[]} - колонки
   */
  getReportFilesetColumns(report: IReportDto): IColumnDescDto[] {
    return report.columns.filter(({ colType }) => ['FILE_SET'].includes(colType))
  }
  getReportPostgisColumns(report: IReportDto): IColumnDescDto[] {
    return report.columns.filter(({ colType }) => ['POSTGIS'].includes(colType))
  }

  // возвращает колонки формы, досупные для связанного ввода;
  async getRefererColumns(formId: number) {
    const data = (await StructureApi.getLinkedInputs({ id: formId, full: true }))
      .data as IReferrerColumnFullDto[]
    return data
  }

  // возвращает колонки и формы отчётов, содержащие строки ссылающиеся на текущию строку;
  async getRefererForms(formId: number): Promise<IReferrerFormDto[]> {
    return (await StructureApi.getRefererForms({ id: formId })).data.content
  }

  // вощавращает строки ссылающиеся на текущию строку в колонке с именем;
  /**
   *
   * @param reportId - id ссылающегося отчёта;
   * @param userGroupId
   * @param linkedColumnName - наименование колонки, на которую ссылается отчет с reportId;
   * @param itemId - id строки, на которую ссылается отчёт с reportId;
   * @returns
   */
  async getLinkedItemsByLinkedColumnName(
    reportId: number,
    userGroupId: number,
    linkedColumnName: string,
    itemId: number,
  ) {
    return await DataApi.getReportDataWeb({
      reportId,
      userGroupId: userGroupId,
      dicRequestMode: 'ID_CODE_DESC_FIELDS',
      includeRowInfo: true,
      includeRowInfoAsColumns: false,
      pageSize: 10,
      supplierFilter: 'OWN',
      includeAscendants: true,
      filter: [
        {
          columnName: linkedColumnName,
          refIn: [{ id: itemId } as IIdCodeNameDto],
        } as IFilterFieldDto,
      ],
    }).then(({ data }) => data)
  }

  /**
   * 8.4. Информация о строке отчёта с подписями полей.
   * @param columns - основне колонки отчёта;
   * @param reportItemDetails - информация о строке отчёта;
   * @returns
   */
  generateAttributionInfo(
    columns: IColumnDescDto[],
    reportItemDetails: IDataRowReadFullDto,
  ): ReportItemStoreData[] {
    const formatter = new Intl.DateTimeFormat('ru', {
      year: 'numeric',
      month: '2-digit',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    function getValue(
      reportItemDetails: IDataRowReadFullDto,
      columnId: number,
      type: IColumnDescDto['colType'],
    ) {
      const value = reportItemDetails.values[columnId.toString()]
      if (type === 'TIMESTAMP') {
        try {
          const date = new Date(value)
          return value === null ? '-' : formatter.format(date)
        } catch (e) {
          console.warn(e)
          return null
        }
      }

      if (typeof value === 'boolean') {
        return value ? 'Да' : 'Нет'
      }

      return value || '-'
    }
    return columns.map(({ id: columnId, colTitle: columnTitle, colType }) => {
      const value = getValue(reportItemDetails, columnId, colType)
      return { columnId, columnTitle, value, colType }
    })
  }

  /**
   * 8.5. Установить информацию о строке в хранилище.
   * @param data - информация о строке с подписями полей;
   */
  setDataStore(data: ReportItemStoreData[]) {
    this.repository.data = data
  }

  /**
   * 8.6. Установить идентификаторы отчёта и строки в хранилище.
   * @param reportId - идентификатор отчёта;
   * @param itemId - идентификатор строки отчёта;
   */
  setIds(reportId: number, itemId: number) {
    this.repository.itemId = itemId
    this.repository.reportId = reportId
  }

  /**
   * 8.7. Установить флаг загрузки в хранилище.
   * @param payload - флаг загрузки;
   */
  setIsLoading(payload: boolean) {
    this.repository.linkedDataIsLoading = payload
  }

  async getManyReports(reportsIds: number[]) {
    const promises = reportsIds.map(reportId => DataApi.getReport(reportId))
    const data = await Promise.all(promises)

    return data.filter(({ success }) => success).map(({ data }) => data)
  }

  getShowSearch() {
    return this.repository.showSearchItems
  }

  async searchInReportsByString(reportsIds: number[], searchParams: IPssRequestDto) {
    const promises = reportsIds.map(reportId =>
      DataApi.getReportDataWeb({
        geoFormat: 'FLAG',
        includeRowInfo: true,
        includeRowInfoAsColumns: false,
        reportId,
        pageNumber: 0,
        pageSize: 3,
        ...searchParams,
      }),
    )
    const data = await Promise.all(promises)

    return reportsIds
      .map<{ reportId: number; items: IDataRowReadFullDto[] }>((reportId, index) => ({
        reportId,
        items: data[index].success ? data[index].data?.content || [] : [],
      }))
      .filter(({ items }) => items.length)
  }

  getReportItemsSearchParams(columnName: string, like: string): IPssRequestDto {
    return {
      filter: [
        {
          columnName,
          like,
        } as IFilterFieldDto,
      ],
    }
  }

  resetStore() {
    this.repository.reset()
  }

  resetItem() {
    this.repository.data = []
    this.repository.linkedDataIsLoading = false
    this.repository.itemId = undefined
    this.repository.reportId = undefined
  }

  setSearchData(data: SearchData[]) {
    this.repository.searchData = data
  }

  setSearchDataIsLoading(payload: boolean) {
    this.repository.searchDataIsLoading = payload
  }

  toggleShowSearch() {
    this.repository.showSearchItems = true
  }
}
