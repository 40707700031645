import ServerApi, {IResponse} from './common/ServerApi'
import IPageDto from '../dto/common/IPageDto'
import IUserFullDto from '../dto/ancient-mser/IUserFullDto'
import IPssRequestDto from '../dto/request/IPssRequestDto'
import ICreateUserDto from '../dto/ancient-mser/ICreateUserDto'
import IChangePasswordDto from '../dto/ancient-mser/IChangePasswordDto'
import IEditProfileDto from '../dto/ancient-mser/IEditProfileDto'
import IUiSettingRequestDto from '../dto/uisetting/IUiSettingRequestDto'
import IUiSettingDto from '../dto/uisetting/IUiSettingDto'
import IIdPssRequestDto from '../dto/request/IIdPssRequestDto'
import IUserListRequestDto from '../dto/request/IUserListRequestDto'
import IUserLoginListRequestDto from '../dto/request/IUserLoginListRequestDto'
import IUserLoginDto from '../dto/ancient-mser/IUserLoginDto'

export default class UserApi {

  static async getUsers(apiRequestOptions: IUserListRequestDto): Promise<IResponse<IPageDto<IUserFullDto>>> {
    return ServerApi.postQuery('user/getUsers', apiRequestOptions)
  }

  static async getUser(id: number): Promise<IResponse<IUserFullDto>> {
    return ServerApi.postQuery('user/getUser', {id: id})
  }

  static async saveUser(dto: ICreateUserDto): Promise<IResponse<IUserFullDto>> {
    return ServerApi.postQuery('user/saveUser', dto)
  }

  static async editProfile(dto: IEditProfileDto): Promise<IResponse<IUserFullDto>> {
    return ServerApi.postQuery('user/editProfile', dto)
  }

  static async changePassword(dto: IChangePasswordDto): Promise<IResponse> {
    return ServerApi.postQuery('user/changePassword', dto)
  }

  static async syncCentralUsers(): Promise<IResponse> {
    return ServerApi.postQuery('user/syncCentralUsers', null)
  }

  static async getUiSetting(dto: IUiSettingRequestDto): Promise<IResponse> {
    return ServerApi.postQuery('user/getUiSetting', dto)
  }

  static async saveUiSetting(dto: IUiSettingDto): Promise<IResponse> {
    return ServerApi.postQuery('user/saveUiSetting', dto)
  }

  static async generateNewPassword(userId: number) : Promise<IResponse> {
    return ServerApi.postQuery('user/generateNewPassword', {
      id: userId,
    })
  }

  static async getUserLogins(apiRequestOptions: IUserLoginListRequestDto): Promise<IResponse<IPageDto<IUserLoginDto>>> {
    return ServerApi.postQuery('user/getUserLogins', apiRequestOptions)
  }
}
