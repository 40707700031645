import ServerApi, {IResponse} from './common/ServerApi'
import IReportGisInfo from '../dto/gismap/IReportGisInfo'
import IGisReportRequestDto from '../dto/gismap/IGisReportRequestDto'
import IGisLayerRequestDto from '../dto/gismap/IGisLayerRequestDto'
import IBaseLayerDto from '../dto/gismap/IBaseLayerDto'
import IPssRequestDto from '../dto/request/IPssRequestDto'
import IPageDto from '../dto/common/IPageDto'
import IBackgroundDto from '../dto/gismap/IBackgroundDto'
import IGeojsonUploadDto from '../dto/gismap/IGeojsonUploadDto'
import IGismapListRequestDto from '../dto/request/IGismapListRequestDto'
import IGismapDto from '../dto/gismap/IGismapDto'
import IGisReportListRequestDto from '../dto/gismap/IGisReportListRequestDto'
import IGismapAsGisInfoRequestDto from '../dto/gismap/IGismapAsGisInfoRequestDto'
import {FindLayersByGeometryPayload} from 'src/dto/gismap/FindLayersByGeometryPayload'
import IFindLayersByGeometryResponse from '../dto/gismap/IFindLayersByGeometryResponse'

export default class MBGeoApi {

  static async getReportGisInfo(requestDTO: IGisReportRequestDto): Promise<IResponse<IReportGisInfo>> {
    console.debug('getReportGisInfo')
    return ServerApi.postQuery('gismap/getReportGisInfo', requestDTO)
  }

  static async getReportGisInfos(requestDTO: IGisReportListRequestDto): Promise<IResponse<IReportGisInfo[]>> {
    console.debug('getReportGisInfos')
    return ServerApi.postQuery('gismap/getReportGisInfos', requestDTO)
  }

  static async getLayer(requestDTO: IGisLayerRequestDto): Promise<IResponse<IBaseLayerDto>> {
    console.debug('getLayer')
    return ServerApi.postQuery('gismap/getLayer', requestDTO)
  }

  // backgrounds
  static async getBackgrounds(requestDTO: IPssRequestDto): Promise<IResponse<IPageDto<IBackgroundDto>>> {
    console.debug('getBackground')
    return ServerApi.postQuery('gismap/getBackgrounds', requestDTO)
  }
  static async getDefaultBackground(): Promise<IResponse<IBackgroundDto>> {
    console.debug('getDefaultBackground')
    return ServerApi.postQuery('gismap/getDefaultBackground', null)
  }
  static async deleteBackground(id: number): Promise<IResponse> {
    return ServerApi.postQuery('gismap/deleteBackground', { id: id } )
  }

  static async deleteBackgrounds(ids: number[]): Promise<IResponse> {
    return ServerApi.postQuery('gismap/deleteBackgrounds', ids )
  }
  static async saveBackground(dto: IBackgroundDto): Promise<IResponse<IBackgroundDto>> {
    console.debug('saveBackground')
    return ServerApi.postQuery('gismap/saveBackground', dto)
  }

  static async loadDbConfig(): Promise<IResponse> {
    console.debug('loadDbConfig')
    return ServerApi.postQuery('gismap/loadDbConfig', null)
  }

  static async uploadGeojson(dto: IGeojsonUploadDto) {
    console.debug('uploadGeojson')
    return ServerApi.postQuery('gismap/uploadGeojson', dto)
  }

  static async uploadGeojsonFile(dto: IGeojsonUploadDto, file: File, onProgress) {
    console.debug('uploadGeojsonFile')

    const fd = new FormData()
    fd.append('file', file)
    fd.append('dto', JSON.stringify(dto))

    return ServerApi.postQuery('gismap/uploadGeojsonFile', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    })
  }

  static getGismap(id: number): Promise<IResponse<IGismapDto>> {
    console.debug('getGismap')
    return ServerApi.postQuery('gismap/getGismap', {id: id})
  }

  static getGismaps(requestDto: IGismapListRequestDto): Promise<IResponse<IPageDto<IGismapDto>>> {
    console.debug('getGismaps')
    return ServerApi.postQuery('gismap/getGismaps', requestDto)
  }

  static deleteGismap(id: number): Promise<IResponse> {
    console.debug('deleteGismap')
    return ServerApi.postQuery('gismap/deleteGismap', {id: id})
  }

  static deleteGismaps(ids: number[]): Promise<IResponse> {
    console.debug('deleteGismaps')
    return ServerApi.postQuery('gismap/deleteGismaps', {ids: ids})
  }

  static saveGismap(dto: IGismapDto): Promise<IResponse<IGismapDto>> {
    console.debug('saveGismap')
    return ServerApi.postQuery('gismap/saveGismap', dto)
  }

  static getGismapAsGisInfos(dto: IGismapAsGisInfoRequestDto): Promise<IResponse<IReportGisInfo[]>> {
    console.debug('getGismapAsGisInfos')
    return ServerApi.postQuery('gismap/getGismapAsGisInfos', dto)
  }

  static findLayersByGeometry<Payload extends FindLayersByGeometryPayload>(dto: Payload): Promise<IResponse<IFindLayersByGeometryResponse>> {
    return ServerApi.postQuery('gismap/findLayersByGeometry', dto)
  }

}
