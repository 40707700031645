import { action, observable, runInAction, makeObservable } from 'mobx'
import BaseListStore from './common/BaseListStore'
import { IResponse } from '../api/common/ServerApi'
import { copyAndSpread } from '../util/objects'
import ITagDto from '../dto/tag/ITagDto'
import ITagListRequestDto from '../dto/tag/ITagListRequestDto'
import TagApi from '../api/TagApi'
import INameDto from '../dto/common/INameDto'
import ITagCreateEditDto from '../dto/tag/ITagCreateEditDto'
import ITagBriefDto from '../dto/tag/ITagBriefDto'
import IRootStore from './_root/type'

class TagListStore extends BaseListStore<ITagDto, ITagListRequestDto> {
  listLoaded: boolean = false
  tags: ITagBriefDto[]
  tagMap: {}
  selectedTags: string[] = []

  initialize = async (initObj?: any): Promise<IResponse> => {
    console.debug('TagListStore::initialize')
    // sync synhro
    this.requestOptions = this.getDefaultRequestOptions()
    if (
      initObj &&
      typeof initObj === 'object' &&
      initObj.requestOptions &&
      typeof initObj.requestOptions === 'object'
    ) {
      this.requestOptions = copyAndSpread(this.requestOptions, initObj.requestOptions)
    }

    //await this.loadDics();
    const resp = await this.load(this.requestOptions)
    this.initialized = true
    return resp
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      listLoaded: observable,
      tags: observable,
      tagMap: observable,
      selectedTags: observable,
      getTags: action,
      createTag: action,
      deleteTag: action,
      renameTag: action,
      editTag: action,
    })
  }

  protected getDefaultRequestOptions(): ITagListRequestDto {
    return new ITagListRequestDto()
  }

  deleteTag = async (name: string): Promise<IResponse> => {
    const resp = await TagApi.deleteTag(name)
    if (resp.success) {
      this.getTags(true)
    }
    return resp
  }

  createTag = async (name: string): Promise<IResponse<INameDto>> => {
    this.isLoading = true
    this.loadingError = null
    const resp = await TagApi.createTag(name)
    if (resp.success) {
      this.isLoading = false
      this.load(this.requestOptions)
      this.getTags(true)
    } else {
      this.isLoading = false
      this.loadingError = resp.message
    }

    return resp
  }

  renameTag = async (oldName: string, newName: string): Promise<IResponse> => {
    const resp = await TagApi.renameTag({
      oldName: oldName,
      newName: newName,
    })
    runInAction(() => {
      if (resp.success) {
        this.load(this.requestOptions)
        this.getTags(true)
      }
    })
    return resp
  }

  editTag = async (editDto: ITagCreateEditDto): Promise<IResponse<ITagDto>> => {
    const resp = await TagApi.editTag(editDto)
    runInAction(() => {
      if (resp.success) {
        this.load(this.requestOptions)
        this.getTags(true)
      }
    })
    return resp
  }

  protected async getListRoutine(apiRequestOptions: ITagListRequestDto): Promise<IResponse> {
    return TagApi.getTagList(apiRequestOptions)
  }

  load = async (requestOptions: ITagListRequestDto): Promise<IResponse> => {
    return this.loadList(requestOptions)
  }

  getTags = async (force?: boolean): Promise<IResponse<ITagDto[]>> => {
    if (!force && this.tags) return
    this.isLoading = true
    this.loadingError = null
    const resp = await TagApi.getTags()
    runInAction(() => {
      if (resp.success) {
        this.tags = resp.data
        this.tagMap = {}
        this.tags.forEach(x => {
          this.tagMap[x.name] = x
        })
        this.listLoaded = true
      } else {
        this.loadingError = resp.message
      }
      this.isLoading = false
    })

    return resp
  }
}

export default TagListStore
