import React, { FunctionComponent, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { isMobile } from 'react-device-detect'
import NavBar from './NavBar'
import TopBar from './TopBar'
import { MscTheme } from 'src/theme/type'
import { getSubsystem } from '../../auth/LoginView'
import contentOrnament1 from './content-ornament-1.svg'

const useStyles = makeStyles((theme: MscTheme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    '@media print': {
      maxWidth: '280mm',
    },
    paddingTop: '70px',
    backgroundRepeat: 'no-repeat',
    //backgroundSize: 'contain',
    backgroundImage: `url(${contentOrnament1})`,
    backgroundPosition: 'top right',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    //overflow: 'hidden',
    paddingTop: 10,
    transition: '225ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    transitionProperty: 'padding-left',
    paddingLeft: 0,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256
    // }
  },
  wrapperNavbarOpen: {
    paddingLeft: 256,
    '@media print': {
      paddingLeft: 0,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    //overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    //height: '100%',
    //overflow: 'auto' // auto
  },
}))

const MainLayout: FunctionComponent = ({ children, ...rest }) => {
  console.debug('MainLayout...')

  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(isMobile ? false : true)
  const subsystem = getSubsystem(rest)

  const wrapperClasses = clsx(classes.wrapper, {
    [classes.wrapperNavbarOpen]: isMobileNavOpen && !isMobile,
  })

  return (
    <div className={classes.root}>
      <TopBar
        subsystem={subsystem}
        onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)}
        isMobileNavOpen={isMobileNavOpen}
      />
      <NavBar
        subsystem={subsystem}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={wrapperClasses}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default MainLayout
