import {STAGE_STATUS, STAGE_STATUS_MAP} from '../../enums/STAGE_STATUS'
import {SUPPLIER_FILTER, SUPPLIER_FILTER_MAP} from '../../enums/SUPPLIER_FILTER'
import IReportBaseDataRequestDto from './IReportBaseDataRequestDto'
import ISupplierBriefDto from '../supplier/ISupplierBriefDto'
import {GEO_FORMAT} from '../../enums/gismap/GEO_FORMAT'
import {FIELD_REQUEST_MODE} from '../../enums/FIELD_REQUEST_MODE'

export default class IReportDataRequestDto extends IReportBaseDataRequestDto {
  userGroupId?: number
  stageStatus?: STAGE_STATUS
  supplierFilter?: SUPPLIER_FILTER
  includeAscendants?: boolean
  includeRowInfo: boolean
  subSupplierId?: number
  geoFormat?: GEO_FORMAT
  fieldRequestMode?: FIELD_REQUEST_MODE
  dontUpdateUiSettings?: boolean
  includeMainGeometry?: boolean

  // если true - то игнорируем поля userGroupId, providerFilter, subProviderId (аока используется только на запросе тайлов)
  open?: boolean

  // front only
  subSupplier?: ISupplierBriefDto

  constructor() {
    super()
    this.pageSize = 25
    this.includeRowInfo = true
    this.geoFormat = 'FLAG'
  }

}

export function supplierOptionAsStr(value: IReportDataRequestDto): string {
  if (value) {
    const arr: string[] = []
    if (value.subSupplier) arr.push(`[${value.subSupplier.userGroup.name}]`)
    if (value.supplierFilter) arr.push(SUPPLIER_FILTER_MAP[value.supplierFilter])
    arr.push( (value.includeAscendants ? 'Отображать' : 'Не отображать' ) + ' уже опубликованные мной' )
    arr.push( 'Статус: ' + (value.stageStatus ? (STAGE_STATUS_MAP[value.stageStatus]) : 'любой') )
    return arr.join(', ')
  } else {
    return undefined
  }
}