import ServerApi, {IResponse} from './common/ServerApi'
import IPageDto from '../dto/common/IPageDto'
import ITagListRequestDto from '../dto/tag/ITagListRequestDto'
import ITagDto from '../dto/tag/ITagDto'
import INameDto from '../dto/common/INameDto'
import IRenameDto from '../dto/common/IRenameDto'
import ITagCreateEditDto from '../dto/tag/ITagCreateEditDto'
import ITagBriefDto from '../dto/tag/ITagBriefDto'

export default class TagApi {

  static async getTagList(dto: ITagListRequestDto): Promise<IResponse<IPageDto<ITagDto>>> {
    console.debug('getTagList...')
    return ServerApi.postQuery('tag/getTagList', dto)
  }

  static async getTags(): Promise<IResponse<ITagBriefDto[]>> {
    console.debug('getTags...')
    return ServerApi.postQuery('tag/getTags', null)
  }

  static async createTag(name: string): Promise<IResponse<ITagDto>> {
    return ServerApi.postQuery('tag/createTag', {name: name})
  }

  static async deleteTag(name: string): Promise<IResponse>  {
    return ServerApi.postQuery('tag/deleteTag', {name: name})
  }

  static async renameTag(dto: IRenameDto): Promise<IResponse<ITagDto>>  {
    return ServerApi.postQuery('tag/renameTag', dto)
  }

  static async editTag(dto: ITagCreateEditDto): Promise<IResponse<ITagDto>>  {
    return ServerApi.postQuery('tag/editTag', dto)
  }

}
