import React, {FunctionComponent} from 'react'
import {observer} from 'mobx-react'
import {Redirect, Route, RouteProps} from 'react-router-dom'
import useStore from 'src/context/RootStoreContext'
import {getSubsystem} from '../../auth/LoginView'

const AdminRoute: FunctionComponent<RouteProps> = ({
  component,
  ...rest
}) => {
  const { authStore } = useStore()
  const hasAccess = authStore.isSysAdmin || authStore.isObserver || authStore.isUserGroupAdmin
  const Component = component as React.ComponentType
  const subsystem = getSubsystem(rest)
  const signinUrl = `/${subsystem}/signin`

  return (
    <Route
      {...rest}
      render={props =>
        hasAccess ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: signinUrl,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default observer(AdminRoute)
