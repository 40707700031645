import React, {FunctionComponent, useEffect, useState} from 'react'
import useStore from 'src/context/RootStoreContext'
import SplashScreen from '../../components/SplashScreen'

const Auth: FunctionComponent = props => {
  const [isLoading, setLoading] = useState(true)
  const { getAuthenticatedUserDetails } = useStore().authStore
  useEffect(() => {
    const initAuth = async () => {
      await getAuthenticatedUserDetails()
      setLoading(false)
    }
    initAuth()
  }, [getAuthenticatedUserDetails])

  if (isLoading) return <SplashScreen />
  return <>{props.children}</>
}

export default Auth
