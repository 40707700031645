import { action, observable, makeObservable } from 'mobx'
import LoadableStore from '../common/ILoadableStore'
import IUserGroupBriefDTO from '../../dto/usergroup/IUserGroupBriefDto'
import UserGroupStore from '../UserGroupStore'
import IRootStore from '../_root/type'
import { IResponse } from '../../api/common/ServerApi'

class UserGroupSelectorStore extends LoadableStore {
  userGroupStore: UserGroupStore

  open: boolean = false
  selectedUserGroup: IUserGroupBriefDTO

  handleSelect: (userGroup: IUserGroupBriefDTO) => Promise<boolean>
  handleCancel: () => void

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      selectedUserGroup: observable,

      openModal: action,
      closeModal: action,
    })

    this.userGroupStore = new UserGroupStore(root)
  }

  openModal = async (
    initialValue: IUserGroupBriefDTO,
    onSelect: (IFormBriefDto) => Promise<boolean>,
    onCancel?: () => void,
  ) => {
    this.selectedUserGroup = initialValue
    this.handleSelect = onSelect
    this.handleCancel = onCancel
    this.open = true
    await this.initialize()
  }

  closeModal = () => {
    this.open = false
    if (this.handleCancel) this.handleCancel()
  }

  public async initialize(initObj?: any): Promise<IResponse> {
    await super.initialize(initObj)
    return this.userGroupStore.initialize(initObj)
  }
}

export default UserGroupSelectorStore
