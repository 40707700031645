
export type SUPPLIER_FILTER = 'ALL' | 'OWN' | 'OWN_AND_CHILD' | 'OWN_AND_DESCENDANT' | 'CHILD' | 'DESCENDANT' | 'USER_ALL';

export const SUPPLIER_FILTERS: Array<SUPPLIER_FILTER> = ['ALL', 'OWN', 'OWN_AND_CHILD', 'OWN_AND_DESCENDANT', 'CHILD',
  'DESCENDANT', 'USER_ALL']

export const SUPPLIER_FILTER_MAP = {
  'ALL': 'Все',
  'OWN': 'Только свои',
  'OWN_AND_CHILD': 'Свои и непосредственных подчиненных',
  'OWN_AND_DESCENDANT': 'Свои и всех потомков',
  'CHILD': 'Непосредственных подчиненных',
  'DESCENDANT': 'Всех потомков',
  'USER_ALL': 'Все видимые пользователю',
}