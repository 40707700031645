import React, {FunctionComponent} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import clsx from 'clsx'
import {AppBar, Box, colors, IconButton, makeStyles, SvgIcon, Toolbar, Typography,} from '@material-ui/core'
import {Menu as MenuIcon, X as CloseIcon} from 'react-feather'
import Account from './Account'
import useStore from 'src/context/RootStoreContext'
import Logo from '../../../panels/Logo'
import {SubsystemEnum} from '../../../../util/constants'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark,
  },
  toolbar: {
    minHeight: 64,
    height: 64,
  },
  caption: {
    color: colors.common.white,
  },
  logo: { height: '40px' },
}))

interface ITopBarProps {
  onMobileNavOpen: () => void
  isMobileNavOpen?: boolean
  className?: string
  subsystem: SubsystemEnum
  [key: string]: any
}

const TopBar: FunctionComponent<ITopBarProps> = ({
  className,
  onMobileNavOpen,
  isMobileNavOpen,
  subsystem,
  ...rest
}) => {
  const classes = useStyles()
  const user = useStore().authStore.authenticatedUser
  const userDetails = useStore().authStore.authenticatedUserDetails
  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          // className={classes.menuButton}
          color="inherit"
          onClick={onMobileNavOpen}
        >
          <SvgIcon fontSize="small">{isMobileNavOpen ? <CloseIcon /> : <MenuIcon />}</SvgIcon>
        </IconButton>

        <RouterLink to={`/${subsystem}/`}>
          <Logo topbar className={classes.logo} />
        </RouterLink>

        <Box ml={2}>
          DataPlatform
          <br />
          <Typography variant="caption" color="textSecondary" className={classes.caption}>
            {process.env.REACT_APP_COPYRIGHT}
          </Typography>
        </Box>

        <Box ml={2} flexGrow={1} className="print-hide" />

        {/* <Search /> */}
        {/* <Contacts /> */}
        {/* <Notifications /> */}
        {/* <Settings /> */}

        {/* <Box ml={2}>
          <Lk />
        </Box> */}

        {user && (
          <>
            <Box ml={2} className="print-hide">
              <Account subsystem={subsystem} />
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
