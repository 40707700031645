import React from 'react'
import { Router } from 'react-router-dom'
import history from 'src/history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createTheme } from 'src/theme'
import Auth from './views/components/Auth'
import Routes from './Routes'
import RootStore from 'src/store/_root'
import { RootContextProvider } from './context/RootStoreContext'
import AlertDialog from './views/modals/AlertDialog'
import InfoDialog from './views/modals/InfoDialog'
import FileSelectDialog from './views/modals/FileSelectDialog'
import ScrollReset from './views/components/ScrollReset'
import moment from 'moment'
import TextFieldDialog from './views/modals/TextFieldDialog'
import 'react-sortable-tree/style.css'
import 'src/views/components/sortabletree-customization/style.css'
import SortableTree from 'react-sortable-tree'
import XlsxUploadDialog from './views/modals/XlsxUploadDialog'
import SelectDialog from './views/modals/SelectDialog'
import './assets/styles/main.scss'
import { ContextMenu } from './views/components/context-menu/ContextMenu'
import { ControllersContext, controllersContextValue } from './context'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  }),
)

const store = new RootStore()

function App() {
  useStyles()

  moment.locale('ru')

  document.title = process.env.REACT_APP_COPYRIGHT

  return (
    <RootContextProvider value={store.getStore}>
      <ControllersContext.Provider value={controllersContextValue}>
        <ThemeProvider theme={createTheme()}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={3}>
                <Auth>
                  <Router history={history}>
                    <AlertDialog />
                    <InfoDialog />
                    <FileSelectDialog />
                    <TextFieldDialog />
                    <SelectDialog />
                    <XlsxUploadDialog />

                    {/*это удивительный костыль, он не рисует ничего видимого, но нужен для какого контекста Drag&Drop */}
                    <SortableTree
                      treeData={[]}
                      onChange={() => {}}
                      style={{ zIndex: -1 /*height: 0, width: 0, padding: 0, margin: 0*/ }}
                    />

                    <ScrollReset />
                    <Routes />
                  </Router>
                </Auth>
              </SnackbarProvider>
              <ContextMenu />
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ControllersContext.Provider>
    </RootContextProvider>
  )
}

export default App
