import IUserBriefDto from './IUserBriefDto'
import IFormBriefDto from '../form/IFormBriefDto'
import ISupplierBriefDto from '../supplier/ISupplierBriefDto'
import IUserGroupBriefWithPathDto from '../usergroup/IUserGroupBriefWithPathDto'

export default interface IUserFullDto extends IUserBriefDto {
    adminGroups: IUserGroupBriefWithPathDto[];
    userGroups: IUserGroupBriefWithPathDto[];
    observerGroups: IUserGroupBriefWithPathDto[];
    adminForms: IFormBriefDto[];
    userForms: IFormBriefDto[];

    options?: any; // map<string, any>

    // front only
    admin?: boolean;
    observer?: boolean;
}

export interface IAdminPrivileges {
    isRootSupplierAdmin?: boolean;
    isSysAdmin?: boolean;
    isFormAdmin?: boolean;
}

export function isFormAdmin(userInfo: IUserFullDto, formRootSupplier: ISupplierBriefDto): boolean {
  return userInfo && formRootSupplier && formRootSupplier.userGroup && userInfo.adminGroups &&
        ( !!userInfo.adminGroups.find(x => x.id === formRootSupplier.userGroup.id) ||
          !!userInfo.userGroups.find(x => x.id === formRootSupplier.userGroup.id)
        )
}

export function isRootSupplierAdminForForm(userInfo: IUserFullDto, formRootSupplier: ISupplierBriefDto): boolean {
  return userInfo && formRootSupplier && formRootSupplier.userGroup && userInfo.adminGroups &&
        ( !!userInfo.adminGroups.find(x => x.id === formRootSupplier.userGroup.id) )
}

export function adminPrivilegesFor(userInfo: IUserFullDto, formRootSupplier: ISupplierBriefDto, mySupplier: ISupplierBriefDto): IAdminPrivileges {
  try {
    const isRootSupplierAdmin = isRootSupplierAdminForForm(userInfo, formRootSupplier)
    const isSysAdmin = userInfo && userInfo.roles && userInfo.roles.includes('ADMIN')
    const formAdmin = isSysAdmin || isRootSupplierAdmin || isFormAdmin(userInfo, formRootSupplier)
    return {
      isSysAdmin: isSysAdmin,
      isRootSupplierAdmin: isRootSupplierAdmin,
      isFormAdmin: formAdmin,
    }
  } catch (e) {
    return {}
  }
}

export function isMemberOfUserGroup(userInfo: IUserFullDto, userGroupId: number): boolean {
  if (!userInfo) return false
  let res = false;
  [userInfo.userGroups, userInfo.adminGroups, userInfo.observerGroups].forEach(ugList => {
    if (!res && ugList && ugList.find(x => x.id === userGroupId)) res = true
  })
  return res
}