import IColumnDescDto from '../columndesc/IColumnDescDto'
import IFormBriefDto from '../form/IFormBriefDto'
import IReportBriefDto from '../report/IReportBriefDto'
import IReportDto from '../report/IReportDto'

export default interface IGismapColumnDescDto {
    virtualKey: string;
    form: IFormBriefDto;
    report: IReportDto;
    columnDesc: IColumnDescDto;
    child?: IGismapColumnDescDto;
    maplibreStyle;
    main?: boolean;
}

export function flatten(v: IGismapColumnDescDto): IGismapColumnDescDto[] {
  const res: IGismapColumnDescDto[] = []
  function fn(x){
    if (!x) return
    res.push({...x, child: null})
    fn(x.child)
  }
  fn(v)
  return res
}

export function leafGC(gc: IGismapColumnDescDto): IGismapColumnDescDto {
  if (!gc) return gc
  if (!gc.child) return gc
  return leafGC(gc.child)
}