import { action, makeObservable, observable, runInAction } from 'mobx'
import IPssRequestDto from '../../dto/request/IPssRequestDto'
import IPageDto from '../../dto/common/IPageDto'
import { IResponse, message } from '../../api/common/ServerApi'
import LoadableStore from './ILoadableStore'
import IRootStore from '../_root/type'

// T - ResponseDTO type, R - requestOptionsType
abstract class BaseListStore<T, R extends IPssRequestDto> extends LoadableStore {
  page: IPageDto<T>
  requestOptions: R
  selectedItems: any[] = []

  protected abstract getDefaultRequestOptions(): R

  constructor(root: () => IRootStore) {
    super(root)
    makeObservable(this, {
      page: observable,
      requestOptions: observable,
      selectedItems: observable,
    })
  }

  protected async deleteManyRoutine(ids: number[] | string[]): Promise<IResponse> {
    return { success: true }
  }
  protected async deleteOneRoutine(id: number | string): Promise<IResponse> {
    return { success: true }
  }
  protected async getListRoutine(apiRequestOptions: IPssRequestDto): Promise<IResponse> {
    return { success: true }
  }

  protected beforeInit() {
    console.debug('BaseListStore::beforeInit')
    this.initialized = false
    this.page = null
    this.loadingError = null
  }

  public async initialize(initObj?: any): Promise<IResponse> {
    this.beforeInit()
    // sync synhro
    this.requestOptions = this.getDefaultRequestOptions()

    //await this.loadDics();
    const resp = await this.load(this.requestOptions)
    this.initialized = true
    return resp
  }

  loadDics = async (): Promise<void> => {}

  deleteMany = async (ids: number[]): Promise<IResponse> => {
    this.isLoading = true
    const response = await this.deleteManyRoutine(ids)
    if (response.success) {
      this.loadingError = null
    } else {
      this.loadingError = message(response)
    }
    await this.load(this.requestOptions)
    this.isLoading = false
    return response
  }

  deleteOne = async (id: number): Promise<IResponse> => {
    this.isLoading = true
    const response = await this.deleteOneRoutine(id)
    if (response.success) {
      this.loadingError = null
    } else {
      this.loadingError = message(response)
    }
    await this.load(this.requestOptions)
    this.isLoading = false
    return response
  }

  load = async (requestOptions: R): Promise<IResponse> => {
    return this.loadList(requestOptions)
  }

  protected loadList = async (requestOptions: R, options?: any): Promise<IResponse> => {
    runInAction(() => {
      this.requestOptions = requestOptions
      this.isLoading = true
    })
    const response = await this.getListRoutine(requestOptions)
    runInAction(() => {
      if (response.success) {
        this.loadingError = null
        this.page = response.data
        this.selectedItems = []
      } else {
        this.loadingError = message(response)
      }
      this.isLoading = false
    })
    return response
  }
}

export default BaseListStore
