import ServerApi, {IResponse} from "./common/ServerApi";
import IGeodataUploadDto from "../dto/gismap/IGeodataUploadDto";
import IGeodataUploadPreviewDto from "../dto/geoimpexp/IGeodataUploadPreviewDto";
import IUpdateResultDto from "../dto/data/IUpdateResultDto";
import {mapToAny} from "../util/str-util";

export class GeoImportApi {
  static readonly baseURL = 'geoimport'

  static async uploadFile(file: File, onProgress): Promise<IResponse<IGeodataUploadPreviewDto>> {
    const fd = new FormData()
    fd.append('file', file)
    return ServerApi.postQuery(`${this.baseURL}/uploadFile`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    })
  }

  static async uploadData(requestDto: IGeodataUploadDto): Promise<IResponse<IUpdateResultDto>> {
    return ServerApi.postQuery(`${this.baseURL}/uploadData`, {...requestDto,
      fieldCorrespondence: mapToAny(requestDto.fieldCorrespondence) || {}
    })
  }

}
