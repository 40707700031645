import IReportBaseDataRequestDto from './IReportBaseDataRequestDto'

export default class IReportDicItemsRequestDto extends IReportBaseDataRequestDto {
  parentId: number
  includeChilds: boolean
  descOnly?: boolean
  requestingColumnId?: number
  excludeNodeId?: number

  constructor() {
    super()
    this.pageSize = 25
    this.sort = [
      { columnName: 'code', asc: true },
      //{ columnName: 'name', asc: true },
    ]
    this.parentId = null
    this.includeChilds = true
  }

}
