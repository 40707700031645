import IPssRequestDto from './IPssRequestDto'

export default class IUserListRequestDto extends IPssRequestDto {
  locked?: boolean = null
  role?: string = null
  hasApiKey?: boolean = null
  hasExternalId?: boolean = null
  esiaApproved?: boolean = null

  constructor() {
    super()
    this.pageSize = 25
    this.sort = [
      { columnName: 'id', asc: false },
    ]
  }

}
