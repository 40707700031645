import IPssRequestDto from '../request/IPssRequestDto'
import {DIC_REQUEST_MODE} from '../../enums/DIC_REQUEST_MODE'

// Pss - Paging, Search, Sort
export default class IReportBaseDataRequestDto extends IPssRequestDto{
  reportId: number
  formId?: number
  dicRequestMode?: DIC_REQUEST_MODE
  includeReferrerFields?: boolean
  includeRowInfoAsColumns?: boolean

  constructor() {
    super()
  }
}
