import { computed, makeAutoObservable } from 'mobx' // eslint-disable-line

import {
  AnyLayerListReport,
  ExtLayerListReport,
  GeoTiffLayerListReport,
  LayerListReport,
  RegisterLayerListReport,
} from './types/index'
import IReportGisInfo from 'src/dto/gismap/IReportGisInfo'

export class LayerListStore {
  id: number
  reportGisInfo: IReportGisInfo[] = []
  availableLayers: LayerListReport[] = []
  _currentLayer: RegisterLayerListReport
  hotReportIds: number[] = []
  hotExtAndTiffsSourceIds: string[] = []
  //@observable geoTiffs: IGeoTiffBriefDto[] = []

  availableLayersIsLoading = false
  currentLayerIsLoading = false
  geoTiffsIsLoading = false
  externalLayersIsLoading = false
  searchString = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  private get registerLayers() {
    return this.availableLayers.filter(
      ({ type }: AnyLayerListReport) => type === 'register',
    ) as RegisterLayerListReport[]
  }

  @computed get isLoading() {
    return (
      this.availableLayersIsLoading ||
      this.currentLayerIsLoading ||
      this.geoTiffsIsLoading ||
      this.externalLayersIsLoading
    )
  }

  setAvailableLayers(layers: LayerListReport[]) {
    this.availableLayers = layers
  }

  setCurrentLayer(layer: RegisterLayerListReport) {
    this._currentLayer = layer
  }

  @computed get currentLayer(): LayerListReport {
    return { ...this._currentLayer, type: 'register' }
  }

  get coldRegisterLayers() {
    const currentLayer = this.currentLayer as RegisterLayerListReport
    return this.registerLayers
      .filter(({ report }) => report.id !== currentLayer?.report?.id)
      .filter(({ report }) => !this.hotReportIds.includes(report.id))
  }

  get coldExtAndGeotiffs() {
    const external = (
      this.extAndGeotiffs.filter(({ type }) => type === 'external') as ExtLayerListReport[]
    )
      .reduce<ExtLayerListReport['items']>((acc, { items }) => [...acc, ...items], [])
      .filter(({ sourceId }) => !this.hotExtAndTiffsSourceIds.includes(sourceId))
      .map(({ externalLayer, sourceId }) => ({ sourceId, title: externalLayer.title }))

    const geotiff = (
      this.extAndGeotiffs.filter(({ type }) => type === 'geotiff') as GeoTiffLayerListReport[]
    )
      .reduce<GeoTiffLayerListReport['items']>((acc, { items }) => [...acc, ...items], [])
      .filter(({ sourceId }) => !this.hotExtAndTiffsSourceIds.includes(sourceId))
      .map(({ geoTiff, sourceId }) => ({ sourceId, title: geoTiff.title }))
    return [...external, ...geotiff]
  }

  get extAndGeotiffs() {
    const external = this.availableLayers.filter(
      ({ type }) => type === 'external',
    ) as ExtLayerListReport[]

    const geotiff = (
      this.availableLayers.filter(({ type }) => type === 'geotiff') as GeoTiffLayerListReport[]
    ).map(tiffLayer => {
      const items = tiffLayer.items.map(item => ({
        ...item,
        geoTiff: { ...item.geoTiff, title: `(Geotiff) ${item.geoTiff.title}` },
      }))
      return { ...tiffLayer, items }
    })
    return [...external, ...geotiff] as AnyLayerListReport[]
  }

  get filteredLayers() {
    return this.hotRegisterLayers.filter(({ form }) =>
      form.tableTitle.toLowerCase().includes(this.searchString.toLowerCase()),
    )
  }

  get filteredExtAndTiffsLayers() {
    const external = (
      this.extAndGeotiffs.filter(({ type }) => type === 'external') as ExtLayerListReport[]
    ).map(extLayer => {
      const items = extLayer.items
        .filter(({ sourceId }) => this.hotExtAndTiffsSourceIds.includes(sourceId))
        .filter(({ externalLayer }) =>
          externalLayer.title.toLowerCase().includes(this.searchString.toLowerCase()),
        )
      return { ...extLayer, items }
    })

    const geotiff = (
      this.extAndGeotiffs.filter(({ type }) => type === 'geotiff') as GeoTiffLayerListReport[]
    ).map(tiffLayer => {
      const items = tiffLayer.items
        .filter(({ sourceId }) => this.hotExtAndTiffsSourceIds.includes(sourceId))
        .filter(({ geoTiff }) =>
          geoTiff.title.toLowerCase().includes(this.searchString.toLowerCase()),
        )
      return { ...tiffLayer, items }
    })
    return [...external, ...geotiff]
  }

  get hotExtAndGeotiffs() {
    const external = (
      this.extAndGeotiffs.filter(({ type }) => type === 'external') as ExtLayerListReport[]
    )
      .reduce<ExtLayerListReport['items']>((acc, { items }) => [...acc, ...items], [])
      .filter(({ sourceId }) => this.hotExtAndTiffsSourceIds.includes(sourceId))
      .map(({ externalLayer, sourceId }) => ({ sourceId, title: externalLayer.title }))

    const geotiff = (
      this.extAndGeotiffs.filter(({ type }) => type === 'geotiff') as GeoTiffLayerListReport[]
    )
      .reduce<GeoTiffLayerListReport['items']>((acc, { items }) => [...acc, ...items], [])
      .filter(({ sourceId }) => this.hotExtAndTiffsSourceIds.includes(sourceId))
      .map(({ geoTiff, sourceId }) => ({ sourceId, title: geoTiff.title }))
    return [...external, ...geotiff]
  }

  get hotRegisterLayers() {
    const currentLayer = this.currentLayer as RegisterLayerListReport
    return this.registerLayers
      .filter(({ report }) => report.id !== currentLayer?.report?.id)
      .filter(({ report }) => this.hotReportIds.includes(report.id))
  }

  reset() {
    this.availableLayers = []
    this.availableLayersIsLoading = false
    this._currentLayer = undefined
    this.currentLayerIsLoading = false
    this.hotReportIds = []
    this.hotExtAndTiffsSourceIds = []
    this.searchString = ''
  }

  /*@action setGeoTiffs(geoTiffs: IGeoTiffBriefDto[]) {
		this.geoTiffs = geoTiffs
	}*/
}
