import React, { FunctionComponent } from 'react'

interface ILogoProps {
  className?: string
  topbar?: boolean
}

const Logo: FunctionComponent<ILogoProps> = ({ topbar, ...props }) => {
  return <img alt="СХУД" src={'/img/spellsystemlogo.png'} {...props} />
}

export default React.memo(Logo)
