import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { Feature, FeatureCollection, Geometry, LineString, Point, Polygon, Position } from 'geojson'
import { GeoCadastrApi } from 'src/api/GeoCadastrApi'

import IDataRowReadFullDto from 'src/dto/data/IDataRowReadFullDto'
import { POSTGIS_SHAPE } from 'src/enums/POSTGIS_SHAPE'
import { DrawStore } from 'src/store/map-store'
import { DrawStoreItem, FeatureType, GeometryType, SelectedPoint } from 'src/store/map-store/types'

/** Сервис хранилища контекстов рисования на карте */
export class DrawStoreService {
  private readonly repository: DrawStore
  private readonly availableFeatureTypes = ['LineString', 'Point', 'Polygon']

  /**
	 * Создаёт сервис хранилища рисавания на карте
	 * @param {DrawStore} drawStore - хранилище рисования на карте
	 */
  constructor(drawStore: DrawStore) {
    this.repository = drawStore
  }

  async fetchGeocadastr(cadastralNumber: string) {
    const { geojson } = await GeoCadastrApi.getObjectDetails({ cadastralNumber })
    try {
      return JSON.parse(geojson) as Geometry
    } catch (e) {
      throw new Error('Не удалось получить геометрию')
    }
  }

  /**
	 * Добавляет геометрии рисования в хранилище
	 * @param items - контексты рисования
	 */
  setItems(items: DrawStoreItem[]) {
    for (const i in items) {
      this.addItem(items[i])
    }
  }

  /**
	 * Задаёт фигуры в контексте рисования
	 * @param features - геометрия фигур рисования
	 */
  setManagerFeatures(features: FeatureCollection) {
    this.repository.manager?.set(features)
  }

  /**
	 * Устанавливает сслыку на Drawcontrol
	 * @param manager - DrawControl
	 */
  setDrawManager(manager: MapboxDraw) {
    this.repository.manager = this.repository.manager || manager
  }

  /**
	 * Получает режим Mapbox-draw по типу геометрии 1.0
	 * @param shape - тип геометрии
	 * @returns режим Mapbox-draw
	 */
  getMapboxDrawModeByPostGisShape(shape: POSTGIS_SHAPE): MapboxDraw.DrawMode | undefined {
    if (shape === 'LINE') {
      return 'draw_line_string'
    }
    if (shape === 'POINT') {
      return 'draw_point'
    }
    if (shape === 'POLYGON') {
      return 'draw_polygon'
    }
    if (shape === 'GEOMETRY') {
      console.warn('Смешанный тип геометрии не поддерживается')
    }
    return null
  }

  /**
	 * Начать рисовать 1.1.
	 * @param mode - режим рисования
	 */
  startManagerDraw(mode: MapboxDraw.DrawMode): MapboxDraw {
    return this.repository.manager?.changeMode(mode.toString())
  }

  /**
	 * Получить последнюю фичу менеджера 1.2.
	 */
  getManagerLastFeature(manager: MapboxDraw): FeatureType | undefined {
    const { features } = manager.getAll()
    const feature = features[features.length - 1]
    if (this.availableFeatureTypes.includes(feature.geometry.type)) {
      return feature as FeatureType
    }
  }

  /**
	 * Создаёт drawItem на осонове фичи рисования 1.3.
	 * @param columndId - колонка к которой привязать drawItem, позденее будет не нужна;
	 * @param feature - фича рисования
	 */
  createDrawItem(columnId: string | number, feature: FeatureType): DrawStoreItem {
    return { columnId, feature }
  }

  /**
	 * Добавляет геометрию рисования в хранилище 1.4.
	 * @param {DrawStoreItem} item - контекст рисования
	 */
  addItem(item: DrawStoreItem) {
    const existsInRepository = this.repository.items.some(({ feature }) => feature.id === item.feature.id)
    if (!existsInRepository) {
      this.repository.items.push(item)
    }
  }

  /** Получает набор геометрий из контекста рисования 2.1. */
  getManagerFeatures(): FeatureType[] {
    const featureCollection = this.repository.manager?.getAll()
    const result = featureCollection?.features.filter(({ geometry }) => this.availableFeatureTypes.includes(geometry.type)) || []
    return result as FeatureType[]
  }

  /**
	 * Ищет drawStoreItem по featureId 2.2.
	 * @param {string} featureId - идентификатор фичи
	 * @returns
	 */
  getItemByFeatureId(featureId: string) {
    return this.repository.items.find(({ feature }) => feature.id === featureId)
  }

  /**
	 * Устанавливает фичу в drawStoreItem 2.3.
	 * @param item - drawStoreItem
	 * @param feature - фича рисования
	 */
  setItemFeature(item: DrawStoreItem, feature: FeatureType) {
    const items = [...this.repository.items]

    const index = this.repository.items.findIndex(({ feature }) => (feature.id === item.feature.id))
    if (index === -1) {
      return
    }

    items.splice(index, 1, { ...item, feature })
    this.repository.items = items
  }

  /** Получает одну фичу, находящуюся в фокусе 3.1. */
  getManagerSelectedFeature() {
    return this.repository.manager?.getSelected()?.features[0]
  }

  /** Устанавливает выбранную колонку 3.2. */
  setSlectedId(id: string | number | undefined) {
    this.repository.selectedItemId = id
  }

  /** Получает выбранную точку на фигуре рисования 3.3. */
  getManagerSelectedPoint(): Point | undefined {
    const selectedPoints = this.repository.manager?.getSelectedPoints()
    const geometry = selectedPoints?.features[0]?.geometry
    if (geometry?.type === 'Point') {
      return geometry
    }
  }

  /**
	 * Устанавливает выбранную точку 3.4.
	 * @param point - выбранная точка;
	 * @param feature - фича, в которой выбрана точка;
	 */
  setSelectedPoint(point: Point | undefined, feature?: Feature) {
    if (point && feature?.id) {
      const featureId = feature.id
      this.repository.selectedPoint = { featureId, point }
    } else {
      this.repository.selectedPoint = undefined
    }
  }

  /**
	 * Устанавливает выбранную фичу 3.5.
	 * @param feature - выбранна фича;
	 */
  setSelectedFeatureId(featureId: string | number | undefined) {
    this.repository.selectedFeatureId = featureId
  }

  /**
	 * Поучает все drawStoreItem относящиеся к колонке 4.1.
	 * @param columnId - колонка, для которой нужно забрать drawItems
	 * @returns {DrawStoreItem} = массив drawStoreItem
	 */
  getItemsByColumnId(columnId: string | number) {
    return this.repository.items.filter((item) => item.columnId.toString() === columnId.toString())
  }

  /**
	 * Создаёт FeatureCollection из фичей drawStoreItems 4.2.
	 * @param items - массив drawStoreItem, подлежащих преобразованию
	 * @returns {FeatureCollection}
	 */
  featureCollectionFromDrawStoreItems(items: DrawStoreItem[]): FeatureCollection {
    const features = items.map(({ feature }) => feature)
    return { features, type: 'FeatureCollection' }
  }

  /**
	 * Получает featureCollection из геоколонки деталей строки 5.1.
	 * @param item - детальная информация о строке отчёта;
	 * @param columndId - идентификатор геоколонки
	 * @returns
	 */
  getGeometryFromItemDetails(item: IDataRowReadFullDto, columndId: number): Geometry | null {
    if (!item) {
      return null
    }

    const prevSymbols = [',', '.', ':', '!', '[', '(', '{']
    const nextSymbols = [',', '.', ':', '!', ']', ')', '}']

    const { values } = item
    const str = values[columndId] as string
    if (!str) {
      return null
    }

    const value = str.split('').reduce<string>((acc, item, index, arr) => {
      const invalid = item === ',' && (prevSymbols.includes(arr[index - 1]) || nextSymbols.includes(arr[index + 1]))
      return invalid ? acc : acc + item
    }, '')
    try {
      const parsed = JSON.parse(value)
      if (!parsed) {
        throw new Error()
      }
      if (!parsed.type || !parsed.coordinates) {
        throw new Error()
      }
      return parsed as Geometry
    } catch (e) {
      console.log(e)
      return null
    }
  }

  /**
	 * Преобразует мультигеометрию в массив сингл геометрий 5.2.
	 * @param geometry - преобразуемая геометрия;
	 */
  breakMultyGeometryToGeometryType(geometry: Geometry): GeometryType[] {
    if (geometry.type === 'MultiPoint') {
      return geometry.coordinates.map<Point>((coordinates) => ({ coordinates, type: 'Point' }))
    }
    if (geometry.type === 'MultiLineString') {
      return geometry.coordinates.map<LineString>((coordinates) => ({ coordinates, type: 'LineString' }))
    }
    if (geometry.type === 'MultiPolygon') {
      return geometry.coordinates.map<Polygon>((coordinates) => ({ coordinates, type: 'Polygon' }))
    }
    if (this.availableFeatureTypes.includes(geometry.type)) {
      return [geometry] as GeometryType[]
    }
    if (geometry.type === 'Point' || geometry.type === 'LineString' || geometry.type === 'Polygon') {
      return [geometry]
    }
    return []
  }

  /**
	 * Добавляет фичу в контекст рисования 5.3.
	 * @param feature доавляемая фича
	 */
  addManagerFeature(feature: FeatureType) {
    this.repository.manager?.add(feature)
  }

  /** Получает выбранную точку из стора 6.1. */
  getSelectedPoint() {
    return this.repository.selectedPoint
  }

  /**
	 * Удаляет координаты из фичи MapboxDraw 6.2.
	 * @param point - точка, содержащая удаляемые координаты;
	 * @returns - обновленная фича из MapboxDraw.
	 */
  removeMapboxFeaturePoint({ point, featureId }: SelectedPoint) {
    // проверяет координаты на совпадение;
    function isMatched([x, y]: Position) {
      return x === point.coordinates[0] && y === point.coordinates[1]
    }

    // предпологаем, что фича имеет тип FeatureType$
    const feature = this.repository.manager.get(featureId.toString()) as FeatureType
    if (!feature) {
      throw new Error('Фича отсутсвует в MapboxDraw')
    }

    // убедились, что фича имеет тип FeatureType;
    const availableFeature = this.availableFeatureTypes.includes(feature.geometry.type)
    if (!availableFeature) {
      throw new Error(`Неожиданный тип фичи: ${feature.geometry.type}`)
    }

    // если фича оказалась точкой;
    if (feature.geometry.type === 'Point') {
      // и она совпала с удаляемой;
      if (isMatched(feature.geometry.coordinates)) {
        // удаляем фичу;
        this.manager.delete(feature.id.toString())
      }
      return
    }

    // если фича оказалась линией;
    if (feature.geometry.type === 'LineString') {
      const coordinates = feature.geometry.coordinates.filter((item) => !isMatched(item))
      const geometry = { ...feature.geometry, coordinates }
      const newFeature = { ...feature, geometry } as FeatureType

      // удаляем фичу;
      this.manager.delete(feature.id.toString())
      if (newFeature.geometry.coordinates.length === 0) {
        return
      }

      // добаляем фичу с тем же id, если в ней еще остались узлы;
      this.manager.add(newFeature)
      return this.manager.get(featureId.toString()) as FeatureType
    }

    // если фича оказалась полигоном;
    if (feature.geometry.type === 'Polygon') {
      const coordinates = feature.geometry.coordinates
        .map((coordinates) => coordinates.filter((item) => !isMatched(item)))
        .filter((item) => item.length > 2)

      const geometry = { ...feature.geometry, coordinates }
      const newFeature = { ...feature, geometry } as FeatureType

      // удаляем фичу;
      this.manager.delete(feature.id.toString())
      if (newFeature.geometry.coordinates.length === 0) {
        return
      }

      // добаляем фичу с тем же id, если в ней еще остались узлы;
      this.manager.add(newFeature)
      return this.manager.get(featureId.toString()) as FeatureType
    }
  }

  /**
	 * Удаляет элемент из хранилища 6.3.
	 * @param item - удалаяемый элемент хранилища;
	 */
  removeDrawStoreItem(item: DrawStoreItem) {
    const index = this.repository.items.findIndex(({ feature }) => feature.id === item.feature.id)
    if (index !== -1) {
      const items = [...this.repository.items]
      items.splice(index, 1)
      this.repository.items = items
    }
  }

  /**
	 * Получает id выбранной фичи 7.1.
	 */
  getSelectedFeatureId() {
    return this.repository.selectedFeatureId
  }

  /**
	 * Удаляет фичу из MapboxDraw 7.2.
	 * @param featureId - id удаляемой фичи;
	 */
  removeFeatureFromMapboxDraw(featureId: string | number) {
    this.repository.manager.delete(featureId.toString())
  }

  /**
	 * Сбрасывает хранилище
	 */
  resetStore() {
    this.repository.reset()
    this.repository.manager?.set({ features: [], type: 'FeatureCollection' })
  }

  get manager() {
    return this.repository.manager
  }
}
