import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from '../_root/RootStoreModel'
import FrontApi from '../../api/common/FrontApi'
import { message } from '../../api/common/ServerApi'
import IRootStore from '../_root/type'

class InfoModalStore extends RootStoreModel {
  open: boolean = false

  title: string
  body: string
  onClose: Function
  hardModal: boolean

  url: string
  isLoading: boolean = false
  htmlContent: string

  element: JSX.Element

  openModal = (title: string, body: string, onClose?: Function, closeOnBackdropClick?: boolean) => {
    if (this.open) {
      this.closeModal()
    }

    this.onClose = onClose
    this.isLoading = false
    this.hardModal = closeOnBackdropClick
    this.url = null
    this.title = title
    this.body = body

    this.open = true
  }

  openModalHtml = (
    title: string,
    body: string,
    onClose?: Function,
    closeOnBackdropClick?: boolean,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.onClose = onClose
    this.isLoading = false
    this.hardModal = closeOnBackdropClick
    this.url = null
    this.title = title
    this.htmlContent = body

    this.open = true
  }

  openModalUrl = (title: string, url: string, closeOnBackdropClick?: boolean) => {
    if (this.open) {
      this.closeModal()
    }

    this.title = title
    this.body = null
    this.url = url
    this.hardModal = closeOnBackdropClick

    this.isLoading = true

    FrontApi.getHtml(url).then(response => {
      if (response.success) {
        this.htmlContent = this.extractBody(response.data)
      } else {
        this.htmlContent = `<h3>Ошибка:</h3><p>${message(response)}</p>`
      }
      this.isLoading = false
    })

    this.open = true
  }

  openModalElement = (
    title: string,
    element: JSX.Element,
    onClose?: Function,
    closeOnBackdropClick?: boolean,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.onClose = onClose
    this.hardModal = closeOnBackdropClick
    this.title = title
    this.element = element

    this.open = true
  }

  closeModal = () => {
    this.open = false
    this.isLoading = false
    this.htmlContent = null
    this.element = null

    this.title = null
    this.body = null
    this.url = null

    const onClose = this.onClose
    this.onClose = null
    if (onClose) onClose()
  }

  extractBody = (html: string): string => {
    return html
  }

  constructor(root: () => IRootStore) {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super(root)

    makeObservable(this, {
      open: observable,
      hardModal: observable,
      title: observable,
      body: observable,
      url: observable,
      isLoading: observable,
      htmlContent: observable,
      element: observable,
      openModal: action,
      openModalHtml: action,
      openModalUrl: action,
      openModalElement: action,
      closeModal: action,
    })
  }
}

export default InfoModalStore
