import {decode, encode} from 'base64-arraybuffer'

console.debug('str-util')

export function searchMatch(str: string, filter: string): boolean {
  if (!filter || !filter.trim()) return true
  if (!str || !str.trim()) return false

  const strL = str.toLowerCase()
  const arr = filter.split(' ')
  return arr.filter(f => strL.indexOf(f.toLowerCase().trim()) >= 0).length == arr.length
}

export function numStrTrunc(v: number): string {
  if (!v) return v + ''
  if (!isFinite(v)) return ''
  let res = (Math.abs(v) >= 1) ? v.toFixed(3) : v.toFixed(6)
  res = res.replace(/0+$/g, '').replace(/\.$/, '')
  return res
}

export function objToBase64RequestString(obj: any): string {
  if (!obj) return null
  try {
    const strToBytesEncoder = new TextEncoder()
    const base64 = encode(strToBytesEncoder.encode(JSON.stringify(obj)))
    return encodeURIComponent(base64)
  } catch (e) {
    return null
  }
}

export function base64RequestStringToObj(str: string): any {
  if (!str) return {}
  try {
    const bytesToStrDecoder = new TextDecoder()
    const base64 = decodeURIComponent(str)
    const bytes = decode(base64)
    return JSON.parse(bytesToStrDecoder.decode(bytes))
  } catch (e) {
    return {}
  }
}

export function normalizeSpaces(s: string) {
  if (!s) return s
  return s.toLowerCase().replace(/\s+/g, ' ')
}

export function searchStringMatches(searchString: string, testedString: string): boolean {
  if (!searchString || !testedString) return null
  searchString = searchString.toLowerCase()
  testedString = testedString.toLowerCase()

  const arr = searchString.split(/\s+/g).filter(x => !!x)
  let lastInd = -1
  for (let i = 0; i < arr.length; i++) {
    const n = testedString.indexOf(arr[i], lastInd)
    if (n < 0) return false
    lastInd = n
  }
  return true
}

class Transliterator {
  private abcCyr = [' ','а','б','в','г','д','е','ё', 'ж','з','и','й','к','л','м','н','о','п','р','с','т','у','ф','х', 'ц', 'ч', 'ш', 'щ',  'ъ','ы','ь','э', 'ю','я', 'А','Б','В','Г','Д','Е','Ё', 'Ж','З','И','Й','К','Л','М','Н','О','П','Р','С','Т','У','Ф','Х', 'Ц', 'Ч', 'Ш',  'Щ', 'Ъ','Ы','Ь','Э','Ю', 'Я']
  private abcLat = ['_','a','b','v','g','d','e','e','zh','z','i','y','k','l','m','n','o','p','r','s','t','u','f','h', 'ts','ch','sh','sch', '','i', '','e','yu','ya','A','B','V','G','D','E','E','Zh','Z','I','Y','K','L','M','N','O','P','R','S','T','U','F','H','Ts','Ch','Sh','Sch', '', 'I', '','E','Yu','Ya',
    'c', 'C', 'j','J', 'q', 'Q', 'w','W', 'x', 'X',
  ]
  private map = {}

  constructor() {
    console.debug('Transliterator')
    for (let i = 0; i < this.abcCyr.length; i++) {
      this.map[this.abcCyr[i]] = this.abcLat[i]
    }
  }

  transliterate = (s: string) => {
    let res = ''
    for (let i = 0; i < s.length; i++) {
      const srcChar = s.charAt(i)
      let destChar = this.map[srcChar]
      if (destChar === undefined) {
        if (srcChar >= '0' && srcChar <= '9') destChar = srcChar
        else if (this.abcLat.find(x => x === srcChar)) destChar = srcChar
        else destChar = ''
      }
      res += destChar
    }
    return res
  }
}

export function capitalizeFirst(s: string): string {
  return s && s[0].toUpperCase() + s.slice(1)
}

export const transliterator = new Transliterator()

export function byteToHex(b: number): string {
  return b.toString(16).padStart(2, '0')
}

export function stringToHex(str: string): string {
  const utf8Encode = new TextEncoder()
  const arr = utf8Encode.encode(str)

  let res = ''
  arr.forEach((value, index, array) => {
    res += byteToHex(value)
  })
  return res
  //return str.split('').map(char => char.charCodeAt(0).toString(16).padStart(2, '0')).join('');
}
export function objToHex(obj: any): string {
  if (!obj) return null
  try {
    return stringToHex(JSON.stringify(obj))
  } catch (e) {
    return null
  }
}

export function mapToAny(map: Map<any, any>): any {
  if (!map) return null
  const obj: any = {}
  map.forEach((v, k, m) => {
    obj[String(k)] = v
  })
  return obj
}
