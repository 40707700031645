import { action, observable, makeObservable } from "mobx"; // eslint-disable-line

import { DrawStoreItem, SelectedPoint } from './types'

export class DrawStore {
  items: DrawStoreItem[] = [];
  selectedFeatureId: string | number | undefined;
  selectedItemId: string | number | undefined; 	// id выбранной колонки;
  selectedPoint: SelectedPoint | undefined = undefined;
  manager?: MapboxDraw

  constructor() {
    makeObservable(this, {
      items: observable,
      selectedFeatureId: observable,
      selectedItemId: observable,
      selectedPoint: observable,
      reset: action
    });
  }

  reset() {
    this.items = []
    this.selectedFeatureId = undefined
    this.selectedItemId = undefined
    this.selectedPoint = undefined
  }

  // получает массив координат featureCollection для колонки;
  getGeometryByColumnId(columnId: string | number) {
    // получаем все drawItems, относящиеся к колонке
    const itemsByColumnId = this.items.filter((item) => item.columnId.toString() === columnId.toString())

    // создаём массив координат;
    const coordinates = itemsByColumnId.map(({ feature }) => {
      if (feature?.geometry?.type === 'Point') {
        return feature?.geometry?.coordinates || []
      } else {
        const coordinates: any[] = feature?.geometry?.coordinates || [[]]
        return coordinates.filter((coord) => {
          return coord[0] !== null
        })
      }
    })
    return coordinates.filter((item) => item.length)
  }
}