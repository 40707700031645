import { action, observable, runInAction, makeObservable } from 'mobx'
import { IResponse } from '../../api/common/ServerApi'
import SupplierApi from '../../api/SupplierApi'
import ISupplierFullDto from '../../dto/supplier/ISupplierFullDto'
import ISupplierBriefDto from '../../dto/supplier/ISupplierBriefDto'
import RootStoreModel from '../_root/RootStoreModel'
import { isRootSupplierAdminForForm } from '../../dto/ancient-mser/IUserFullDto'
import IRootStore from '../_root/type'

class SupplierSelectorStore extends RootStoreModel {
  loadingError: string
  isLoading: boolean

  mySuppliers: ISupplierBriefDto[] = []
  allSuppliers: ISupplierBriefDto[] = []
  selectedSupplier: ISupplierBriefDto

  rootSupplier: ISupplierFullDto

  load = async (formId: number): Promise<IResponse> => {
    console.debug('SupplierSelectorStore::load...')

    this.isLoading = true
    this.loadingError = null

    const resp1 = await SupplierApi.getSuppliersForForm(formId)
    if (resp1.success) {
      runInAction(() => {
        this.mySuppliers = resp1.data || []
        this.selectedSupplier = this.mySuppliers[0]
      })
    } else {
      runInAction(() => {
        this.loadingError = resp1.message
        this.isLoading = false
      })
      return resp1
    }

    const resp2 = await SupplierApi.getFormSupplierTree(formId, false)
    if (!resp2.success) {
      runInAction(() => {
        this.loadingError = resp2.message
        this.isLoading = false
      })
      return resp2
    }

    runInAction(() => {
      this.rootSupplier = resp2.data || null
      this.allSuppliers = this.unnestSpl(this.rootSupplier)
      let isRootSupplierAdmin = false
      const userWithDetails = this.getRootStore().authStore.authenticatedUserDetails
      if (
        userWithDetails &&
        this.rootSupplier &&
        isRootSupplierAdminForForm(userWithDetails, this.rootSupplier)
      ) {
        isRootSupplierAdmin = true
      }
      if (
        this.getRootStore().authStore.isSysAdmin ||
        this.getRootStore().authStore.isObserver ||
        isRootSupplierAdmin
      )
        this.selectedSupplier = this.rootSupplier

      this.isLoading = false
    })

    return resp2
  }

  loadRootSupplierForObserving = async (formId: number): Promise<IResponse> => {
    this.isLoading = true
    this.loadingError = null

    const resp1 = await SupplierApi.getFormRootSupplier(formId, true)
    if (resp1.success) {
      runInAction(() => {
        this.mySuppliers = [resp1.data]
        this.selectedSupplier = this.mySuppliers[0]
        this.selectedSupplier.observer = true
      })
    } else {
      runInAction(() => {
        this.loadingError = resp1.message
        this.isLoading = false
      })
    }
    return resp1
  }

  selectSupplier = (supplier: ISupplierBriefDto) => {
    this.selectedSupplier = supplier
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      isLoading: observable,
      loadingError: observable,

      mySuppliers: observable,
      allSuppliers: observable,
      selectedSupplier: observable,

      selectSupplier: action,
      load: action,
      loadRootSupplierForObserving: action,
    })
  }

  // ------------------
  unnestSpl(supplier: ISupplierFullDto): ISupplierBriefDto[] {
    if (!supplier) return []
    const res: ISupplierFullDto[] = []

    function _unnestSpl(spl: ISupplierFullDto, resArr: ISupplierBriefDto[], level: number) {
      resArr.push(spl as ISupplierBriefDto)
      if (spl.childs) spl.childs.forEach(ch => _unnestSpl(ch, resArr, level + 1))
    }

    _unnestSpl(supplier, res, 0)

    return res
  }
}

export default SupplierSelectorStore
