import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core'
import {observer} from 'mobx-react'
import useStore from '../../context/RootStoreContext'
import {getModalZTop} from '../../util/modal-z-levels'
import {useTheme} from '@material-ui/styles'
import {MscTheme} from '../../theme/type'

function AlertDialog() {
  const theme = useTheme() as MscTheme
  const alertModalStore = useStore().alertModalStore
  const {open, title, body, handleConfirm, handleCancel} = alertModalStore

  const defaultHandleConfirm = () => {
    if (handleConfirm) handleConfirm()
    alertModalStore.closeModal()
  }

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel()
    alertModalStore.closeModal()
  }

  return (
    <Dialog
      open={open}
      style={{zIndex: getModalZTop(theme)}}
      onClose={defaultHandleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
        </Button>
        <Button onClick={defaultHandleConfirm} color="primary" autoFocus>
              OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(AlertDialog)