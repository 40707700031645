import { observable, computed, action, makeObservable } from 'mobx'
import { StyleSpecification } from 'maplibre-gl'
import { IResponse } from '../api/common/ServerApi'
import BaseListStore from './common/BaseListStore'
import { copyAndSpread } from '../util/objects'
import IBackgroundDto from '../dto/gismap/IBackgroundDto'
import MBGeoApi from '../api/MBGeoApi'
import IPageDto from 'src/dto/common/IPageDto'
import IBackgroundListRequestDto from '../dto/request/IBackgroundListRequestDto'
import IRootStore from './_root/type'

class BackgroundListStore extends BaseListStore<IBackgroundDto, IBackgroundListRequestDto> {
  selectedBackgroundId: number | undefined
  private _defaultStyle: StyleSpecification = {
    version: 8,
    sources: {
      background: {
        type: 'raster',
        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
      },
    },
    layers: [
      {
        id: 'background',
        type: 'raster',
        source: 'background',
        minzoom: 0,
        maxzoom: 19,
      },
    ],
    glyphs: '/public/glyphs/{fontstack}/{range}.pbf',
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      selectedBackgroundId: observable,
      availableBackgrounds: computed,
      backgroundStyle: computed,
      setSelectedBackgroundId: action,
      setAvailableBackgrounds: action,
    })
  }

  protected getDefaultRequestOptions(): IBackgroundListRequestDto {
    const res = new IBackgroundListRequestDto()
    res.sort = [{ columnName: 'id', asc: false }]
    return res
  }

  initialize = async (initObj?: any): Promise<IResponse> => {
    this.requestOptions = this.getDefaultRequestOptions()
    if (initObj) this.requestOptions = copyAndSpread(this.requestOptions, initObj)

    const resp = await this.load(this.requestOptions)
    this.initialized = true
    return resp
  }

  private _getBackgroundStyle(background?: IBackgroundDto): StyleSpecification | string {
    const style = background?.style

    if (!style) {
      return this._defaultStyle
    }

    try {
      return JSON.parse(style)
    } catch (e) {
      return style
    }
  }

  protected async getListRoutine(apiRequestOptions: IBackgroundListRequestDto): Promise<IResponse> {
    return MBGeoApi.getBackgrounds(apiRequestOptions)
  }

  protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
    return MBGeoApi.deleteBackgrounds(ids)
  }
  protected async deleteOneRoutine(id: number): Promise<IResponse> {
    return MBGeoApi.deleteBackground(id)
  }

  get availableBackgrounds() {
    return this.page?.content || []
  }

  get backgroundStyle() {
    const background = this.availableBackgrounds.find(
      ({ id }) => id && id === this.selectedBackgroundId,
    )
    return this._getBackgroundStyle(background)
  }

  setSelectedBackgroundId(id: number | undefined) {
    this.selectedBackgroundId = id
  }

  setAvailableBackgrounds(payload: IPageDto<IBackgroundDto>) {
    this.page = payload
  }
}

export default BackgroundListStore
