import { Geometry } from 'geojson'

import { message } from 'src/api/common/ServerApi'
import DataApi from 'src/api/DataApi'
import IColumnDescDto from 'src/dto/columndesc/IColumnDescDto'
import IReportBaseDataRequestDto from 'src/dto/report/IReportBaseDataRequestDto'
import IFilterFieldDto from 'src/dto/request/IFilterFieldDto'
import MyReportDataStore from 'src/store/data/MyReportDataStore'
import { TABLE_VIEW_MODE } from 'src/store/types'
import { copyAndSpread } from 'src/util/objects'

export class MyFormReportService {
  private readonly repository: MyReportDataStore

  constructor(store: MyReportDataStore) {
    this.repository = store
  }

  async getReport(id: number) {
    const resp = await DataApi.getReport(id)
    if (resp.success) {
      const datum = resp.data
      if (datum) {
        return datum
      } else {
        throw new Error('Неизвестная ошибка')
      }
    } else {
      throw new Error(message(resp))
    }
  }

  /**
	 * Детальная инормация о строке отчёта
	 * @param id - идентификатор строки;
	 * @param requestOptions - параметры текущего url;
	 * @returns
	 */
  async getReportItemDetails(id: number, requestOptions: IReportBaseDataRequestDto) {
    const resp = await DataApi.getReportDataWeb(
      copyAndSpread(requestOptions, {
        pageSize: 1,
        pageNumber: 0,
        filter: [{ columnName: 'id', numEq: id } as IFilterFieldDto],
        dontUpdateUiSettings: true,
        geoFormat: 'GEOJSON',
        includeMainGeometry: true,
      }),
    )
    if (resp.success) {
      const datum = resp.data?.content?.[0]
      if (datum) {
        return datum
      } else {
        throw new Error('Неизвестная ошибка')
      }
    } else {
      throw new Error(message(resp))
    }
  }

  getGeoColumnsGeometrySingleTypes(columns: IColumnDescDto[]) {
    function getType(shape: IColumnDescDto['postgisShape']): Geometry['type'] | null {
      if ( shape === 'LINE' ) {
        return 'LineString'
      }
      if ( shape === 'POINT' ) {
        return 'Point'
      }
      if ( shape === 'POLYGON' ) {
        return 'Polygon'
      }
      return null
    }

    return columns.map(({ id, postgisShape }) => {
      const type = getType(postgisShape)
      if ( type ) {
        return { id, type }
      }

      return null
    }).filter(item => !!item)
  }

  setTableViewMode(tableViewMode: TABLE_VIEW_MODE) {
    this.repository.tableViewMode = tableViewMode
  }

  resetStore() {
    this.repository.report = null
    this.repository.form = null
    this.repository.page = null
    this.repository.tableViewMode = 'half'
  }
}
