import { API_BASE_URL } from '../../config'
import axios, { AxiosRequestConfig } from '../../util/axios'
import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'
import { cleanFileName } from '../../util/file-util'

const BASE_URL = `${API_BASE_URL}`

export const LOCAL_STORAGE_ACCESS_TOKEN_NAME = 'access-token'
export const LOCAL_STORAGE_REFRESH_TOKEN_NAME = 'refresh-token'
export const LOCAL_STORAGE_CURRENT_USER = 'current-user'
export const LOCAL_STORAGE_CURRENT_USER_GROUP = 'current-user-group'

export interface IErrorResponse {
  code: number
  message: string
  details: any
}

export interface IAuthTokenDto {
  accessToken: string
  refreshToken: string
  tokenType: string
}

export function message(response: IResponse) {
  if (typeof response.data === 'string') {
    let res = response.data.toString().substr(0, 500)
    if (!res) res = 'Ошибка'
    return res
  } else if (typeof response.data === 'object' && response.status === 400) {
    let res = 'Валидация не пройдена'
    if (response.message) res += `: ${response.message}`
    return res
  } else {
    let res = response.message
    if (!res) res = 'Ошибка'
    return res
  }
}

export interface IResponse<T = any> {
  success: boolean
  status?: number
  data?: T
  message?: string
  details?: any
  contentType?: string
}

export default class ServerApi {
  static onUpdateToken: () => void

  static async getQuery<T>(uri: string, config?: AxiosRequestConfig): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0,
    }
    try {
      const resp = await axios.get<T>(`${BASE_URL}${uri}`, config)
      result.success = true
      result.status = resp.status
      result.data = resp.data
      result.contentType = resp.headers['content-type']
      return result
    } catch (e) {
      console.debug('GetQuery exception')

      return ServerApi.handleError(e, result, async () => {
        return ServerApi.getQuery(uri, config)
      })
    }
  }

  static async postQuery<T>(
    uri: string,
    body: any,
    config?: AxiosRequestConfig,
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0,
      data: null,
    }
    try {
      const resp = await axios.post(`${BASE_URL}${uri}`, body, config)
      result.success = true
      result.status = resp.status
      result.data = resp.data
      result.contentType = resp.headers['content-type']
      return result
    } catch (e) {
      console.debug('PostQuery exception')

      return ServerApi.handleError(e, result, async () => {
        return ServerApi.postQuery(uri, body, config)
      })
    }
  }

  static async downloadFileQuery(
    uri: string,
    saveName: string,
    params: any,
    body?: any,
    method?: 'GET' | 'POST',
    onProgress?: (ev) => void,
  ): Promise<IResponse> {
    console.debug('downloadFileQuery')
    try {
      const response = await axios({
        url: `${BASE_URL}${uri}`,
        method: method ? method : 'GET',
        responseType: 'blob', // important
        params: params,
        data: body,
        onDownloadProgress: onProgress,
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      let sn: string
      try {
        sn = response.headers['content-disposition'].split('filename=')[1]
        const buffer = new Buffer(sn, 'latin1')
        sn = new TextDecoder().decode(buffer)
      } catch (e) {
        sn = saveName
      }
      sn = cleanFileName(sn)

      link.setAttribute('download', sn)
      document.body.appendChild(link)
      link.click()

      return { success: true }
    } catch (e) {
      return ServerApi.handleError(e, { success: false }, async () => {
        return ServerApi.downloadFileQuery(uri, saveName, params, body, method)
      })
    }
  }

  static async uploadFileQuery<T>(
    uri: string,
    file: File,
    params?: { name: string; value: string }[],
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0,
      data: null,
    }
    const fd = new FormData()
    fd.append('file', file)
    if (params)
      params.forEach(p => {
        fd.append(p.name, p.value)
      })
    try {
      const resp = await axios.post(`${BASE_URL}${uri}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      result.success = true
      result.status = resp.status
      result.data = resp.data
      result.contentType = resp.headers['content-type']
      return result
    } catch (error) {
      return ServerApi.handleError(error, result, async () => {
        return ServerApi.uploadFileQuery(uri, file)
      })
    }
  }
  // ------------------------

  private static async handleError<T>(
    error: AxiosError,
    result: IResponse<T>,
    callback: () => Promise<IResponse<T>>,
  ): Promise<IResponse<T>> {
    const res = result
    if (error.response) {
      if (error.response.status === 401) {
        if (await this.refreshTokens()) return callback()
      }
      if (error.response.data) {
        const data = error.response.data as IErrorResponse
        res.data = error.response.data
        if (res.data instanceof Blob) res.message = await res.data.text()
        res.status = data.code || error.response.status
        if (data.message) res.message = data.message
        res.details = data.details
      } else {
        res.status = error.response.status
        res.message = error.response.statusText
      }
    } else if (error.request) {
      res.message = 'Сервис недоступен'
    }
    return res
  }

  static async refreshTokens(callOnUpdate = true): Promise<boolean> {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME)
    if (refreshToken) {
      try {
        const resp = await axios.post(`${BASE_URL}auth/refreshAuthorizeTokens`, {
          refreshToken,
        })
        const data = resp.data as IAuthTokenDto
        this.updateTokenValues(data.accessToken, data.refreshToken)
        if (callOnUpdate) this.onUpdateToken()
        return true
      } catch (error) {
        this.updateTokenValues()
        if (callOnUpdate) this.onUpdateToken()
      }
    } else {
      this.updateTokenValues()
      if (callOnUpdate) this.onUpdateToken()
    }
    return false
  }

  static updateTokenValues(token?: string, refreshToken?: string): void {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME, token)
      axios.defaults.headers.common.Authorization = /*null;*/ `Bearer ${token}`
    } else {
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME)
      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER)
    }
    if (refreshToken) localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken)
    else localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME)
  }

  static async initialize(onUpdateToken: () => void): Promise<void> {
    this.onUpdateToken = onUpdateToken

    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME)
    if (accessToken) {
      const decoded = jwtDecode(accessToken) as any
      const currentTime = Date.now() / 1000
      if (decoded.exp > currentTime)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      else await this.refreshTokens(false)
    }
  }
}
