import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from '../_root/RootStoreModel'
import IRootStore from '../_root/type'

class MoveUpModalStore extends RootStoreModel {
  open: boolean = false

  handleConfirmWithCsp: Function
  handleConfirmWithoutCsp: Function
  handleCancel?: Function

  openModal = (
    handleConfirmWithCsp: Function,
    handleConfirmWithoutCsp: Function,
    handleCancel?: Function,
  ) => {
    console.debug('MoveUpModalStore.openModal')

    if (this.open) {
      this.closeModal()
    }

    this.handleConfirmWithCsp = handleConfirmWithCsp
    this.handleConfirmWithoutCsp = handleConfirmWithoutCsp
    this.handleCancel = handleCancel

    this.open = true
  }

  closeModal = () => {
    this.open = false

    this.handleConfirmWithCsp = null
    this.handleConfirmWithoutCsp = null
    this.handleCancel = null
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      handleConfirmWithCsp: observable,
      handleConfirmWithoutCsp: observable,
      openModal: action,
      closeModal: action,
    })
  }
}

export default MoveUpModalStore
