import { action, observable, makeObservable } from 'mobx'
import FormListStore from '../FormListStore'
import IFormBriefDto from '../../dto/form/IFormBriefDto'
import { IResponse } from '../../api/common/ServerApi'
import IFormListRequestDto from '../../dto/request/IFormListRequestDto'
import { copyAndSpread } from '../../util/objects'
import IRootStore from '../_root/type'

class FormSelectorStore extends FormListStore {
  open: boolean
  multiSelect: boolean
  selectedForms: IFormBriefDto[] = []

  onSelect: (values: IFormBriefDto[]) => Promise<boolean>

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      multiSelect: observable,
      selectedForms: observable,
      openModal: action,
      closeModal: action,
      setSelectedForms: action,
      handleSelectForm: action,
      handleDeselectForm: action,
    })
  }

  protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
    return // не нужно
  }

  protected async deleteOneRoutine(id: number): Promise<IResponse> {
    return // не нужно
  }

  //-------------

  openModal = (
    requestOptions: IFormListRequestDto,
    initialValues: IFormBriefDto[],
    multiSelect: boolean,
    onSelect: (values: IFormBriefDto[]) => Promise<boolean>,
  ) => {
    this.open = true
    this.multiSelect = multiSelect
    this.selectedForms = initialValues || []
    this.onSelect = onSelect

    this.requestOptions = this.getDefaultRequestOptions()
    if (requestOptions) {
      this.load(
        copyAndSpread(requestOptions, {
          sort: this.requestOptions.sort,
          filter: this.requestOptions.filter,
          pageSize: this.requestOptions.pageSize,
          pageNumber: this.requestOptions.pageNumber,
        }),
      )
    }

    this.load(this.requestOptions)
  }

  closeModal = () => {
    this.open = false
    this.selectedForms = []
    this.onSelect = null
  }

  setSelectedForms = (forms: IFormBriefDto[]) => {
    this.selectedForms = forms || []
  }

  handleSelectForm = (item: IFormBriefDto) => {
    if (this.multiSelect) {
      if (!this.selectedForms.find(x => x.id === item.id)) {
        const newArr = this.multiSelect ? this.selectedForms.concat(item) : [item]
        newArr.sort((x1, x2) =>
          x1.tableTitle < x2.tableTitle ? -1 : x1.tableTitle > x2.tableTitle ? 1 : 0,
        )
        this.selectedForms = newArr
      }
    } else {
      this.selectedForms = [item]
    }
  }

  handleDeselectForm = (item: IFormBriefDto) => {
    const newArr = this.selectedForms.filter(x => x.id !== item.id)
    this.selectedForms = newArr
  }
}

export default FormSelectorStore
