import { colorsJSON } from 'src/config/colors'

const colors = JSON.parse(colorsJSON)

function getContrastRgb(color: string) {
  const r = parseInt(color.substring(1, 2), 16)
  const g = parseInt(color.substring(3, 2), 16)
  const b = parseInt(color.substring(5, 2), 16)

  const red = Math.abs(Math.round(((255 - r) / (255 + r)) * 255))
    .toString(16)
    .padStart(2, '0')
    .toUpperCase()
  const green = Math.abs(Math.round(((255 - g) / (255 + g)) * 255))
    .toString(16)
    .padStart(2, '0')
    .toUpperCase()
  const blue = Math.abs(Math.round(((255 - b) / (255 + b)) * 255))
    .toString(16)
    .padStart(2, '0')
    .toUpperCase()
  return `#${red}${green}${blue}`
}

function findTextColor(color: string) {
  if ( typeof color === 'string' ) {
    const colorLowerCase = color?.toLowerCase()
    return Object.entries(colors).find(([_, value]: [string, string]) => value.toLowerCase() === colorLowerCase)?.[0]
  }
}

export function getContrastColor(color: string | any[]) {
  if ( typeof color === 'string' ) {
    return getContrastRgb(color)
  }

  if (color[0] === 'interpolate') {
    return color.map(item => {
      if ( typeof item === 'string' ) {
        if ( item.substring(0, 3) === 'hsl' ) {
          const valuesString = item.substring(4, item.length - 2)
          const [h, s, l] = valuesString.split(',').map((item) => +item.split('%')[0])
          const contrast = `hsl(${360 - h}, ${100  -s}%, ${100 - l}%)`
          return contrast
        }
        if ( item[0] === '#' ) {
          return getContrastRgb(item)
        }
        const rgbColor = findTextColor(item)
        if ( rgbColor ) {
          return getContrastRgb(rgbColor)
        }
      }
      return item
    })
  }

  if (color[0] === 'match') {
    return color.map((item) => {
      const rgbColor = findTextColor(item)
      if (rgbColor) {
        const contrast = getContrastRgb(rgbColor)
        return contrast
      }
      return item
    })
  }

  return '#C080C0'
}