import { action, observable, runInAction, makeObservable } from 'mobx'
import { message } from '../../api/common/ServerApi'
import LoadableStore from '../common/ILoadableStore'
import IColumnDescDto from '../../dto/columndesc/IColumnDescDto'
import IColumnGroupDto from '../../dto/columngroup/IColumnGroupDto'
import StructureApi from '../../api/StructureApi'
import IColumnSortableNode from '../../dto/form/IColumnSortableNode'
import IRootStore from '../_root/type'

class ColumnSelectorStore extends LoadableStore {
  open: boolean
  multiSelect: boolean
  formId: number

  columns: IColumnDescDto[] = []
  columnMap: {}
  rootGroups: IColumnGroupDto[] = []

  // "maps"
  selectedColumnIds: any = {}
  selectedGroupUids: any = {}

  disabledColumnIds: any = {}
  disabledGroupUids: any = {}

  onSelect: (columns: IColumnDescDto[], groups: IColumnGroupDto[]) => Promise<boolean>

  //-------------

  openModal = async (
    formId: number,
    multiSelect: boolean,
    onSelect: (columns: IColumnDescDto[], groups: IColumnGroupDto[]) => Promise<boolean>,
  ) => {
    console.debug('ColumnSelectorStore::openModal')
    this.open = true
    this.multiSelect = multiSelect
    this.onSelect = onSelect

    this.selectedGroupUids = {}
    this.selectedColumnIds = {}
    this.disabledColumnIds = {}
    this.disabledGroupUids = {}
    this.columns = []
    this.columnMap = {}
    this.rootGroups = []

    this.loadingError = null
    this.isLoading = true
    const resp = await StructureApi.getForm2({
      id: formId,
      includeColumnHasData: false,
      includeReports: false,
      includeColumns: true,
    })
    if (resp.success) {
      runInAction(() => {
        const form = resp.data
        this.columns = form.columns
        this.rootGroups = form.rootColumnGroups
        this.columns.forEach(x => {
          this.columnMap[x.id] = x
        })
        this.isLoading = false
      })
    } else {
      runInAction(() => {
        this.loadingError = message(resp)
        this.isLoading = false
      })
    }

    return resp
  }

  closeModal = () => {
    this.open = false
    this.selectedColumnIds = {}
    this.selectedGroupUids = {}
    this.disabledColumnIds = {}
    this.disabledGroupUids = {}
    this.onSelect = null
  }

  toggle = (obj: IColumnSortableNode) => {
    const oldPresent = obj.isGroup
      ? this.selectedGroupUids[obj.uid]
      : this.selectedColumnIds[obj.id]
    if (!this.multiSelect) {
      this.selectedGroupUids = {}
      this.selectedColumnIds = {}
    }

    if (obj.isGroup) {
      const removeChs = (node: IColumnSortableNode) => {
        if (node.isGroup) this.selectedGroupUids[node.uid] = false
        else this.selectedColumnIds[node.id] = false

        if (node.children) {
          node.children.forEach(ch => {
            removeChs(ch)
          })
        }
      }

      removeChs(obj)

      if (oldPresent) this.selectedGroupUids[obj.uid] = false
      else this.selectedGroupUids[obj.uid] = true
    } else {
      if (oldPresent) this.selectedColumnIds[obj.id] = false
      else this.selectedColumnIds[obj.id] = true
    }

    this.selectedGroupUids = { ...this.selectedGroupUids }
    this.selectedColumnIds = { ...this.selectedColumnIds }

    this.recalcDisabled()
  }

  private recalcDisabled = () => {
    this.disabledColumnIds = {}
    this.disabledGroupUids = {}

    const fn = (g: IColumnGroupDto, level: number) => {
      if (level != 0) this.disabledGroupUids[g.uid] = true
      if (g.columns) {
        g.columns.forEach(col => {
          this.disabledColumnIds[col.id] = true
        })
      }
      if (g.childs) {
        g.childs.forEach(ch => {
          fn(ch, level + 1)
        })
      }
    }

    this.getSelectedGroups().forEach(g => {
      fn(g, 0)
    })
  }

  getSelectedColumns = (): IColumnDescDto[] => {
    const res = []
    this.columns.forEach(x => {
      if (this.selectedColumnIds[x.id]) res.push(JSON.parse(JSON.stringify(x)))
    })
    return res
  }

  getSelectedGroups = (): IColumnGroupDto[] => {
    const res = []

    const fn = (g: IColumnGroupDto) => {
      if (this.selectedGroupUids[g.uid]) res.push(JSON.parse(JSON.stringify(g)))
      if (g.childs) {
        g.childs.forEach(x => fn(x))
      }
    }
    this.rootGroups.forEach(x => {
      fn(x)
    })

    const fn2 = (g: IColumnGroupDto) => {
      if (g.columns)
        g.columns = g.columns.map(x => JSON.parse(JSON.stringify(this.columnMap[x.id])))
      if (g.childs)
        g.childs.forEach(ch => {
          fn2(ch)
        })
    }
    res.forEach(x => {
      fn2(x)
    })

    return res
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      multiSelect: observable,
      columns: observable,
      rootGroups: observable,
      openModal: action,
      closeModal: action,
      toggle: action,

      selectedColumnIds: observable,
      selectedGroupUids: observable,
      disabledColumnIds: observable,
      disabledGroupUids: observable,
    })
  }
}

export default ColumnSelectorStore
