import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import useStore from 'src/context/RootStoreContext'
import { getSubsystem } from '../../auth/LoginView'
import Error403View from '../../error-pages/Error403View'
import LoadingScreen from '../LoadingScreen'
import { LinearProgress } from '@material-ui/core'

const PrivateRoute: FunctionComponent<RouteProps> = ({ component, ...rest }) => {
  const { authStore } = useStore()
  const authenticatedUser = authStore.authenticatedUser
  const authenticatedUserDetails = authStore.authenticatedUserDetails
  const Component = component as React.ComponentType
  const subsystem = getSubsystem(rest)
  const signinUrl = `/${subsystem}/signin`

  const hasAccess =
    authenticatedUserDetails &&
    (subsystem === 'data')

  console.debug('444')

  /*useEffect(() => {
        if (!authenticatedUser) authStore.guestAuth();
    }, [authenticatedUser]);*/

  return (
    <Route
      {...rest}
      render={props => {
        if (authenticatedUser) {
          if (authenticatedUserDetails) {
            if (hasAccess) {
              return <Component {...rest} {...props} />
            } else {
              return <Error403View {...rest} />
            }
          } else {
            return <LoadingScreen />
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: signinUrl,
                state: { from: props.location },
              }}
            />
          )
          /*return <LinearProgress />*/
        }
      }}
    />
  )
}

export default observer(PrivateRoute)
