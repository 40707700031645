import { AnyLayer, CircleLayer, FillLayer, GeoJSONSource, LineLayer, RasterLayer, SymbolLayer } from 'react-map-gl/maplibre'
import { Feature, Geometry, LineString, Point, Polygon } from 'geojson'
import {SourceSpecification, VectorSourceSpecification} from 'maplibre-gl'
import { RasterSourceSpecification } from '@maplibre/maplibre-gl-style-spec'

import IReportGisInfo from 'src/dto/gismap/IReportGisInfo'
import IReportBriefDto from 'src/dto/report/IReportBriefDto'
import IGismapColumnDescDto from 'src/dto/gismap/IGismapColumnDescDto'
import IFormBriefDto from 'src/dto/form/IFormBriefDto'
import IGeoTiffBriefDto from 'src/dto/gismap/IGeoTiffBriefDto'
import IExternalLayerDto from '../../../dto/gismap/IExternalLayerDto'

export type GeoJsonSource = GeoJSONSource & {
	layers: AnyLayer[]
	id: string
}

export type VectorSourceBase = VectorSourceSpecification & {
	layers: AnyLayer[]
	id: string
}

export type VectorSource = VectorSourceBase & {
	id: string
	opacity: number
	selectedFeaturesIds: number[]
	tiles: string[]
	useDefaultOpacity: boolean
	visible: boolean
}

export type AvailableLayer = IReportGisInfo & { sourceId: string }

export type RasterSourceBase = RasterSourceSpecification & {
	layers: RasterLayer[]
	id: string
	useDefaultOpacity: boolean
}

export type RasterSource = RasterSourceBase

export type ExtSourceBase = SourceSpecification & {
	layers: AnyLayer[]
	id: string
	useDefaultOpacity: boolean
}

export type ExtSource = ExtSourceBase

export type LayerType = 'register' | 'geotiff' | 'external'

export type RegisterLayerListReport = {
	form: IFormBriefDto
	items: RegisterLayerListItem[]
	report: IReportBriefDto
}

export type RegisterLayerListItem = {
	sourceId: string
	gc: IGismapColumnDescDto
}

export type GeoTiffLayerListReport = {
	items: GeoTiffLayerListItem[]
}

export type GeoTiffLayerListItem = {
	sourceId: string
	geoTiff: IGeoTiffBriefDto
}

export type ExtLayerListReport = {
	items: ExtLayerListItem[]
}

export type ExtLayerListItem = {
	sourceId: string
	externalLayer: IExternalLayerDto
}

export type AnyLayerListReport = (RegisterLayerListReport | GeoTiffLayerListReport | ExtLayerListReport) & {
	type: LayerType
}

export type LayerListReport = AnyLayerListReport

export type LayerListItem = (RegisterLayerListItem | GeoTiffLayerListItem | ExtLayerListItem)


export function equalsLLR(x1: LayerListReport, x2: LayerListReport): boolean {
  return keyLLR(x1) === keyLLR(x2)
}
export function keyLLR(x: LayerListReport): string {
  if (!x) return
  switch (x.type) {
  case 'register':
    return `register:${(x as RegisterLayerListReport).form.id}.${(x as RegisterLayerListReport).report.id}`
  case 'geotiff':
    return 'geotiff'
  case 'external':
    return 'external'
  }
}

export type GeometryType = Point | LineString | Polygon
export type FeatureType = Feature<GeometryType>
export type UsingLayer = CircleLayer | FillLayer | LineLayer | SymbolLayer
export type SelectedPoint = { point: Point, featureId: string | number }

export type DrawStoreItem = {
	feature: FeatureType
	columnId: string | number
}

type Properties = {
	place_id: number
	osm_type: string
	osm_id: number
	display_name: string
	place_rank: number
	category: string
	type: string
	importance: number
}

type GeocoderFeatureProperties = Properties & { geocoderComponentId: string }

export type GeocoderFeature = Feature<Geometry, Properties>

export type GeocoderItem = {
	licence: string
	boundingbox: [number, number, number, number]
	lat: string
	lon: string
} & Properties

export type GeocoderComponentFeature = Feature<Geometry, GeocoderFeatureProperties>

export type GeocoderFeatureCollection = {
	type: 'FeatureCollection'
	licence: string
	features: GeocoderFeature[]
}

type MapFeatureProps = {
	id?: string | number
	reportId?: string
	sourceId?: string
	[key: number]: string
}

export type MapFeature = Feature<Geometry, MapFeatureProps>

export type MeasureMode = 'DISTANCE' | 'SQUARE'

export type GeocadasrtResponse = {
    cadastralNumber: string,
    addressLocation: string,
    area: any | null,
    cost: number,
    contours: any | null,
    contourLocation: string
    unit: any | null,
    spatialData: any | null,
    params: string,
    subtypeValue: string,
    typeCode: string,
	typeValue: string,
    geom: string
	geojson: string
}

export type FormLayerItem = {
	id: number
	reports: { id: number; rowsCount: number }[]
	tableTitle: string
}

export type GeoTiffLayerItem = {
	id: number
	title: string
}

export type WhatsHereCadastrItem = {
	addressLocation: string | null
	area: number | null
	cadastralNumber: string | null
	contourLocation: string | null
	contours: null
	cost: number | null
	geojson: string | null
	geom: string | null
	params: string | null
	spatialData: null
	subtypeValue: string | null
	typeCode: string | null
	typeValue: string | null
	unit: null
}

export type WhatsHereCadastr = {
	buildRecords: WhatsHereCadastrItem[]
	cadastralBlocks: WhatsHereCadastrItem[]
	landRecords: WhatsHereCadastrItem[]
	zoneAndTerritoryRecords: WhatsHereCadastrItem[]
}

export type DbConfig = {
	defaultStyle: Object | null
	geocoder: {
		baseUrl: string | null
	}
	mapdrawStyle: string | null
	osrm: {
		baseUrl: string | null
	}
	selectedStyle: string
	tilerUrl: string | null
}