import MBGeoApi from 'src/api/MBGeoApi'
import { LayerListStore } from 'src/store/map-store'
import { equalsLLR, LayerListReport, RegisterLayerListReport } from 'src/store/map-store/types'
import IGeoTiffListRequestDto from '../../dto/request/IGeoTiffListRequestDto'
import GeoTiffApi from '../../api/GeoTiffApi'
import IExternalLayerListRequestDto from '../../dto/request/IExternalLayerListRequestDto'
import ExternalLayerApi from '../../api/ExternalLayerApi'
import IReportGisInfo from 'src/dto/gismap/IReportGisInfo'

export class LayersListService {
  private readonly _repository: LayerListStore

  constructor(repository: LayerListStore) {
    this._repository = repository
  }

  private async getCurrentReportGisInfo(reportId: number, userGroupId: number) {
    const { data, message, status, success } = await MBGeoApi.getReportGisInfo({
      includeRowInfo: false,
      includeRowInfoAsColumns: false,
      reportId,
      userGroupId,
      dicRequestMode: 'DRE_ALL_FIELDS_RECURSIVE',
    })
    if (status >= 400 || !success) {
      throw new Error(message || 'Что-то пошло не так...')
    }
    return data
  }

  private async getAvailableReportGisInfos(userGroupId: number) {
    const { data, message, status, success } = await MBGeoApi.getReportGisInfos({ open: null })
    if (status >= 400 || !success) {
      throw new Error(message || 'Что-то пошло не так...')
    }
    return data
  }

  private async getGeoTiffs(requestDto: IGeoTiffListRequestDto) {
    const { data, message, status, success } = await GeoTiffApi.getGeoTiffs(requestDto)
    if (status >= 400 || !success) {
      throw new Error(message || 'Что-то пошло не так...')
    }
    return data.content
  }

  private async getExternalLayers(requestDto: IExternalLayerListRequestDto) {
    const { data, message, status, success } = await ExternalLayerApi.getExternalLayers(requestDto)
    if (status >= 400 || !success) {
      throw new Error(message || 'Что-то пошло не так...')
    }
    return data.content
  }

  async fetchCurrentReportGisInfo(reportId: number, userGroupId: number) {
    try {
      this._repository.currentLayerIsLoading = true
      return await this.getCurrentReportGisInfo(reportId, userGroupId)
    } catch (e) {
      throw e
    } finally {
      this._repository.currentLayerIsLoading = false
    }
  }

  async fetchAvailableReportGisInfos(userGroupId: number) {
    try {
      this._repository.availableLayersIsLoading = true
      return await this.getAvailableReportGisInfos(userGroupId)
    } catch (e) {
      throw e
    } finally {
      this._repository.availableLayersIsLoading = false
    }
  }

  async fetchGeoTiffs(requestDto: IGeoTiffListRequestDto) {
    try {
      this._repository.geoTiffsIsLoading = true
      return await this.getGeoTiffs(requestDto)
    } catch (e) {
      throw e
    } finally {
      this._repository.geoTiffsIsLoading = false
    }
  }

  async fetchExternalLayerDtos(requestDto: IExternalLayerListRequestDto) {
    try {
      this._repository.externalLayersIsLoading = true
      return await this.getExternalLayers(requestDto)
    } catch (e) {
      throw e
    } finally {
      this._repository.externalLayersIsLoading = false
    }
  }

  addAvailableLayer(item: LayerListReport) {
    const itemExists = this._repository.availableLayers.some(x => equalsLLR(x, item))

    if (!itemExists) {
      this._repository.availableLayers = [...this._repository.availableLayers, item]
    }
  }

  getAvailableLayers() {
    return this._repository.availableLayers
  }

  getCurrentLayer() {
    return this._repository.currentLayer
  }

  getLayerByReportId(reportId: number): RegisterLayerListReport | null {
    function registerLayerFilter(value: any): value is RegisterLayerListReport {
      return !!value?.report
    }

    const registerLayers = this._repository.availableLayers.filter(
      registerLayerFilter,
    ) as RegisterLayerListReport[]
    return registerLayers.find(({ report }) => report?.id === reportId)
  }

  getReportGisInfo() {
    return this._repository.reportGisInfo
  }

  getSourceIdsByReportId(reportId: string | number) {
    return this.getSourcesByReportId(reportId)?.map(({ sourceId }) => sourceId) || []
  }

  getSourcesByReportId(reportId: string | number) {
    return [this._repository.currentLayer, ...this._repository.availableLayers].find(
      item => item.type === 'register' && (item as RegisterLayerListReport).report.id === reportId,
    )?.items
  }

  resetStore() {
    this._repository.reset()
  }

  setReportGisInfo(info: IReportGisInfo[]) {
    this._repository.reportGisInfo = info
  }

  setCurrentLayer(layer: RegisterLayerListReport) {
    this._repository.setCurrentLayer(layer)
  }

  setSearchString(value: string) {
    this._repository.searchString = value
  }

  rotateTwoAvailableLayers(firstIndex: number, secondIndex: number) {
    const layers = [...this._repository.availableLayers]
    layers[firstIndex] = this._repository.availableLayers[secondIndex]
    layers[secondIndex] = this._repository.availableLayers[firstIndex]
    this._repository.setAvailableLayers(layers)
  }

  /**
   * 10.1. Получить настройки карты.
   * @param mapId - идентификатор настроек карты;
   * @returns Настройки карты.
   */
  async getReportsFromMapSettings(mapId: string) {
    try {
      if (isNaN(+mapId)) {
        throw new Error(`id карты должен быть числом. Получено ${mapId}`)
      }

      const { data, message, success } = await MBGeoApi.getGismapAsGisInfos({ gismapId: +mapId })
      if (!success) {
        throw new Error(message)
      }

      return data
    } catch (e) {
      throw e
    }
  }
}
