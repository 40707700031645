import { ContextMenuItem, ContextMenuStore, IContextMenuController } from 'src/store/context-menu'
import { ContextMenuService } from './ContextMenuService'

export class ContextMenuController implements IContextMenuController {
  private readonly service: ContextMenuService

  constructor(contextMenuStore: ContextMenuStore) {
    this.service = new ContextMenuService(contextMenuStore)
  }

  show(items: ContextMenuItem[], position: { top: number, left: number }) {
    this.service.setItems(items)
    this.service.setPosition(position)
    this.service.setShow(true)
  }

  hide() {
    this.service.reset()
  }
}