export type FLAT_COLUMN_TYPE =
  | 'STRING'
  | 'FLOAT'
  | 'INT'
  | 'BOOLEAN'
  | 'TIMESTAMP'
  | 'FILE'
  | 'FILE_SET'
  | 'REF'
  | 'INDICATOR'
  | 'JSONB'
  | 'FORMULA'
  | 'COLUMN_FORMULA'
  | 'GEO_POINT'
  | 'GEO_POLYGON'
  | 'POSTGIS'
  | 'REFERRER_FIELD'

export const FLAT_COLUMN_TYPES: Array<FLAT_COLUMN_TYPE> = [
  'STRING',
  'FLOAT',
  'INT',
  'BOOLEAN',
  'TIMESTAMP',
  'FILE',
  'FILE_SET',
  'REF',
  'POSTGIS',
  'INDICATOR',
  'FORMULA',
  'COLUMN_FORMULA',
  'JSONB' /*'GEO_POINT', 'GEO_POLYGON',*/,
  'REFERRER_FIELD',
]

export const FLAT_COLUMN_TYPE_MAP = {
  STRING: 'Строка',
  FLOAT: 'Дробное число',
  INT: 'Целое число',
  BOOLEAN: 'Логическое (да/нет)',
  TIMESTAMP: 'Дата',
  FILE: 'Файл',
  FILE_SET: 'Набор файлов',
  REF: 'Ссылка на реестр',
  INDICATOR: 'Показатель',
  JSONB: 'JSONB',
  FORMULA: 'Формула ячейки',
  COLUMN_FORMULA: 'Внутристрочная колоночная формула',
  GEO_POINT: 'Гео точка',
  GEO_POLYGON: 'Гео полигон',
  POSTGIS: 'Геометрия',
  REFERRER_FIELD: 'Данные ссылающейся таблицы',
}

export function agIgnoredColumnType(colType: FLAT_COLUMN_TYPE) {
  switch (colType) {
    case 'FILE':
    case 'FILE_SET':
    case 'JSONB':
    case 'GEO_POINT':
    case 'GEO_POLYGON':
    case 'POSTGIS':
    case 'REFERRER_FIELD':
      return true
    default:
      return false
    /*case "STRING":
        case "FLOAT":
        case "INT":
        case "BOOLEAN":
        case "TIMESTAMP":
        case "REF":
        case "INDICATOR":
        case "FORMULA":*/
  }
}
