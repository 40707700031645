import IPssRequestDto from './IPssRequestDto'
import {FORM_TYPE} from '../../enums/FORM_TYPE'
import IFormScheduleFilterDto from './IFormScheduleFilterDto'

export default class IFormListRequestDto extends IPssRequestDto {
  formTypes?: FORM_TYPE[] = []
  open?: boolean

  hasRootSupplier?: boolean
  excludeFormId?: number
  tags?: string[]

  userId?: number
  asAdmin?: boolean
  ignoreCurrentUser?: boolean
  forUseAsRef?: boolean
  excludeNonReusable?: boolean

  scheduleFilter?: IFormScheduleFilterDto

  hasVisibleGeom?: boolean

  lkUserGroupsIds?: number[]

  constructor() {
    super()
    this.pageSize = 25
    this.sort = [
      { columnName: 'id', asc: false },
    ]
    this.tags = []
  }

}
