import IIdDto from '../common/IIdDto'

export default interface IUserBriefDto extends IIdDto {
    email: string;
    workEmail: string;
    firstName: string;
    secondName: string;
    lastName: string;
    department: string;
    post: string;
    phone: string;
    roles: string[];
    locked: boolean;
    apiKey: string;
    externalId: string;
    shudOnly: boolean;
    nsiOnly: boolean;
    lastLogin: Date;
    created: Date;
    esiaApproved: boolean;
    esiaRidDoc: string;
}

export function makeUserName(user: IUserBriefDto): string {
  if (!user) return ''
  const arr: string[] = []
  if (user.lastName) arr.push(user.lastName)
  if (user.firstName) arr.push(user.firstName)
  if (user.secondName) arr.push(user.secondName)
  return arr.join(' ')
}

export function rolesTitle(roles: string[]): string {
  if (!(roles && roles.length)) return 'Пользователь'
  return roles.map(x => roleTitle(x)).join(', ')
}

export function roleTitle(role: string): string{
  if (!role) return null
  switch (role) {
  case 'ADMIN': return 'Администратор'
  case 'OBSERVER': return 'Наблюдатель'
  case 'USER': return 'Пользователь'
  default: return role
  }
}

export function userEsiaState(user: IUserBriefDto): string {
  if (user.esiaApproved === false) return 'Заявка ожидает одобрения'
  else if (user.esiaApproved === true) return 'Заявка одобрена'
  else return ''
}
