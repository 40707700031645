import { action, makeObservable } from 'mobx'
import IUserFullDto from '../dto/ancient-mser/IUserFullDto'
import UserApi from '../api/UserApi'
import { IResponse } from '../api/common/ServerApi'
import BaseListStore from './common/BaseListStore'
import IUserListRequestDto from '../dto/request/IUserListRequestDto'
import IRootStore from './_root/type'

class UserListStore extends BaseListStore<IUserFullDto, IUserListRequestDto> {
  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, { })
  }

  protected getDefaultRequestOptions(): IUserListRequestDto {
    return new IUserListRequestDto()
  }

  initialize = async (initObj?: any): Promise<IResponse> => {
    this.requestOptions = initObj ? initObj : this.getDefaultRequestOptions()

    const resp = await this.load(this.requestOptions)
    this.initialized = true
    return resp
  }

  protected async getListRoutine(apiRequestOptions: IUserListRequestDto): Promise<IResponse> {
    return UserApi.getUsers(apiRequestOptions)
  }

}

export default UserListStore
