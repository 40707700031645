import { action, observable, makeObservable } from 'mobx'
import IFormBriefDto from '../../dto/form/IFormBriefDto'
import FormListStore from '../FormListStore'
import IFormListRequestDto from '../../dto/request/IFormListRequestDto'
import { FORM_TYPE } from '../../enums/FORM_TYPE'
import IRootStore from '../_root/type'

class RegisterSelectorStore extends FormListStore {
  open: boolean = false
  availableFormTypes: FORM_TYPE[] = []
  selectedForm: IFormBriefDto
  exludeFormId: number

  handleSelect: (user: IFormBriefDto) => void
  handleCancel: () => void

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      selectedForm: observable,
      availableFormTypes: observable,

      openModal: action,
      closeModal: action,
      selectForm: action,
    })
  }

  protected getDefaultRequestOptions(): IFormListRequestDto {
    const res = new IFormListRequestDto()
    res.formTypes = this.availableFormTypes || ['REGISTER', 'GEO_REGISTER', 'NSI', 'CNSI']
    res.pageSize = 20
    res.forUseAsRef = true
    res.excludeNonReusable = true
    res.excludeFormId = this.exludeFormId
    return res
  }

  openModal = async (
    availableFormTypes: FORM_TYPE[],
    excludeFormId: number,
    onSelect: (IFormBriefDto) => void,
    onCancel?: () => void,
  ) => {
    this.selectedForm = null
    this.handleSelect = onSelect
    this.handleCancel = onCancel
    this.open = true
    this.availableFormTypes = availableFormTypes
    this.exludeFormId = excludeFormId
    this.initialize()
  }

  closeModal = () => {
    this.exludeFormId = null
    this.open = false
    if (this.handleCancel) this.handleCancel()
  }

  selectForm = (form: IFormBriefDto) => {
    this.selectedForm = form
  }
}

export default RegisterSelectorStore
