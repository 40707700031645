import { action, makeObservable, observable } from 'mobx'
import { IResponse } from '../../api/common/ServerApi'
import RootStoreModel from '../_root/RootStoreModel'
import IRootStore from '../_root/type'

// T - ResponseDTO type, R - requestOptionsType
class LoadableStore extends RootStoreModel {
  loadingError: string
  isLoading: boolean
  initialized: boolean

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      loadingError: observable,
      isLoading: observable,
      initialized: observable,
      initialize: action,
    })
  }

  public async initialize(initObj?: any): Promise<IResponse> {
    return { success: true }
  }
}

export default LoadableStore
