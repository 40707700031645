import React, {FunctionComponent} from 'react'
import {observer} from 'mobx-react'
import {Redirect, Route, RouteComponentProps, RouteProps} from 'react-router-dom'
import useStore from 'src/context/RootStoreContext'

const GuestRoute: FunctionComponent<RouteProps> = ({
  component,
  render,
  ...rest
}) => {
  const authenticatedUser = useStore().authStore.authenticatedUser
  const Component = component as React.ComponentType

  if (authenticatedUser) return <Redirect to="/" />

  return (
    <Route
      {...rest}
      component={(props: RouteComponentProps) =>
        !authenticatedUser ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default observer(GuestRoute)
