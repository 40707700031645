import IPssRequestDto from '../request/IPssRequestDto'

export default class IReportRequestDto extends IPssRequestDto {
  formId: number
  userGroupId?: number

  constructor() {
    super()
    this.pageSize = 25
    this.sort = [
      { columnName: 'periodEnd', asc: false },
      { columnName: 'periodStart', asc: false },
    ]
  }
}
