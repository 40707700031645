import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from './_root/RootStoreModel'
import IColumnDescDto from '../dto/columndesc/IColumnDescDto'
import { uuidv4 } from '../util/uuid'
import { FLAT_COLUMN_TYPE } from '../enums/FLAT_COLUMN_TYPE'
import IRootStore from './_root/type'

class ColumnDescEditorStore extends RootStoreModel {
  dto: IColumnDescDto
  opened: boolean = false
  onSubmit: (dto: IColumnDescDto) => void
  availableTypes: FLAT_COLUMN_TYPE[] = null

  open = (
    column: IColumnDescDto,
    availableTypes: FLAT_COLUMN_TYPE[],
    onSubmit: (dto: IColumnDescDto) => void,
  ) => {
    console.debug('4444')
    if (column) {
      this.dto = JSON.parse(JSON.stringify(column))
      if (!this.dto.id && !this.dto.uid) this.dto.uid = uuidv4()
    } else {
      this.dto = null
    }
    this.availableTypes = availableTypes
    this.onSubmit = onSubmit
    this.opened = true
  }

  update = (dto: IColumnDescDto) => {
    this.dto = dto
  }

  cancel = () => {
    this.opened = false
  }

  submit = () => {
    if (this.onSubmit) this.onSubmit(this.dto)
    this.opened = false
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      dto: observable,
      availableTypes: observable,
      opened: observable,
      open: action,
      update: action,
      cancel: action,
      submit: action,
    })
  }
}

export default ColumnDescEditorStore
