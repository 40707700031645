import ServerApi, {IResponse} from './common/ServerApi'
import IPageDto, {emptyPage} from '../dto/common/IPageDto'
import IExternalLayerDto from '../dto/gismap/IExternalLayerDto'
import IExternalLayerListRequestDto from '../dto/request/IExternalLayerListRequestDto'

export default class ExternalLayerApi {
  static getExternalLayerProfile(id: number): Promise<IResponse<IExternalLayerDto>> {
    console.debug('getExternalLayerProfile')
    return ServerApi.postQuery('externalLayer/getExternalLayerProfile', {id: id})
  }

  static getExternalLayers(requestDto: IExternalLayerListRequestDto): Promise<IResponse<IPageDto<IExternalLayerDto>>> {
    return new Promise(resolve => ({success: true, data: emptyPage}))
    console.debug('getExternalLayers')
    return ServerApi.postQuery('externalLayer/getExternalLayers', requestDto)
  }

  static deleteExternalLayer(id: number): Promise<IResponse> {
    console.debug('deleteExternalLayer')
    return ServerApi.postQuery('externalLayer/deleteExternalLayer', {id: id})
  }

  static deleteExternalLayers(ids: number[]): Promise<IResponse> {
    console.debug('deleteGeoTiffs')
    return ServerApi.postQuery('externalLayer/deleteExternalLayers', {ids: ids})
  }

  static saveExternalLayer(dto: IExternalLayerDto): Promise<IResponse<IExternalLayerDto>> {
    console.debug('saveExternalLayer')
    return ServerApi.postQuery('externalLayer/saveExternalLayer', dto)
  }

}
