import { observable, makeObservable } from 'mobx'
import AlertModalStore, { AlertModalStoreDecorators } from './AlertModalStore'
import IRootStore from '../_root/type'

class XlsxUploadModalStore extends AlertModalStore {
  useDataFormat: boolean

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, { useDataFormat: observable })
  }
}

export default XlsxUploadModalStore
