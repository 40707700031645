import React from 'react'
import {Box, Container, makeStyles, Typography, useMediaQuery, useTheme} from '@material-ui/core'
import Page from '../components/Page'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}))

function Error403View() {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Page className={classes.root} title="403: нет доступа">
      <Box style={{position: 'absolute', top: 0, height: 100, left: 0, backgroundColor: theme.palette.background.default}}>
        <Typography
          style={{position: 'relative', top: 20, left: 30}}
          variant="caption"
          color="textSecondary"
        >
          {process.env.REACT_APP_COPYRIGHT}
        </Typography>
      </Box>

      <Container maxWidth="lg">

        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          403: Нет доступа
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          Нет доступа к подсистеме и/или разделу.
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img
            alt=""
            className={classes.image}
            src="/static/logo512.png"
          />
        </Box>
      </Container>
    </Page>
  )
}

export default Error403View
