import ISupplierFullDto from './ISupplierFullDto'
import ISupplierBriefDto from './ISupplierBriefDto'
import ISortableNode, {makeMapOfExpanded} from '../common/ISortableNode'
import {alphaSortFn} from '../../util/objects'

export default interface ISupplierSortableNode extends ISortableNode{
    id?: number;
    supplier?: ISupplierBriefDto;
}

export function customNodeKey ({ node, treeIndex }): number {
  return node && node.id
}

// ---------------

export function makeSupplierTree(rootSupplier: ISupplierFullDto, oldRootSortable: ISupplierSortableNode): ISupplierSortableNode {
  if (!rootSupplier) return null

  const expandedMap = makeMapOfExpanded([oldRootSortable], customNodeKey)

  // make deep copy of rootProvider

  function _makeNode (node: ISupplierFullDto): ISupplierSortableNode {
    const res: ISupplierSortableNode = {
      id: node.id,
      children: [],
      expanded: !oldRootSortable || expandedMap[customNodeKey({node: node, treeIndex: null})],
      supplier: node,
    }
    if (node.childs) node.childs.forEach(x => {
      res.children.push(_makeNode(x))
    })
    res.children = res.children.sort( (x1, x2) => {
      return alphaSortFn(
        (x1 as ISupplierSortableNode).supplier.userGroup.name,
        (x2 as ISupplierSortableNode).supplier.userGroup.name,
      )
    })
    return res
  }

  return _makeNode(rootSupplier)
}

// ---
