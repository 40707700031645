import { action, observable, makeObservable } from 'mobx'
import RootStoreModel from '../_root/RootStoreModel'
import IRootStore from '../_root/type'

const DEFAULT_WIDTH = 200
const DEFAULT_ROWS = 10

class TextFieldModalStore extends RootStoreModel {
  open: boolean = false
  title: string
  value: string
  maxlen: number
  width: number = DEFAULT_WIDTH
  rows: number = DEFAULT_ROWS
  handleConfirm?: Function
  handleCancel?: Function

  openModal = (
    title: string,
    initialValue: string,
    maxlen: number,
    handleConfirm?: Function,
    handleCancel?: Function,
    width?: number,
    rows?: number,
  ) => {
    if (this.open) {
      this.closeModal()
    }

    this.title = title
    this.value = initialValue
    this.maxlen = maxlen
    this.width = width || DEFAULT_WIDTH
    this.rows = rows || DEFAULT_ROWS
    this.handleConfirm = handleConfirm
    this.handleCancel = handleCancel

    this.open = true
  }

  closeModal = () => {
    this.open = false

    this.title = ''
    this.value = ''
    this.handleConfirm = null
    this.handleCancel = null
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      open: observable,
      title: observable,
      value: observable,
      maxlen: observable,
      width: observable,
      openModal: action,
      closeModal: action,
    })
  }
}

export default TextFieldModalStore
