import {action, computed, observable, runInAction, makeObservable} from 'mobx'
import RootStoreModel from './_root/RootStoreModel'
import IUserFullDto from '../dto/ancient-mser/IUserFullDto'
import UserApi from '../api/UserApi'
import IPageDto from '../dto/common/IPageDto'
import AuthApi from '../api/AuthApi'
import IRootStore from './_root/type'
import ServerApi, {
  IResponse,
  LOCAL_STORAGE_ACCESS_TOKEN_NAME,
  LOCAL_STORAGE_CURRENT_USER,
  LOCAL_STORAGE_REFRESH_TOKEN_NAME,
} from '../api/common/ServerApi'
import JwtDecode from 'jwt-decode'
import IUserBriefDto from '../dto/ancient-mser/IUserBriefDto'
import IUserGroupBriefDTO from '../dto/usergroup/IUserGroupBriefDto'
import IUserGroupBriefWithPathDto from '../dto/usergroup/IUserGroupBriefWithPathDto'
import IUserGroupFullDto from '../dto/usergroup/IUserGroupFullDto'

interface IAuthToken {
  user: IUserBriefDto;
  iat: number;
  exp: number;
}

const LOCAL_STORAGE_LKS_NAME = 'lks'
const LOCAL_STORAGE_SELECTED_LK_ID_NAME = 'selectedLkId'

class AuthStore extends RootStoreModel {
  foundUsers: IPageDto<IUserFullDto>

  authenticatedUser: IUserBriefDto
  authenticatedUserDetails: IUserFullDto

  lks: IUserGroupFullDto[]
  selectedLkId: number = null

  errMessage: string

  get isSysAdmin(): boolean {
    const user = this.authenticatedUser
    return user && user.roles && user.roles.includes('ADMIN')
  }

  get isShudAdmin(): boolean {
    const user = this.authenticatedUser
    return user && user.roles && user.roles.includes('ADMIN') && !user.nsiOnly
  }

  get isNsiAdmin(): boolean {
    const user = this.authenticatedUser
    return user && user.roles && user.roles.includes('ADMIN') && !user.shudOnly
  }

  get isUserGroupAdmin(): boolean {
    const user = this.authenticatedUserDetails
    return user && user.adminGroups && (user.adminGroups.length > 0)
  }

  get isUser(): boolean {
    const user = this.authenticatedUser
    return user && user.roles && user.roles.includes('USER')
  }

  get isObserver(): boolean {
    const user = this.authenticatedUser
    return user && user.roles && user.roles.includes('OBSERVER')
  }

  get lkUserGroupsIds(): number[] {
    if (!(this.lks && this.selectedLkId)) return null
    const lk = this.lks.find(x => x.id === this.selectedLkId)
    if (!(lk && lk.childs && lk.childs.length)) return null
    return lk.childs.map(x => x.id)
  }

  get currentLk(): IUserGroupBriefDTO {
    if (!(this.lks && this.selectedLkId)) return null
    const lk = this.lks.find(x => x.id === this.selectedLkId)
    return lk
  }

  get nonobserverUserGroups(): IUserGroupBriefDTO[] {
    const res = []
    if (!this.authenticatedUserDetails) return res
    const set: any = {}
    this.authenticatedUserDetails.userGroups.forEach(x => {
      res.push(x)
      set[x.id] = true
    })
    this.authenticatedUserDetails.adminGroups.forEach(x => {
      if (!set[x.id]) res.push(x)
    })
    return res
  }

  get allUserGroups(): IUserGroupBriefDTO[] {
    const res = []
    if (!this.authenticatedUserDetails) return res
    const set: any = {};
    [
      this.authenticatedUserDetails.userGroups,
      this.authenticatedUserDetails.adminGroups,
      this.authenticatedUserDetails.observerGroups,
    ].forEach(arr => {
      arr.forEach(x => {
        if (!set[x.id]) res.push(x)
        set[x.id] = true
      })

    })
    return res
  }

  lksFromStorage = () => {
    try {
      const s = localStorage.getItem(LOCAL_STORAGE_LKS_NAME)
      this.lks = JSON.parse(s)
      this.selectedLkId = Number(localStorage.getItem(LOCAL_STORAGE_SELECTED_LK_ID_NAME))
    } catch (e) {
      this.lks = []
    }
  }

  lksToStorage = () => {
    localStorage.setItem(LOCAL_STORAGE_LKS_NAME, JSON.stringify(this.lks))
  }

  selectedLkIdToStorage = () => {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_LK_ID_NAME, String(this.selectedLkId))
  }

  lksUpdate = (user: IUserFullDto) => {

    function getRootUg(ug: IUserGroupBriefWithPathDto): IUserGroupBriefDTO {
      if (!ug) return null
      if (!ug.parent) return ug
      return getRootUg(ug.parent)
    }

    try {
      const lkMap: any = {}
      const uuggss = [user.userGroups, user.adminGroups, user.observerGroups]
      uuggss.forEach(ugs => {
        if (ugs && ugs.length) ugs.forEach(ug => {
          const rootUg = getRootUg(ug)
          if (!lkMap[rootUg.id]) {
            lkMap[rootUg.id] = {
              id: rootUg.id,
              name: rootUg.name,
              childs: [],
            }
          }
          lkMap[rootUg.id].childs.push({
            id: ug.id,
          })
        })
      })
      const lks = []
      for (const x in lkMap) {
        lks.push(lkMap[x])
      }
      this.lks = lks
    } catch (e) {
      this.lks = []
    }

    if (user.roles.includes('ADMIN') || user.roles.includes('OBSERVER') || user.roles.includes('GUEST')) {
      this.selectedLkId = null
    } else {
      if (this.lks && this.lks.length === 1) {
        this.selectedLkId = this.lks[0].id
        this.selectedLkIdToStorage()
      }
    }

    this.lksToStorage()
  }

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      authenticatedUser: observable,
      authenticatedUserDetails: observable,
      lks: observable,
      selectedLkId: observable,
      errMessage: observable,
      isSysAdmin: computed,
      isShudAdmin: computed,
      isNsiAdmin: computed,
      isUser: computed,
      isObserver: computed,
      isUserGroupAdmin: computed,
      lkUserGroupsIds: computed,
      currentLk: computed,
      nonobserverUserGroups: computed,
      allUserGroups: computed,
      logout: action,
      getAuthenticatedUserDetails: action,
      lksFromStorage: action,
      lksToStorage: action,
      lksUpdate: action,
      selectedLkIdToStorage: action,
      updateSession: action,
    });

    this.lksFromStorage()
  }

  getAuthenticatedUserDetails = async () => {
    await ServerApi.initialize(this.updateSession)
    this.updateSession()
    if (!(this.authenticatedUser && this.authenticatedUser.id)) return
    const resp = await UserApi.getUser(this.authenticatedUser.id)
    if (resp.success) {
      runInAction(() => {
        this.authenticatedUserDetails = resp.data
        this.lksUpdate(this.authenticatedUserDetails)
      })
    }
  }

  updateSession = () => {
    console.debug('AuthStore::UpdateSession...')
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME)
    if (token) {
      try {
        if (!this.authenticatedUser) this.authenticatedUser = (JwtDecode(token) as IAuthToken).user
      } catch (e) {
        this.authenticatedUser = null
        this.authenticatedUserDetails = null
      }
    } else {
      this.authenticatedUser = null
      this.authenticatedUserDetails = null
    }
  }

  // loginBasic
  loginBasic = async (email: string, password: string): Promise<IResponse> => {
    const resp = await AuthApi.loginBasic(email, password)
    if (resp.success) {
      ServerApi.updateTokenValues(resp.data.accessToken, resp.data.refreshToken)
      this.updateSession()
      //await this.getAuthenticatedUserDetails();
      const resp2 = await UserApi.getUser(this.authenticatedUser.id)
      runInAction(() => {
        if (resp2.success) this.authenticatedUserDetails = resp2.data
        else this.authenticatedUserDetails = null
        this.lksUpdate(this.authenticatedUserDetails)
      })
    }
    return resp
  }

  logout = async(subsystem?: string) => {
    const response = await AuthApi.logout(subsystem)
    this.authenticatedUser = null
    this.authenticatedUserDetails = null
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME, '')
    localStorage.setItem(LOCAL_STORAGE_CURRENT_USER, '')
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME, '')
  }

  guestAuth = async () => {
    const resp = await UserApi.getUser(null)
    if (resp.success) {
      runInAction(() => {
        this.authenticatedUserDetails = resp.data
        this.authenticatedUser = resp.data
        this.lksUpdate(this.authenticatedUserDetails)
      })
    }
  }

}

export default AuthStore
