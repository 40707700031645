import { action, observable, runInAction, makeObservable } from 'mobx'
import { IResponse, message } from '../../api/common/ServerApi'
import DataApi from '../../api/DataApi'
import IDataRowReadFullDto, {
  DatumDistanceCollection,
  DatumDistanceEnum,
} from '../../dto/data/IDataRowReadFullDto'
import IReportDataRequestDto from '../../dto/report/IReportDataRequestDto'
import IDataUploadDto from '../../dto/data/IDataUploadDto'
import IUpdateResultDto from '../../dto/data/IUpdateResultDto'
import IReportUgRowsDto from '../../dto/data/IReportUgRowsDto'
import ISupplierFullDto from '../../dto/supplier/ISupplierFullDto'
import ISupplierBriefDto, { fromSupplierFullDto } from '../../dto/supplier/ISupplierBriefDto'
import BaseDataStore from './BaseDataStore'
import IDataSetSupplierFirstDto from '../../dto/data/IDataSetSupplierFirstDto'
import ISetSupplierFirstRequestDto from '../../dto/report/ISetSupplierFirstRequestDto'
import SupplierApi from '../../api/SupplierApi'
import IDataMoveUpDto from '../../dto/data/IDataMoveUpDto'
import { getByPath } from '../../dto/data/IDataRowEditDto'
import IFormHeaderDto, { makeAGGridFieldName } from '../../dto/form/IFormHeaderDto'
import { agIgnoredColumnType } from '../../enums/FLAT_COLUMN_TYPE'
import IColumnDescDto from '../../dto/columndesc/IColumnDescDto'
import { DIC_REQUEST_MODE } from '../../enums/DIC_REQUEST_MODE'
import IFilterFieldDto from '../../dto/request/IFilterFieldDto'
import ISortFieldDto from '../../dto/request/ISortFieldDto'
import IFilterOrSortFieldDto from '../../dto/request/IFilterOrSortFieldDto'
import UserApi from '../../api/UserApi'
import IReportGisInfo from '../../dto/gismap/IReportGisInfo'
import MBGeoApi from '../../api/MBGeoApi'
import IGeodataUploadDto from '../../dto/gismap/IGeodataUploadDto'
import { TABLE_VIEW_MODE } from '../types'
import IRootStore from '../_root/type'
import {GDAL_EXPORT_FORMAT} from "../../enums/geoimpexp/GDAL_EXPORT_FORMAT";
import {EXPORT_STATUS} from "../../enums/geoimpexp/EXPORT_STATUS";
import {GeoExportApi} from "../../api/GeoExportApi";
import {FIELD_REQUEST_MODE} from "../../enums/FIELD_REQUEST_MODE";
import {GeoImportApi} from "../../api/GeoImportApi";
import IGeodataUploadPreviewDto from "../../dto/geoimpexp/IGeodataUploadPreviewDto";

export type DATA_VIEW_MODE = 'TABLE' | 'GEOMAP' | 'AGGRID'
export const AG_MAX_DATA_COUNT = 1_000_000 //10_000;

export const AG_SUMMARY_REP_PERIOD_START_NAME = '___r_ps'
export const AG_SUMMARY_REP_PERIOD_END_NAME = '___r_pe'
export const AG_SUMMARY_REP_REVISION_NAME = '___r_rev'

export interface IGeoExportState {
  guid: string;
  status: EXPORT_STATUS
  message: string
}

class MyReportDataStore extends BaseDataStore<IDataRowReadFullDto, IReportDataRequestDto> {
  tableViewMode: TABLE_VIEW_MODE = 'half'

  openData: boolean

  mySupplierTree: ISupplierFullDto
  splsDistance: object = {}

  subSupplierForSelector: ISupplierBriefDto

  viewMode: DATA_VIEW_MODE = 'TABLE'
  agRowData: any = []

  reportMap: {}

  gismapReport: IReportGisInfo

  geoExportState: IGeoExportState
  geodataUploadPreview: IGeodataUploadPreviewDto

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      mySupplierTree: observable,
      subSupplierForSelector: observable,
      viewMode: observable,
      agRowData: observable,
      gismapReport: observable,
      openData: observable,
      selectSupplier: action,
      load: action,
      setViewMode: action,
      loadGismapReport: action,
      uploadGeodataFile: action,
      tableViewMode: observable,
      geoExportState: observable,
      geodataUploadPreview: observable,
      geoExportStart: action,
    })
  }

  getGismapColumn(columnId: number) {
    return this.gismapReport.gismapColumns.find(({ columnDesc }) => columnDesc.id === columnId)
  }

  supplierStore = () => {
    return this.root().supplierSelectorStore
  }

  selectedSupplier = () => {
    return this.supplierStore().selectedSupplier
  }

  public async initialize(options: { initObj?: any; openData: boolean }): Promise<IResponse> {
    console.debug('MyReportDataStore::initialize...')
    this.openData = options && options.openData
    const initObj = options && options.initObj

    if (!initObj.reportId && initObj.formId) {
      this.viewMode = 'AGGRID'
    }

    if (initObj.reportId) {
      const respUiSetting = await UserApi.getUiSetting({
        type: 'FORM_REPORT_VIEW',
        paramInt2: initObj.reportId,
      })
      if (respUiSetting.success && respUiSetting.data) {
        initObj.requestOptions = respUiSetting.data.body
        if (initObj.requestOptions) initObj.requestOptions.reportId = initObj.reportId
      }
    }

    const respSuper = await super.initialize(initObj)
    if (!respSuper.success) {
      this.loadingError = respSuper.message || 'Ошибка чтения отчета'
      this.isLoading = false
      return respSuper
    }
    if (this.form) {
      this.reportMap = {}
      this.form.reports.forEach(x => {
        this.reportMap[x.id] = x
      })
    }

    this.beforeInit()

    // дерево провайдеров для открытых данных не нужно
    if (!this.openData) {
      const resp3 = await this.supplierStore().load(this.form.id)
      if (!resp3.success) {
        this.loadingError =
          this.supplierStore().loadingError || 'Ошибка получения поставщиков отчета'
        this.isLoading = false
        return resp3
      } else {
        if (initObj.requestOptions && initObj.requestOptions.userGroupId) {
          const uiSettingSupplier = this.supplierStore().mySuppliers.find(
            x => x.userGroup.id === initObj.requestOptions.userGroupId,
          )
          if (uiSettingSupplier) this.supplierStore().selectSupplier(uiSettingSupplier)
        }
      }
      if (!this.selectedSupplier()) {
        this.loadingError = this.supplierStore().loadingError || 'Нет доступа к отчету'
        this.isLoading = false
        return resp3
      }

      const resp4 = await this._selectSupplier(
        this.selectedSupplier(),
        initObj.requestOptions && initObj.requestOptions.subProviderId,
      )
      if (!resp4.success) {
        this.loadingError = this.loadingError || 'Ошибка применения группы пользователей'
        this.isLoading = false
        return resp4
      }
    } else {
      const resp3 = await this.supplierStore().loadRootSupplierForObserving(this.form.id)
      if (!resp3.success) {
        this.loadingError =
          this.supplierStore().loadingError || 'Ошибка получения поставщиков отчета'
        this.isLoading = false
        return resp3
      }
    }

    if (this.openData) {
      this.requestOptions.open = true
    }
    const resp5 = await this.load(this.requestOptions)
    this.initialized = true
    return resp5
  }

  private async _selectSupplier(
    supplier: ISupplierBriefDto,
    defaultSubSupplierId?: number,
  ): Promise<IResponse> {
    if (supplier && supplier.id) {
      const resp = await SupplierApi.getSubSuppliers(supplier.id)
      if (!resp.success) {
        this.loadingError = resp.message
        this.isLoading = false
        return resp
      } else {
        this.mySupplierTree = resp.data
      }
    }

    let defaultSubSupplier = null
    if (defaultSubSupplierId) {
      function walk(p: ISupplierFullDto) {
        if (!p) return
        if (p.id === defaultSubSupplierId) defaultSubSupplier = p
        else if (p.childs) p.childs.forEach(ch => walk(ch))
      }
      walk(this.mySupplierTree)
    }

    this.requestOptions.subSupplierId = defaultSubSupplier ? defaultSubSupplierId : null

    if (defaultSubSupplier)
      this.requestOptions.subSupplier = fromSupplierFullDto(defaultSubSupplier as ISupplierFullDto)
    else this.requestOptions.subSupplier = fromSupplierFullDto(supplier as ISupplierFullDto)

    this.subSupplierForSelector = this.requestOptions.subSupplier
    this.supplierStore().selectSupplier(supplier)
    this.requestOptions.userGroupId =
      this.selectedSupplier() &&
      this.selectedSupplier().userGroup &&
      this.selectedSupplier().userGroup.id

    this.updateDistanceGroups()

    return { success: true }
  }

  protected getDefaultRequestOptions(): IReportDataRequestDto {
    const res = new IReportDataRequestDto()
    res.stageStatus = null
    res.supplierFilter = 'OWN'
    res.includeAscendants = true
    res.includeReferrerFields = true
    return res
  }

  protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
    return DataApi.deleteReportData({
      reportId: this.report.id,
      userGroupId: this.selectedSupplier().userGroup.id,
      rowIds: ids,
    })
  }

  protected async deleteOneRoutine(id: number): Promise<IResponse> {
    return this.deleteManyRoutine([id])
  }

  protected async getListRoutine(requestDto: IReportDataRequestDto): Promise<IResponse> {
    return DataApi.getReportDataWeb({ ...requestDto, includeRowInfo: true })
  }

  load = async (requestOptions: IReportDataRequestDto): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true

    this.page = null

    const nhr =
      this.needsHeaderRestruct(
        this.requestOptions ? this.requestOptions.dicRequestMode : null,
        requestOptions ? requestOptions.dicRequestMode : null,
      ) ||
      requestOptions.includeReferrerFields !== this.requestOptions.includeReferrerFields ||
      requestOptions.includeRowInfoAsColumns !== this.requestOptions.includeRowInfoAsColumns

    if (nhr) {
      console.debug('needsRestruct: ' + (requestOptions ? requestOptions.dicRequestMode : ''))
      const resp = await this.initHeaders(
        requestOptions ? requestOptions.dicRequestMode : null,
        requestOptions ? requestOptions.includeReferrerFields : null,
        requestOptions ? requestOptions.includeRowInfoAsColumns : null,
      )
      if (!resp.success) {
        this.loadingError = resp.message
        this.isLoading = false
        return resp
      }
    }

    requestOptions.filter = this.makeCorrectFilterOrSort(
      requestOptions.filter,
      requestOptions.dicRequestMode,
    ) as IFilterFieldDto[]
    requestOptions.sort = this.makeCorrectFilterOrSort(
      requestOptions.sort,
      requestOptions.dicRequestMode,
    ) as ISortFieldDto[]

    let resp1
    if (this.viewMode === 'TABLE' || this.viewMode === 'GEOMAP') {
      resp1 = await this.loadList(requestOptions)
      if (this.report.columns.find(x => x.colType === 'POSTGIS')) {
        //if (this.viewMode === 'GEOMAP') {
        await this.loadGismapReport(requestOptions)
      }
    } else if (this.viewMode === 'AGGRID') {
      resp1 = await this.loadAgList(requestOptions)
    }
    if (!resp1.success) {
      this.loadingError = resp1.message
      this.isLoading = false
      return resp1
    }
    if (this.viewMode === 'AGGRID') {
      runInAction(() => {
        this.agRowData = []

        const lfhNames = []
        const agLeafFormHeaders = this.leafFormHeaders.filter(
          x => !agIgnoredColumnType(this.columnMap[x.desc.id]),
        )
        agLeafFormHeaders.forEach(fh => {
          lfhNames.push(makeAGGridFieldName(fh))
        })

        resp1.data.content.forEach(dre => {
          const agObj = {}
          for (let i = 0; i < agLeafFormHeaders.length; i++) {
            agObj[lfhNames[i]] = this.agPrimitiveValue(dre, agLeafFormHeaders[i])
          }
          if (this.report == null) {
            // ag summary. add report info
            agObj[AG_SUMMARY_REP_PERIOD_START_NAME] = this.reportMap[dre.reportId].periodStart
              ? new Date(this.reportMap[dre.reportId].periodStart)
              : null
            agObj[AG_SUMMARY_REP_PERIOD_END_NAME] = this.reportMap[dre.reportId].periodEnd
              ? new Date(this.reportMap[dre.reportId].periodEnd)
              : null
            agObj[AG_SUMMARY_REP_REVISION_NAME] = this.reportMap[dre.reportId].revision
          }
          this.agRowData.push(agObj)
        })
      })
    }

    return resp1
  }

  makeCorrectFilterOrSort = (
    filterOrSort: IFilterFieldDto[] | ISortFieldDto[],
    dicRequestMode: DIC_REQUEST_MODE,
  ) => {
    if (!filterOrSort) return []
    if (!dicRequestMode) dicRequestMode = 'ID_CODE_DESC_FIELDS'
    const arr = filterOrSort as IFilterOrSortFieldDto[]
    switch (dicRequestMode) {
      case 'ID_CODE_DESC_FIELDS': // '(Одна колонка) Поля описания'
        return arr.filter(x => !(x.path && x.path.length))
      case 'DRE_CODE_DESC': // (Раздельные колонки) Поля описания
        return arr.filter(x => {
          if (!(x.path && x.path.length)) return true
          else if (x.path && x.path.length === 1) {
            return this.columnMap[x.id] && this.columnMap[x.id].isDesc
          }
        })
      case 'DRE_CODE_DESC_RECURSIVE': // (Раздельные колонки) Поля описания [Рекурсивно]
        return arr.filter(x => {
          if (!(x.path && x.path.length)) return true
          else {
            return this.columnMap[x.id] && this.columnMap[x.id].isDesc
          }
        })
      case 'DRE_ALL_FIELDS': // (Раздельные колонки) Все поля
        return arr.filter(x => !(x.path && x.path.length > 1))
      case 'DRE_ALL_FIELDS_RECURSIVE': // (Раздельные колонки) Все поля [Рекурсивно]
        return arr
      default:
        return []
    }
  }

  selectSupplier = async (supplier: ISupplierBriefDto): Promise<IResponse> => {
    console.debug('MyReportDataStore::selectSupplier')

    const resp = await this._selectSupplier(supplier)
    if (!resp.success) {
      this.loadingError = resp.message
      this.isLoading = false
      return resp
    }

    return await this.load({ ...this.requestOptions, pageNumber: 0 })
  }

  private updateDistanceGroups() {
    const newValue = {}
    DatumDistanceCollection.forEach(x => {
      newValue[x] = {}
    })

    const rootSupplier = this.supplierStore().rootSupplier

    if (
      !(rootSupplier && rootSupplier.id && this.selectedSupplier() && this.selectedSupplier().id)
    ) {
      return
    }

    newValue['root'][rootSupplier.id] = true
    newValue['mine'][this.selectedSupplier().id] = true
    const rootId = rootSupplier.id

    function walk(
      node: ISupplierFullDto,
      currentId: number,
      currentInPath: boolean,
      is1down,
    ): boolean {
      let currentFound: boolean = currentInPath
      if (is1down) {
        newValue['1down'][node.id] = true
      } else if (currentInPath) {
        newValue['farDown'][node.id] = true
      }

      let is1Up = false
      const cFound = currentInPath || node.id === currentId
      currentFound = currentFound || cFound
      if (node.childs) {
        node.childs.forEach(ch => {
          is1Up = is1Up || ch.id === currentId

          const fnd = walk(ch, currentId, cFound, node.id === currentId)
          currentFound = currentFound || fnd
        })
      }

      if (!currentInPath && currentFound) {
        if (is1Up) {
          newValue['1up'][node.id] = true
        } else if (currentFound) {
          if (node.id !== rootId && node.id !== currentId) {
            newValue['farUp'][node.id] = true
          }
        }
      }

      return currentFound
    }

    walk(rootSupplier, this.selectedSupplier().id, false, false)

    this.splsDistance = newValue
  }

  // rows' operations
  operationWithReload = action(async (operation: () => Promise<IResponse>): Promise<IResponse> => {
    this.loadingError = null
    this.isLoading = true
    const resp1 = await operation()
    if (!resp1.success) {
      this.loadingError = resp1.message
      this.isLoading = false
      return resp1
    }
    this.getRootStore().dataEditorStore.close()
    const resp2 = await this.load(this.requestOptions)
    return resp2
  })

  private rugr(ids: number[]): IReportUgRowsDto {
    return {
      reportId: this.report.id,
      userGroupId:
        this.selectedSupplier() &&
        this.selectedSupplier().userGroup &&
        this.selectedSupplier().userGroup.id,
      rowIds: ids,
    }
  }

  saveRow = async (dto: IDataUploadDto): Promise<IResponse<IUpdateResultDto>> => {
    dto.isFrontButton = true
    const resp = await DataApi.addUpdateDataWeb(dto)
    if (resp.success) {
      await this.load(this.requestOptions)
    }
    return resp
  }

  // групповые операции по массиву id
  deleteRowsById = async (ids: number[]): Promise<IResponse<IUpdateResultDto>> => {
    return await this.operationWithReload(async () => {
      return await DataApi.deleteReportData(this.rugr(ids))
    })
  }

  approveRowsById = async (ids: number[]) => {
    return await this.operationWithReload(async () => {
      return await DataApi.approveReportData(this.rugr(ids))
    })
  }

  disapproveRowsById = async (ids: number[]) => {
    return await this.operationWithReload(async () => {
      return await DataApi.disapproveReportData(this.rugr(ids))
    })
  }

  moveUpRowsById = async (ids: number[]) => {
    return await this.operationWithReload(async () => {
      const dto: IDataMoveUpDto = this.rugr(ids) as IDataMoveUpDto
      return await DataApi.moveUpReportData(dto)
    })
  }

  moveDownRowsById = async (ids: number[]) => {
    return await this.operationWithReload(async () => {
      return await DataApi.moveDownReportData(this.rugr(ids))
    })
  }

  moveToFirstRowsById = async (ids: number[]) => {
    return await this.operationWithReload(async () => {
      return await DataApi.moveToFirstReportData(this.rugr(ids))
    })
  }

  // групповые операции по массиву id
  setSupplierFirstById = async (ids: number[], supplierFirstId: number): Promise<IResponse> => {
    return await this.operationWithReload(async () => {
      const requestDto: IDataSetSupplierFirstDto = this.rugr(ids) as IDataSetSupplierFirstDto
      requestDto.supplierFirst = supplierFirstId
      return await DataApi.setSupplierFirst(requestDto)
    })
  }
  // групповые операции по фильтру
  deleteRowsByFilter = async (
    request: IReportDataRequestDto,
  ): Promise<IResponse<IUpdateResultDto>> => {
    return await this.operationWithReload(async () => {
      return await DataApi.deleteReportDataAll(request)
    })
  }

  approveRowsByFilter = async (request: IReportDataRequestDto) => {
    return await this.operationWithReload(async () => {
      return await DataApi.approveReportDataAll(request)
    })
  }

  disapproveRowsByFilter = async (request: IReportDataRequestDto) => {
    return await this.operationWithReload(async () => {
      return await DataApi.disapproveReportDataAll(request)
    })
  }

  moveUpRowsByFilter = async (request: IReportDataRequestDto) => {
    return await this.operationWithReload(async () => {
      return await DataApi.moveUpReportDataAll(request)
    })
  }

  moveDownRowsByFilter = async (request: IReportDataRequestDto) => {
    return await this.operationWithReload(async () => {
      return await DataApi.moveDownReportDataAll(request)
    })
  }

  moveToFirstRowsByFilter = async (request: IReportDataRequestDto) => {
    return await this.operationWithReload(async () => {
      return await DataApi.moveToFirstReportDataAll(request)
    })
  }

  setSupplierFirstByFilter = async (request: ISetSupplierFirstRequestDto): Promise<IResponse> => {
    return await this.operationWithReload(async () => {
      return await DataApi.setSupplierFirstAll(request)
    })
  }
  // ----------- \операции

  getRowDistance(datum: IDataRowReadFullDto): DatumDistanceEnum {
    if (!this.selectedSupplier() || !datum) return null
    const me = this.selectedSupplier()
    const curpId = datum?.supplierCurrent?.id
    for (let i = 0; i < DatumDistanceCollection.length; i++) {
      const map = this.splsDistance[DatumDistanceCollection[i]]
      if (map && map[curpId]) return DatumDistanceCollection[i]
    }
  }

  private loadAgList = async (requestOptions: IReportDataRequestDto): Promise<IResponse> => {
    this.requestOptions = requestOptions
    const reqDto = JSON.parse(JSON.stringify(this.requestOptions)) as IReportDataRequestDto
    reqDto.pageNumber = 0
    reqDto.pageSize = AG_MAX_DATA_COUNT
    reqDto.includeRowInfo = false
    const resp = await DataApi.getReportDataWeb(reqDto)
    this.loadingError = message(resp)
    this.isLoading = false
    return resp
  }

  setViewMode = async (value: DATA_VIEW_MODE): Promise<IResponse> => {
    if (this.viewMode === value) return { success: true }
    this.viewMode = value
    if (value !== 'AGGRID') this.agRowData = []
    const resp = await this.load(this.requestOptions)
    return resp
  }

  private agPrimitiveValue(dre: IDataRowReadFullDto, fh: IFormHeaderDto): string | number | Date {
    const datum = getByPath(dre, fh.desc.id, fh.path)
    const column = this.columnMap[fh.desc.id] as IColumnDescDto
    switch (column.colType) {
      case 'STRING':
      case 'FLOAT':
      case 'INT':
        return datum
      case 'TIMESTAMP':
        if (!datum) return null
        return new Date(datum)
      case 'BOOLEAN':
        if (datum === false || datum === 'false') return 'нет'
        else if (datum === true || datum === 'true') return 'да'
        else return null
      case 'REF':
        if (!datum) return null
        if (datum.code === datum.name) return datum.code
        else return `${datum.name} (${datum.code})`
      default:
        return null
    }
  }

  loadGismapReport = async (requestOptions: IReportDataRequestDto) => {
    const dicRequestMode =
      requestOptions.dicRequestMode && requestOptions.dicRequestMode.startsWith('DRE')
        ? requestOptions.dicRequestMode
        : 'DRE_CODE_DESC'
    const resp = await MBGeoApi.getReportGisInfo({
      ...requestOptions,
      dicRequestMode: dicRequestMode,
    })
    if (resp.success) {
      runInAction(() => {
        this.gismapReport = resp.data
        const des = this.getRootStore().dataEditorStore
        des.setEeAvailableGeoColumns(this.gismapReport.gismapColumns.map(gc => gc.columnDesc))
      })
    }
    return resp
  }

  uploadGeodataFile = async (file: File, onProgress) => {
    this.geodataUploadPreview = null;
    const resp = await GeoImportApi.uploadFile(file, onProgress)
    this.geodataUploadPreview = resp.data;
    return resp;
  }

  loadGeodataToForm = async (dto: IGeodataUploadDto) => {
    const resp = await GeoImportApi.uploadData(dto)
    if (resp.success) {
      this.load(this.requestOptions)
    }
    return resp
  }

  async geoExportStart({exportFormat, geomColumnId, pageNumber, pageSize, fieldRequestMode, dicRequestMode}:
                           {exportFormat: GDAL_EXPORT_FORMAT, geomColumnId: number, pageNumber: number,
                             pageSize: number, fieldRequestMode: FIELD_REQUEST_MODE,
                           dicRequestMode: DIC_REQUEST_MODE}) {
    this.geoExportState = {
      guid: null,
      message: null,
      status: "IN_PROCESS",
    }
    const resp = await GeoExportApi.start({...this.requestOptions, exportFormat, geomColumnId,
      pageSize, pageNumber, fieldRequestMode, dicRequestMode})
    if (!resp.success) {
      this.geoExportState = {...this.geoExportState, status: 'ERROR', message: resp.message}
      return
    } else {
      this.geoExportState.guid = resp.data
    }

    // опрос статуса раз в секунду
    let getStatusErrorCounter = 0;
    const statusFn = () => {
      console.log('zzzz 0, guid = this.geoExportState.guid')
      GeoExportApi.getStatus(this.geoExportState.guid).then(resp => {
        console.log('zzzz got, guid = this.geoExportState.guid')
        if (!resp.success) {
          console.log('zzzz getStatus ERROR by COUNTER')
          if (getStatusErrorCounter++ > 10) {
            this.geoExportState = {
              ...this.geoExportState,
              status: 'ERROR',
              message: 'Превышено количество попыток запроса статуса.\n' + resp.message
            }
          }
        } else {
          console.log('zzzz got status' + resp.data?.exportStatus)
          if (resp.data?.exportStatus === 'READY') {
            GeoExportApi.download(this.geoExportState?.guid).then(respDown => {
              console.log('zzzz start download')
              if (respDown.success) {
                this.geoExportState = {
                  ...this.geoExportState,
                  status: resp.data.exportStatus,
                  message: resp.data.message,
                }
              } else {
                this.geoExportState = {
                  ...this.geoExportState,
                  status: 'ERROR',
                  message: 'Файл сформирован, ошибка в процессе скачивания.\n' + respDown.message
                }
              }
            })
          } else if (resp.data?.exportStatus === 'ERROR') {
            this.geoExportState = {
              ...this.geoExportState,
              status: resp.data.exportStatus,
              message: resp.data.message,
            }
          } else if (resp.data?.exportStatus === 'IN_PROCESS') {
            setTimeout(statusFn, 1000)
          }
        }
      })
    }

    setTimeout(statusFn, 1000)

  }

  geoExportCancel() {
    this.geoExportState = null
  }
}

export default MyReportDataStore
