import ISortFieldDto from './ISortFieldDto'
import IPageRequestDto from './IPageRequestDto'
import IFilterFieldDto from './IFilterFieldDto'

// Pss - Paging, Search, Sort
export default class IPssRequestDto extends IPageRequestDto{
  search?: string
  sort?: ISortFieldDto[]
  filter?: IFilterFieldDto[]

  constructor() {
    super()
    this.sort = []
    this.filter = []
  }
}

export function sortOf(str: string): ISortFieldDto[] {
  if (!str) return []
  const res = []
  str.split('|').forEach(x => {
    const arr = x.split(',')
    if (arr[0]) {
      res.push({
        columnName: arr[0].trim(),
        asc: arr[1] && arr[1].trim().toLowerCase() === 'asc',
      })
    }
  })
  return res
}

export function sortAsStr(dto: IPssRequestDto): string {
  if (!dto || !dto.sort) return ''
  const arr = []
  dto.sort.forEach(x => {
    arr.push(`${x.columnName},${x.asc ? 'asc' : 'desc'}`)
  })
  return arr.join('|')
}