import React, { FunctionComponent } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Divider, Drawer, makeStyles } from '@material-ui/core'
import {
  Archive as ArchiveIcon,
  Globe,
  Icon,
  Layout as LayoutIcon,
  Map as MapIcon,
  Server,
  Settings as SettingsIcon,
  Tablet,
  Tag as TagIcon,
  User as UserIcon,
  Users as UsersIcon,
} from 'react-feather'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import { useSwipeable } from 'react-swipeable'
import { isMobile } from 'react-device-detect'
import useStore from '../../../../context/RootStoreContext'
import NavItem from './NavItem'
import { DEFAULT_SUBSYSTEM, SubsystemEnum } from '../../../../util/constants'
import { SatelliteOutlined } from '@material-ui/icons'
import sidebarOrnament1 from 'src/assets/img/sidebar-ornament-1.png'
import sidebarOrnament2 from 'src/assets/img/sidebar-ornament-2.png'

export interface INavItem {
  title?: string
  subheader?: string
  icon?: Icon
  href?: string
  info?: React.ComponentType
  items?: INavItem[]
  accessKey?: string
  depth?: number
  name?: string
}

const navDefault: INavItem[] = [
  {
    title: 'Мои данные',
    icon: LayoutIcon,
    href: `/${DEFAULT_SUBSYSTEM}/user/forms`,
  },
  {
    title: 'Общие данные',
    icon: ArchiveIcon,
    href: `/${DEFAULT_SUBSYSTEM}/user/openRegisters`,
  },
  {
    title: 'Карты',
    icon: Globe,
    href: `/${DEFAULT_SUBSYSTEM}/user/maps/list`,
  },
  /*{
    title: 'Geo TIFF',
    icon: SatelliteOutlined,
    href: `/${DEFAULT_SUBSYSTEM}/user/geotiff/list`,
  },*/
]

export const adminDefaultNavConfig: INavItem[] = [
  {
    title: 'Настройки карт',
    icon: MapIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/mapsetting`,
    name: 'mapsetting',
  },
  {
    title: 'Подложки',
    icon: undefined,
    href: `/${DEFAULT_SUBSYSTEM}/admin/mapsetting/backgrounds`,
    depth: 2,
    name: 'backgrounds',
  },
  {
    title: 'Спрайты',
    icon: undefined,
    href: `/${DEFAULT_SUBSYSTEM}/admin/mapsetting/sprite`,
    depth: 2,
    name: 'sprite',
  },
  {
    title: 'Маршруты прокси',
    icon: undefined,
    href: `/${DEFAULT_SUBSYSTEM}/admin/mapsetting/proxyItems`,
    depth: 2,
    name: 'proxyItems',
  },
  {
    title: 'Тэги',
    icon: TagIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/tags`,
    name: 'tags',
  },
  {
    title: 'Группы пользователей',
    icon: UsersIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/userGroups`,
    name: 'userGroups',
  },
  {
    title: 'Пользователи',
    icon: UserIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/users`,
    name: 'users',
  },
  {
    title: 'Профили пользователей',
    icon: UserIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/users/userProfiles`,
    name: 'userProfiles',
    depth: 2,
  },
  {
    title: 'История авторизаций',
    icon: UserIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/users/loginActions`,
    name: 'loginActions',
    depth: 2,
  },
  {
    title: 'Настройки',
    icon: SettingsIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/setting`,
    name: 'setting',
  },
  /*{
    title: 'Группы документов',
    icon: undefined,
    href: `/${DEFAULT_SUBSYSTEM}/admin/emsed/emsedGroups/`,
    depth: 2,
    name: 'emsedGroups',
  },
  {
    title: 'Шаблоны документов',
    icon: undefined,
    href: `/${DEFAULT_SUBSYSTEM}/admin/emsed/emsedTemplates/`,
    depth: 2,
    name: 'emsedTemplates',
  },
  {
    title: 'Документы',
    icon: undefined,
    href: `/${DEFAULT_SUBSYSTEM}/admin/emsed/emsedDocs/`,
    depth: 2,
    name: 'emsedDocs',
  },*/
]

export const userGroupAdminShudNavConfig: INavItem[] = [
  {
    title: 'Группы пользователей',
    icon: UsersIcon,
    href: `/${DEFAULT_SUBSYSTEM}/admin/userGroups`,
  },
]

const useStyles = makeStyles(({ palette }) => ({
  blueSidebar: {
    backgroundColor: palette.primary.main,
    borderRight: 'none',
    color: '#fff',
    '&::before, &::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: '0',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    '&::before': {
      height: '320px',
      bottom: '0',
      backgroundImage: `url(${sidebarOrnament1})`,
      backgroundPosition: 'bottom right',
    },
    '&::after': {
      height: '320px',
      bottom: '110px',
      backgroundImage: `url(${sidebarOrnament2})`,
      backgroundPosition: 'bottom left',
    },
  },
  sidebarBox: {
    padding: '0 20px',
    position: 'relative',
    zIndex: 1, // чтобы орнамент не перекрывал содержимое
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  navbarOGV: {
    padding: '25px 10px',
    fontFamily: 'SF UI Display',
    fontWeight: 700,
  },
}))

interface INavBarProps {
  subsystem: SubsystemEnum
  openMobile: boolean
  onMobileClose: () => void
}

const NavBar: FunctionComponent<INavBarProps> = ({ subsystem, openMobile, onMobileClose }) => {
  console.debug('NavBar...')

  const classes = useStyles()

  const { authStore } = useStore()

  const userWithDetails = authStore.authenticatedUserDetails

  function renderNavItems(items: INavItem[]) {
    return items.map(item => {
      if (
        !item.accessKey ||
        (userWithDetails && userWithDetails.options && userWithDetails.options[item.accessKey])
      ) {
        return (
          <NavItem
            depth={item.depth || 1}
            href={item.href}
            icon={item.icon}
            key={item.title}
            info={item.info}
            title={item.title}
          />
        )
      } else {
        return <></>
      }
    })
  }

  const handler1SidebarSwipe = useSwipeable({
    // чтобы можно было на планшете\мобилке свайпнуть сайдбар
    onSwipedLeft: () => {
      if (isMobile) {
        onMobileClose()
      }
    },
    swipeDuration: 300,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  const content = (
    <Box height="100%" display="flex" flexDirection="column" className={classes.sidebarBox}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider style={{ margin: '18px 0' }} />
        {[DEFAULT_SUBSYSTEM, 'nsi'].includes(subsystem) && renderNavItems(navDefault)}
        <Divider style={{ margin: '18px 0' }} />
      </PerfectScrollbar>
    </Box>
  )

  return (
    <Drawer
      className="print-hide"
      anchor="left"
      classes={{ paper: clsx(classes.desktopDrawer, classes.blueSidebar) }}
      onClose={onMobileClose}
      open={openMobile}
      variant="persistent"
      {...handler1SidebarSwipe}
    >
      {content}
    </Drawer>
  )
}

export default observer(NavBar)
