export default class IPageRequestDto {
  pageNumber?: number
  pageSize?: number

  constructor() {
    this.pageNumber = 0
    this.pageSize = 25
  }

}
