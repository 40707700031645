import IIdNameDto from '../common/IIdNameDto'
import { STAGE_STATUS } from '../../enums/STAGE_STATUS'

export default interface IDataRowReadFullDto {
  id?: number
  supplierFirst?: IIdNameDto
  supplierChange?: IIdNameDto
  editorUser?: IIdNameDto
  docStage?: number
  supplierCurrent?: IIdNameDto
  stageStatus?: STAGE_STATUS
  created?: Date
  updated?: Date
  // for reading: object is one of String/Number/Date/Boolean/IdCodeNameDTO (for refValues)
  values?: object // Map<Long, Object>
}

export type DatumDistanceEnum = 'mine' | '1up' | 'farUp' | '1down' | 'farDown' | 'root'
export const DatumDistanceCollection: DatumDistanceEnum[] = [
  'mine',
  '1up',
  'farUp',
  '1down',
  'farDown',
  'root',
]
