import { action, observable, makeObservable } from 'mobx'
import IDataRowReadFullDto from '../../dto/data/IDataRowReadFullDto'
import RootStoreModel from '../_root/RootStoreModel'
import IColumnDescDto from '../../dto/columndesc/IColumnDescDto'
import IFormColumnsDto from '../../dto/form/IFormColumnsDto'
import IDataRowEditDto, { getCodeValue } from '../../dto/data/IDataRowEditDto'
import IDatumGC from '../../views/components/MapLibre/ItemInfo/DatumGC'
import IRootStore from '../_root/type'

class DataEditorStore extends RootStoreModel {
  infoIsLoading = false

  editing: boolean

  previewDatum: IDataRowEditDto // здесь геометрия флагами и, возможно, не все поля
  previewGcDatum: IDatumGC
  initialDatum: IDataRowReadFullDto // здесь полный объект
  opened: boolean = false

  // map embedded editor
  // кучка объектов, которые попали под клик на карте (непонятно какой из них выбран)
  eeSelectedItems: IDatumGC[] = []
  // выбранная для редактирования гео-голонка
  eeModifiedGeojsons: any = {} // карта column.id - geojson
  eeEditingColumn: IColumnDescDto = null
  // текущий редактируемый geojson
  eeEditingGeojson: any = null
  // список гео-полей формы
  eeAvailableGeoColumns: IColumnDescDto[] = []
  eeZoomToSelected: boolean

  constructor(root: () => IRootStore) {
    super(root)

    makeObservable(this, {
      infoIsLoading: observable,
      setInfoIsLoading: action,
      setOpened: action,

      editing: observable,
      previewDatum: observable,
      previewGcDatum: observable,
      initialDatum: observable,
      opened: observable,

      eeSelectedItems: observable,
      eeEditingColumn: observable,
      eeEditingGeojson: observable,
      eeModifiedGeojsons: observable,
      eeAvailableGeoColumns: observable,
      eeZoomToSelected: observable,

      openPreview: action,
      openPreviewGC: action,
      fillPreview: action,
      openForCopy: action,
      openFullLoaded: action,
      close: action,

      eeStartEdit: action,
      eeEditorModeOff: action,
      eeSelectEditingColumn: action,
      setEeSelectedItems: action,
      setEeAvailableGeoColumns: action,
      updateDatumWithGeometry: action,
    })
  }

  setInfoIsLoading(payload: boolean) {
    this.infoIsLoading = payload
  }

  setOpened(payload: boolean) {
    this.opened = payload
  }

  setEeAvailableGeoColumns = (columns: IColumnDescDto[]) => {
    this.eeAvailableGeoColumns = columns
    let changeEeEditingColumn = true
    if (
      this.eeEditingColumn &&
      this.eeAvailableGeoColumns.find(x => x.id === this.eeEditingColumn.id)
    )
      changeEeEditingColumn = false
    if (changeEeEditingColumn) {
      let defaultGeoCol = this.eeAvailableGeoColumns.find(x => x.internal)
      if (!defaultGeoCol) defaultGeoCol = this.eeAvailableGeoColumns[0]
      this.eeSelectEditingColumn(defaultGeoCol)
    }
  }
  setEeSelectedItems = (data: IDatumGC[]) => {
    this.eeSelectedItems = data
    this.previewDatum = null
    this.previewGcDatum = null
    this.initialDatum = null
    this.eeEditingGeojson = null
    this.eeModifiedGeojsons = null
    this.editing = false
    this.eeZoomToSelected = false

    this.opened = true
  }
  updateDatumWithGeometry = (columnId: number, geojson: string | any) => {
    if (!this.eeModifiedGeojsons) this.eeModifiedGeojsons = {}
    if (!geojson) this.eeModifiedGeojsons[columnId] = null
    else
      this.eeModifiedGeojsons[columnId] =
        typeof geojson === 'object' ? JSON.stringify(geojson) : geojson
  }
  getGeojsonFromEditingDatum = (columnId: number) => {
    return this.initialDatum && this.initialDatum.values && this.initialDatum.values[columnId]
  }
  eeStartEdit = () => {
    if (this.eeAvailableGeoColumns && this.initialDatum && this.initialDatum.values) {
      this.eeAvailableGeoColumns.forEach(x => {
        this.updateDatumWithGeometry(x.id, this.initialDatum.values[x.id])
      })
    }

    if (this.eeEditingColumn) {
      let geojson = this.getGeojsonFromEditingDatum(this.eeEditingColumn.id)
      if (typeof geojson === 'string') {
        try {
          geojson = JSON.parse(geojson)
        } catch (e) {}
      }
      this.eeEditingGeojson = geojson
    }
    this.editing = true
  }
  eeEditorModeOff = () => {
    this.eeSelectedItems = []
    this.eeEditingGeojson = null
  }
  eeSelectEditingColumn = (column: IColumnDescDto) => {
    this.eeEditingColumn = column
    this.eeEditingGeojson = column ? this.getGeojsonFromEditingDatum(column.id) : null
  }
  // /map embedded editor

  openForCopy = (
    datum: IDataRowReadFullDto,
    formColumns: IColumnDescDto[],
    dicsForms: IFormColumnsDto[],
  ) => {
    this.openPreview(datum)
    this.initialDatum = JSON.parse(JSON.stringify(datum))
    this.editing = true
  }

  openFullLoaded = (
    datum: IDataRowReadFullDto,
    formColumns: IColumnDescDto[],
    dicsForms: IFormColumnsDto[],
  ) => {
    this.fillPreview(datum, formColumns, dicsForms)
    this.opened = true
  }

  openPreviewGC = (gcDatum: IDatumGC, zoomToSelected?: boolean) => {
    this.previewGcDatum = gcDatum
    this.openPreview(gcDatum.scFlatDatum, zoomToSelected)
  }

  openPreview = (datum: IDataRowEditDto, zoomToSelected?: boolean) => {
    console.debug('4444')

    this.initialDatum = null
    this.eeModifiedGeojsons = null
    this.eeZoomToSelected = false
    if (datum) {
      this.editing = false
      this.previewDatum = JSON.parse(JSON.stringify(datum))
      this.eeZoomToSelected = zoomToSelected
    } else {
      this.editing = true
      this.previewDatum = null
      this.initialDatum = null
    }

    this.opened = true
  }

  fillPreview = (
    datum: IDataRowReadFullDto,
    formColumns: IColumnDescDto[],
    dicsForms: IFormColumnsDto[],
  ) => {
    this.initialDatum = JSON.parse(JSON.stringify(datum))
    // restore code/desc from DRE
    if (this.initialDatum && this.initialDatum.values && formColumns && dicsForms) {
      formColumns.forEach(fc => {
        if (fc.colType === 'REF' && fc.colName !== 'parent_id') {
          const ref = this.initialDatum.values[fc.id]
          if (ref && ref.values) {
            const refForm = dicsForms.filter(x => x.id === fc.refForm.id)[0]
            if (refForm && refForm.columns) {
              const descValues = []
              refForm.columns.forEach(x => {
                if (x.isUniqueKey) ref.code = ref.values[x.id]
                else if (x.isDesc) {
                  let v = ref.values[x.id]
                  if (typeof v === 'object') {
                    v = getCodeValue(v, {})
                  }
                  if (v) descValues.push(v)
                }
              })
              ref.desc = descValues.filter(x => !!x).join(' / ')
            }
          }
        }
      })
    }
    // /restore code/desc from DRE
  }

  close = () => {
    this.editing = false
    this.opened = false
    this.previewDatum = null
    this.previewGcDatum = null
    this.initialDatum = null
    this.eeEditorModeOff()
  }
}

export default DataEditorStore
