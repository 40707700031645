import { copyAndSpread } from '../../../util/objects'
import IBackgroundDto from '../../../dto/gismap/IBackgroundDto'
import axios from 'axios'
import IGismapColumnDescDto, { flatten, leafGC } from '../../../dto/gismap/IGismapColumnDescDto'
import IColumnDescDto from '../../../dto/columndesc/IColumnDescDto'
import IReportDataRequestDto from '../../../dto/report/IReportDataRequestDto'
import { API_BASE_URL } from '../../../config'
import { objToBase64RequestString } from '../../../util/str-util'
import ILayerOption from './LayerSelector/ILayerOption'
import IGisLayerRequestDto from '../../../dto/gismap/IGisLayerRequestDto'
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from '../../../api/common/ServerApi'
import IGeoTiffBriefDto from '../../../dto/gismap/IGeoTiffBriefDto'
import IExternalLayerDto from '../../../dto/gismap/IExternalLayerDto'

export const MAP_DIRECTIONS_SOURCE = 'maplibre-gl-directions'
export const MAP_DRAW_PREFIX = 'gl-draw-'
export const RGIS_TEMP = 'data-temp'
export const RGIS_PREFIX = 'data-'
export function makeDataSourceName(gc: IGismapColumnDescDto): string {
  return RGIS_PREFIX + gc.virtualKey
}

export function makeLayerName(gc: IGismapColumnDescDto): string {
  return makeDataSourceName(gc) + '-layer'
}

export function makeGeoTiffDataSourceName(gt: IGeoTiffBriefDto): string {
  return `${RGIS_PREFIX}geotiff.${gt.id}`
}

export function makeGeoTiffLayerName(gt: IGeoTiffBriefDto): string {
  return makeGeoTiffDataSourceName(gt) + '-layer'
}

export function makeExternalLayerDataSourceName(exl: IExternalLayerDto): string {
  return `${RGIS_PREFIX}extlayer.${exl.id}`
}

export function makeExternalLayerLayerName(exl: IExternalLayerDto, ind: number): string {
  let res = makeExternalLayerDataSourceName(exl) + '-layer'
  if (ind !== null) res += '-' + ind
  return res
}

export async function backgroundStyle(background: IBackgroundDto): Promise<any> {
  const defaultStyle = {
    version: 8,
    sources: {
      background: {
        type: 'raster',
        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
      },
    },
    layers: [
      {
        id: 'background',
        type: 'raster',
        source: 'background',
        minzoom: 0,
        maxzoom: 19,
      },
    ],
    glyphs: '/public/glyphs/{fontstack}/{range}.pbf',
    //"glyphs": "/public/glyphs/0-255.pbf"
  }

  let style: any
  if (!(background && background.style)) {
    style = defaultStyle
  } else {
    style = background.style
    switch (typeof style) {
      case 'string':
        try {
          style = JSON.parse(style)
        } catch (e) {
          //видимо, это урл на стиль
          const resp = await axios.get(style)
          style = resp.data
        }
        return style
      // no break, style is object now
      case 'object':
        return style
      default:
        return defaultStyle
    }
  }

  return style
}

export async function createDefaultMap({ container, extendStyle, background }) {
  const style = await backgroundStyle(background)
  // @ts-ignore
  return new maplibregl.Map({
    container: container,
    style: copyAndSpread(style, extendStyle),
    transformRequest: (url, resourceType) => {
      if (url.indexOf(API_BASE_URL) >= 0) {
        let auth = axios.defaults.headers.common.Authorization
        if (auth) console.log('BINGO')
        if (!auth) auth = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME)
        return {
          url: url,
          headers: {
            Authorization: 'Bearer ' + auth,
          },
          credentials: 'include', // Include cookies for cross-origin requests
        }
      }
    },
  })
}

export function createDefaultMapboxDrawStyles(dbConfig: any) {
  const s = dbConfig.mapdrawStyle
  return typeof s === 'string' ? JSON.parse(s) : s
}

export function getTemporaryStyle(dbConfig: any): any {
  const s = dbConfig.selectedStyle
  const res = typeof s === 'string' ? JSON.parse(s) : s
  let i = 0
  res.forEach(x => {
    x.id = RGIS_TEMP + '-' + i
    x.source = RGIS_TEMP
    i++
  })
  return res
}

function makeDefaultLayers(dbConfig: any, gc: IGismapColumnDescDto): any[] {
  const s = dbConfig.defaultStyle[leafGC(gc).columnDesc.postgisShape]
  return typeof s === 'string' ? JSON.parse(s) : s
}

function getCustomStyles(dbConfig: any, gc: IGismapColumnDescDto) {
  try {
    const customStyles = JSON.parse(leafGC(gc).maplibreStyle)
    return Array.isArray(customStyles) ? customStyles : [customStyles]
  } catch (e) {}

  return makeDefaultLayers(dbConfig, leafGC(gc))
}

export function makeLayersFor(dbConfig: any, gc: IGismapColumnDescDto) {
  const customStyles = getCustomStyles(dbConfig, gc)
  return customStyles.map((item, index) => {
    const id = makeLayerName(gc) + '-' + index
    const source = makeDataSourceName(gc)
    return { ...item, id, source }
  })
}

export function getSourceZooms(column: IColumnDescDto): { min: number; max: number } {
  try {
    const cfg = JSON.parse(column.maplibreSourceConfig)
    return {
      min: cfg.minzoom >= 0 ? cfg.minzoom : 0,
      max: cfg.msxzoom <= 22 ? cfg.maxzoom : 22,
    }
  } catch (e) {
    return {
      min: 0,
      max: 22,
    }
  }
}

const tilerProviderFilterValues = {
  ALL: 'A',
  OWN: 'O',
  OWN_AND_CHILD: 'C',
  OWN_AND_DESCENDANT: 'D',
}

export function makeTilesUrl(
  requestOptions: IReportDataRequestDto,
  layerOption: ILayerOption,
): string | string[] {
  const gc = layerOption.gismapColumn
  if (!requestOptions) requestOptions = new IReportDataRequestDto()
  const reqOpts = JSON.parse(JSON.stringify(requestOptions)) as IGisLayerRequestDto

  reqOpts.reportId = gc.report.id
  reqOpts.fieldRequestMode = 'GEO_DESC'
  reqOpts.forEdit = layerOption.forEdit
  reqOpts.open = layerOption.open
  //reqOpts.format = "VECTOR";

  const fltn = flatten(gc)
  const columnId = fltn[fltn.length - 1].columnDesc.id
  reqOpts.columnIds = fltn.map(x => x.columnDesc.id)

  /*const params = [
        { key: 'provider', value: tilerProviderFilterValues[reqOpts.providerFilter] },
        { key: 'status', value: reqOpts.stageStatus },
        { key: 'ascendants', value: reqOpts.includeAscendants ? 1 : 0 },
        { key: 'subprovider', value: reqOpts.subProviderId },
        { key: 'filter', value: (reqOpts.filter && reqOpts.filter.length) ?
                JSON.stringify(reqOpts.filter) : null },
    ];
    const paramString = params.filter(x => !!x.value)
        .map(x => `${x.key}=${x.value}`).join('&');*/

  const base64 = objToBase64RequestString(reqOpts)
  const urlEncoded = encodeURI(base64)

  const res = [
    `${window.location.origin}${API_BASE_URL}gismap/vectortiles/user/${gc.columnDesc.id}/{z}/{y}/{x}.pbf?requestOptions=${urlEncoded}&srid=3857`,
    //`${window.location.origin}/tiles/${gc.report.id}/${reqOpts.userGroupId || 0}/${columnId}/{z}/{x}/{y}.mvt?${paramString}`,
  ]

  //  'SGVsbG8gd29ybGQ='

  return res
}

export function boundsForPolygonAsText(bbox: string): number[] {
  bbox = bbox.replaceAll('POLYGON', '').replaceAll('(', '').replaceAll(')', '')
  let minx = null,
    maxx = null,
    miny = null,
    maxy = null
  const ps = bbox.split(',')
  ps.forEach(p => {
    p = p.trim()
    const a = p.split(' ')
    const x = Number(a[0])
    const y = Number(a[1])
    if (minx === null || minx > x) minx = x
    if (maxx === null || maxx < x) maxx = x
    if (miny === null || miny > y) miny = y
    if (maxy === null || maxy < y) maxy = y
  })
  if (minx === null || maxx === null || miny === null || maxy === null) return undefined
  else return [minx, miny, maxx, maxy]
}

function renderUnknownImage() {
  const width = UNKNOWN_IMAGE_SIZE
  const bytesPerPixel = 4 // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
  const data = new Uint8Array(width * width * bytesPerPixel)

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < width; y++) {
      const offset = (y * width + x) * bytesPerPixel
      if (x === y || x === width - 1 - y) {
        data[offset + 0] = 0 // red
        data[offset + 1] = 0 // green
        data[offset + 2] = 0 // blue
        data[offset + 3] = 200 // alpha
      } else {
        data[offset + 0] = 200 // red
        data[offset + 1] = 200 // green
        data[offset + 2] = 200 // blue
        data[offset + 3] = 200 // alpha
      }
    }
  }
  return data
}
export const UNKNOWN_IMAGE_SIZE = 16
export const UNKNOWN_IMAGE = renderUnknownImage()
