import { GeocoderApi } from 'src/api/GeocoderApi'
import { MaplibreStore } from 'src/store/map-store'
import { Feature } from 'geojson'

export class RouterService {
  private readonly repository: MaplibreStore['routerSource']

  constructor(repository: MaplibreStore['routerSource']) {
    this.repository = repository
  }

  /**
	 * 14.1. Получает фичи объекта по api геокодера;
	 * @param name - наименование объекта;
	 * @returns фичи;
	 */
  async fetchFeatures(name: string, url: string) {
    return await GeocoderApi.searchWithGeo(name, url).then(({ features }) => features)
  }

  /** 14.2. Получает все фичи слоя маршрута. */
  getRouteSourceFeatures() {
    return this.repository.data.features
  }

  /**
	 * 14.3. Удаляет фичу из хранилища.
	 * @param id - идентификатор фичи;
	 */
  rmFeature(id: string | number) {
    const index = this.repository.data.features.findIndex((item) => item.id === id)
    if (index !== -1) {
      this.repository.data.features.splice(index, 1)
      this.repository.setFeatures(this.repository.data.features)
    }
  }

  /**
	 * 14.4. Добавляет фичу в хранилище.
	 * @param feature - фича;
	 */
  addFeature(feature: Feature) {
    const features = [...this.repository.data.features, feature]
    this.repository.setFeatures(features)
  }
}