import { GeoCadastrApi } from 'src/api/GeoCadastrApi'
import MBGeoApi from 'src/api/MBGeoApi'
import { FindLayersByGeometryPayload } from 'src/dto/gismap/FindLayersByGeometryPayload'
import { WhatsHereCadastrResponse } from 'src/dto/gismap/WhatsHereCadastrResponse'
import { WhatsHereStore } from 'src/store/map-store'
import { WhatsHereCadastr, FormLayerItem } from 'src/store/map-store/types'
import IFindLayersByGeometryResponse from '../dto/gismap/IFindLayersByGeometryResponse'

export class WhatsHereService {
  private readonly repository: WhatsHereStore

  constructor(store: WhatsHereStore) {
    this.repository = store
  }

  addCoords(coords: [number, number]) {
    this.repository.coordinates.push(coords)
  }

  changeCoords(coords: [number, number], index: number) {
    this.repository.coordinates[index] = coords
  }

  rmCoords(index: number) {
    this.repository.coordinates.splice(index, 1)
  }

  async fetchInfo<Payload extends FindLayersByGeometryPayload>(geometry: Payload) {
    try {
      this.repository.layersDataIsLoading = true
      const { data } = await MBGeoApi.findLayersByGeometry<Payload>(geometry)
      return data
    } catch (e) {
      throw e
    } finally {
      this.repository.layersDataIsLoading = false
    }
  }

  async findInCadastr<Payload extends FindLayersByGeometryPayload>(geometry: Payload) {
    try {
      this.repository.cadastrDataIsLoading = true
      const data = await GeoCadastrApi.findObjectByGeometry<Payload>(geometry)
      return data
    } catch (e) {
      throw e
    } finally {
      this.repository.cadastrDataIsLoading = false
    }
  }

  getCoords() {
    return this.repository.coordinates
  }

  getMode() {
    return this.repository.mode
  }

  cadastrResponseToCadastrData(response: WhatsHereCadastrResponse): WhatsHereCadastr {
    return {
      buildRecords: response.buildRecords.content,
      cadastralBlocks: response.cadastralBlocks.content,
      landRecords: response.landRecords.content,
      zoneAndTerritoryRecords: response.zoneAndTerritoryRecords.content,
    }
  }

  setCadastrData(data: WhatsHereCadastr) {
    this.repository.cadastrData = data
  }

  setLayersData(data: IFindLayersByGeometryResponse) {
    this.repository.formLayersData = data.forms
    this.repository.geoTiffLayersData = data.geoTiffs
  }

  setPolygonCadastrData(data: WhatsHereCadastr) {
    this.repository.polygonCadastrData = data
  }

  setPolygonLayersData(data: IFindLayersByGeometryResponse) {
    this.repository.polygonFormLayersData = data.forms
    this.repository.polygonGeoTiffLayersData = data.geoTiffs
  }

  setTargetPoint(coords: [number, number] | undefined) {
    this.repository.targetPoint = coords
  }

  setMode(payload: WhatsHereStore['mode']) {
    this.repository.mode = payload
  }

  resetStore() {
    this.repository.reset()
  }
}